import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { createFilter } from "react-select";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FaXmark } from "react-icons/fa6";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { regionalGroupInitialValues } from "@/utils/initialValues/regionalGroup";
import { regionalGroupValidationSchema } from "@/utils/validation/reginalGroupValidationSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { BasesSelect } from "@/components/Selects/BasesSelect";
import { Separator } from "@/components/Separator";

type RegionalGroupRegistrationModalProps = {
	showRegionalGroupRegistrationModal: boolean;
	handleCloseRegionalGroupRegistrationModal: () => void;
	fetchRegionalGroups: () => void;
	regionalGroup: RegionalGroup;
};

type BaseOption = {
	label: string;
	tooltip?: string;
	extraData: BaseOrUnit;
};

export function RegionalGroupRegistrationModal({
	showRegionalGroupRegistrationModal,
	handleCloseRegionalGroupRegistrationModal,
	fetchRegionalGroups,
	regionalGroup,
}: RegionalGroupRegistrationModalProps) {
	const [linkedBases, setLinkedBases] = useState<BaseOrUnit[]>([]);

	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	const regionalGroupLength = regionalGroup && Object.entries(regionalGroup).length;
	const linkedBasesLength = linkedBases?.length;

	const toastMessage = `Grupo regional ${
		regionalGroupLength ? "editado" : "cadastrado"
	} com sucesso`;

	const formik = useFormik({
		initialValues: regionalGroupInitialValues,
		validationSchema: regionalGroupValidationSchema,
		async onSubmit(values) {
			try {
				const linkedBasesIds = linkedBases.map((base) => base.id);
				const newValues = { ...values, bases: linkedBasesIds };

				const method = regionalGroupLength ? "put" : "post";
				const route = regionalGroupLength
					? `/regional-group/${regionalGroup.id}`
					: "/regional-group";

				await api[method](route, newValues);

				Toast.fire({
					icon: "success",
					title: toastMessage,
				});

				fetchRegionalGroups();
				handleCloseModal();
			} catch (error: any) {
				if (error.isAxiosError && error.response.status === 422) {
					return toastRequestErrors(error.response.data.errors);
				}

				Toast.fire({
					icon: "error",
					title: `Erro ao ${regionalGroupLength ? "editar" : "cadastrar"} grupo regional`,
				});
			}
		},
	});

	const { errors } = formik;

	function handleLinkBase(base: BaseOrUnit) {
		setLinkedBases([...linkedBases, base]);
	}

	async function handleUnlinkBase(index: number) {
		try {
			const updatedBases = [...linkedBases];
			updatedBases.splice(index, 1);

			setLinkedBases(updatedBases);
		} catch (error) {
			console.error(error);
		}
	}

	function filterSelectedBases(candidate: ReactSelectFilterOption<unknown>, input: string) {
		const hasSameBase = linkedBases?.some((base) => base.id === candidate.value);

		if (hasSameBase) {
			return false;
		}

		const defaultFilter = createFilter();

		return defaultFilter(candidate, input);
	}

	function handleCloseModal() {
		handleCloseRegionalGroupRegistrationModal();

		formik.resetForm();

		setLinkedBases([] as BaseOrUnit[]);
	}

	useEffect(() => {
		if (regionalGroupLength) {
			formik.setFieldValue("name", regionalGroup.name);
			setLinkedBases(regionalGroup.bases);

			setTimeout(() => {
				formik.validateForm();
			});
		}
	}, [regionalGroup]);

	useEffect(() => {
		formik.validateForm();
	}, [showRegionalGroupRegistrationModal]);

	return (
		<Modal size="lg" show={showRegionalGroupRegistrationModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex align-items-center justify-content-center">
				<h2 className="m-0">
					{regionalGroupLength ? "Edição" : "Cadastro"} de grupo regional
				</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<div className="d-flex flex-column gap-4">
						<FormattedFormControl
							id="regional-group-name"
							label="Nome do grupo regional"
							labelClassName="fw-bold"
							isInvalid={!!errors.name}
							validationMessage={errors.name}
							{...formik.getFieldProps("name")}
						/>

						<div>
							<Form.Label htmlFor="base" className="fw-bold">
								Adicionar base vinculada
							</Form.Label>

							<BasesSelect
								inputId="base"
								filterOption={filterSelectedBases}
								value={null}
								isRegionalGroupForm
								isInvalid={!linkedBasesLength}
								onChange={(option) => {
									handleLinkBase((option as BaseOption)?.extraData);
								}}
								formatOptionLabel={(data) => {
									const option = data as BaseOption;

									if (!option.tooltip) {
										return option.label;
									}

									return (
										<OverlayTrigger
											overlay={<Tooltip>{option.tooltip}</Tooltip>}
											placement="top-start"
										>
											<div>{option.label}</div>
										</OverlayTrigger>
									);
								}}
							/>

							{!linkedBasesLength && (
								<span className="text-danger">Campo obrigatório</span>
							)}
						</div>

						<div className="max-h-50vh overflow-auto">
							{linkedBases.length > 0 && (
								<>
									<div className="d-flex justify-content-between align-items-center mt-3">
										<Form.Label className="fw-bold">
											Bases vinculadas
										</Form.Label>

										<Form.Label className="fw-bold">Ações</Form.Label>
									</div>

									<Separator className="m-0" />
								</>
							)}

							{linkedBases?.map((base, index) => (
								<div key={`base-${index}`}>
									<div className="d-flex justify-content-between align-items-center">
										<span>{`${base.vehicle_type?.name} ${base.city?.name}`}</span>

										<Button
											className="btn-icon btn-sm button-bg-samu-color-white my-3"
											onClick={() => handleUnlinkBase(index)}
										>
											<FaXmark />
										</Button>
									</div>

									{linkedBases?.length - 1 !== index && (
										<Separator className="p-0" />
									)}
								</div>
							))}
						</div>

						{!linkedBasesLength && (
							<div className="text-center">
								<h4 className="py-4">Sem bases vinculadas</h4>
							</div>
						)}
					</div>

					<div className="d-flex gap-3 justify-content-center mt-8">
						<button
							className="btn button-bg-light-color-gray-400"
							type="button"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							className="btn button-bg-samu-color-white"
							type="submit"
							disabled={formik.isSubmitting || !formik.isValid || !linkedBasesLength}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : regionalGroupLength ? (
								"Salvar"
							) : (
								"Cadastrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
