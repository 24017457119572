import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { FaUserCircle, FaExchangeAlt, FaRegUser } from "react-icons/fa";
import { MdOutlineAssignmentLate } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { RoleMessages } from "@/enums/Role";

import { ViewMessageModal } from "@/components/Configurations/Messages/Modals/ViewMessageModal";
import { ChangeProfileModal } from "@/components/Modals/ChangeProfile";
import { ChangeUrcModal } from "@/components/Modals/ChangeUrcModal";

import "./styles.scss";

export const DefaultTitle: FC = () => {
	const [showChangeProfileModal, setShowChangeProfileModal] = useState(false);
	const [showViewMessageModal, setShowViewMessageModal] = useState(false);
	const [showChangeUrcModal, setShowChangeUrcModal] = useState(false);
	const [roles, setRoles] = useState<Role[]>([]);

	const { currentAuth, logout } = useAuth();
	const navigate = useNavigate();
	const { api } = useApi();

	const handleShowChangeUrcModal = () => setShowChangeUrcModal(true);
	const handleCloseChangeUrcModal = () => setShowChangeUrcModal(false);

	const handleShowChangeProfileModal = () => setShowChangeProfileModal(true);
	const handleCloseChangeProfileModal = () => setShowChangeProfileModal(false);

	const handleShowViewMessageModal = () => setShowViewMessageModal(true);
	const handleCloseViewMessageModal = () => setShowViewMessageModal(false);

	async function fetchRoles() {
		try {
			const { data } = await api.get("/user/roles-by-unit", {
				params: {
					user_id: currentAuth?.id,
					urc_id: currentAuth?.current_urgency_regulation_center?.id,
				},
			});

			setRoles(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function handleLogout() {
		try {
			await api.post("/auth/logout");

			logout();

			navigate("/login");
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		fetchRoles();
	}, [currentAuth]);

	return (
		<div className="d-flex justify-content-between align-items-center w-100">
			<div className="d-flex flex-column justify-content-center">
				<h3 className="d-flex flex-column text-white fw-bolder mb-0">
					{currentAuth?.current_urgency_regulation_center?.name}
				</h3>
				<button
					className="d-flex align-items-center btn gap-2 text-white fs-7 p-0 m-0"
					onClick={handleShowChangeUrcModal}
				>
					<FaExchangeAlt /> Alterar a CRU
				</button>
			</div>

			<div className="d-flex gap-3 align-items-center">
				<OverlayTrigger placement="bottom" overlay={<Tooltip>Recados</Tooltip>}>
					<div className="cursor-pointer" onClick={handleShowViewMessageModal}>
						<MdOutlineAssignmentLate color="white" className="fs-2" />
					</div>
				</OverlayTrigger>

				<a
					href="/"
					className="cursor-default"
					data-kt-menu-trigger="hover"
					data-kt-menu-placement="bottom-end"
				>
					<FaUserCircle color="white" className="fs-2.2rem" />
				</a>

				<div
					className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-5 fs-6 w-275px"
					data-kt-menu="true"
				>
					<div className="menu-item px-3">
						<div className="d-flex align-items-center menu-content px-3">
							<div className="symbol symbol-50px me-5">
								<FaUserCircle className="text-secondary fs-4rem" />
							</div>

							<div className="d-flex flex-column">
								<span>{currentAuth?.name}</span>

								<span className="d-flex align-items-center fw-normal gap-2">
									{
										RoleMessages[
											currentAuth?.current_role
												?.name as keyof typeof RoleMessages
										]
									}

									{roles.length > 1 && (
										<button
											className="btn p-0"
											onClick={handleShowChangeProfileModal}
										>
											<FaExchangeAlt color="gray" />
										</button>
									)}
								</span>
							</div>
						</div>

						<div className="separator py-2" />

						<div className="d-flex flex-column pt-5 px-5 gap-4">
							<div className="w-fit-content">
								<Link
									to="ver-perfil"
									className="d-flex align-items-center fw-normal text-dark gap-2"
								>
									<FaRegUser color="grey" className="fs-3" /> Meu perfil
								</Link>
							</div>

							<div className="w-fit-content">
								<button
									className="d-flex align-items-center btn fw-normal text-dark gap-2 p-0"
									onClick={handleLogout}
								>
									<FiLogOut color="grey" className="fs-3" /> Sair
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ChangeUrcModal
				showChangeUrcModal={showChangeUrcModal}
				handleCloseChangeUrcModal={handleCloseChangeUrcModal}
			/>

			<ChangeProfileModal
				showChangeProfileModal={showChangeProfileModal}
				handleCloseChangeProfileModal={handleCloseChangeProfileModal}
				roles={roles}
			/>

			<ViewMessageModal
				showViewMessage={showViewMessageModal}
				handleCloseViewMessageModal={handleCloseViewMessageModal}
			/>
		</div>
	);
};
