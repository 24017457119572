import { ReactNode } from "react";

type Props = {
	attendanceId?: string;
	children?: ReactNode;
};

export function AttendanceRedirect({ attendanceId, children }: Props) {
	function handleSetPathname() {
		window.history.pushState(
			{ pathname: "/resumo" },
			"",
			`/ocorrencias/resumo/${attendanceId}`
		);
	}

	return (
		<a
			href={`/ocorrencias/resumo/${attendanceId}`}
			rel="noreferrer"
			target="_blank"
			onClick={handleSetPathname}
		>
			{children}
		</a>
	);
}
