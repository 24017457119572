import { FormEvent, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";

import { PinInput } from "@/components/Inputs/PinInput";

type Props = {
	showPinModal: boolean;
	modalTitle?: string;
	handleClosePinModal: () => void;
	beforeCheck: (pin: string) => void;
	currentPin: string;
	onlyShow?: boolean;
};
export function PinModal({
	showPinModal,
	handleClosePinModal,
	modalTitle,
	beforeCheck,
	currentPin,
	onlyShow = false,
}: Props) {
	const { toastRequestErrors } = useSwal();
	const { api } = useApi();

	const [isCompletedPin, setIsCompletedPin] = useState(false);
	const [pin, setPin] = useState("");

	function handleCheckCompletedPin(pin: string) {
		const isFullPin = pin.length === 8;
		setIsCompletedPin(isFullPin);

		if (isFullPin) {
			setPin(pin);
		}
	}

	async function handleCheckPinValidation(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			const { data } = await api.get("/pins/check", {
				params: {
					pin,
				},
			});

			beforeCheck(data.pin);
		} catch (error: any) {
			toastRequestErrors(error.response.data.errors);
			console.log(error);
		}
	}

	return (
		<Modal size="lg" show={showPinModal} onHide={handleClosePinModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{modalTitle || "Dispositivo"}</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={handleCheckPinValidation}>
					<h3 className="text-center mb-5">
						{onlyShow
							? "Digite o código no dispositivo para realizar o vínculo com a VTR: "
							: "Por favor, insira o código PIN gerado pelo tablet abaixo:"}
					</h3>

					<PinInput
						maxLength={6}
						minLength={6}
						onChange={handleCheckCompletedPin}
						disabled={onlyShow}
						value={currentPin}
					/>

					<div className="d-flex justify-content-center gap-2 mt-8">
						{onlyShow ? (
							<button
								type="button"
								className="btn button-bg-samu-color-white"
								onClick={handleClosePinModal}
							>
								Continuar
							</button>
						) : (
							<>
								<button
									type="button"
									className="btn button-bg-light-color-gray-400"
									onClick={handleClosePinModal}
								>
									Fechar
								</button>

								<button
									type="submit"
									className="btn button-bg-samu-color-white"
									disabled={!isCompletedPin}
								>
									Continuar
								</button>
							</>
						)}
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
