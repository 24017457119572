import { Route, Routes } from "react-router-dom";

import { MobileDevices } from "@/pages/Configurations/MobileDevices";
import { Management } from "@/pages/Configurations/Management";
import { Messages } from "@/pages/Configurations/Messages";
import { Token } from "@/pages/Configurations/Token";
import { AccessHistory } from "@/pages/Configurations/AccessHistory";
import { Monitoring } from "@/pages/Configurations/Monitoring";
import { RegionalGroups } from "@/pages/Configurations/RegionalGroups";

export function ConfigurationRoutes() {
	return (
		<Routes>
			<Route path="dispositivos-moveis" element={<MobileDevices />} />
			<Route path="gerenciamento" element={<Management />} />
			<Route path="grupos-regionais" element={<RegionalGroups />} />
			<Route path="recados" element={<Messages />} />
			<Route path="token" element={<Token />} />
			<Route path="historico-de-acessos" element={<AccessHistory />} />
			<Route path="monitoramento" element={<Monitoring />} />
		</Routes>
	);
}
