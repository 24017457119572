import { OTPInput, OTPInputProps, SlotProps as InternalSlotProps } from "input-otp";

import "./styles.scss";

type PinInputProps = Omit<OTPInputProps, "render" | "children"> & {
	containerClassName?: string;
	slotClassName?: string;
};

type SlotProps = InternalSlotProps & {
	className?: string;
};

function Pin({ slotClassName, ...rest }: PinInputProps) {
	return (
		<OTPInput
			autoFocus
			inputMode="numeric"
			className="pin-input"
			{...rest}
			render={({ slots }) => (
				<div className="slots-container">
					<div className="slot-group">
						{slots.slice(0, 3).map((slot, index) => (
							<Slot key={index} {...slot} className={slotClassName} />
						))}
					</div>

					<div className="slot-separator">
						<div className="slot-separator-line"></div>
					</div>

					<div className="slot-group">
						{slots.slice(3).map((slot, index) => (
							<Slot key={index} {...slot} className={slotClassName} />
						))}
					</div>
				</div>
			)}
		/>
	);
}

function Slot({ char, hasFakeCaret, className }: SlotProps) {
	return (
		<div className={`slot ${className || ""}`}>
			{char !== null && <div>{char}</div>}
			{hasFakeCaret && (
				<div className="fake-carret">
					<div className="fake-carret-line" />
				</div>
			)}
		</div>
	);
}

export function PinInput({ ...rest }: PinInputProps) {
	return <Pin {...rest} />;
}
