import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { Dayjs } from "dayjs";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { ScheduleType } from "@/enums/ScheduleType";
import { formatPhone } from "@/utils/formatPhone";
import { onlyNumbers } from "@/utils/onlyNumbers";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { userInitialValues } from "@/utils/initialValues/user";
import { userRegistrationSchema } from "@/utils/validation/userRegistrationSchema";

import { PatientData } from "@/components/Users/Components/PatientData";
import { Credentials } from "@/components/Users/Components/Credentials";
import { Address } from "@/components/Users/Components/Address";
import { Separator } from "@/components/Separator";

type EditModalProps = {
	showEditUserModal: boolean;
	handleCloseEditUserModal: () => void;
	currentUser: User;
	fetchPaginatedUsers?: (pageToUse?: number) => void;
};

export function EditModal({
	showEditUserModal,
	handleCloseEditUserModal,
	currentUser,
	fetchPaginatedUsers,
}: EditModalProps) {
	const urgencyRegulationCenterAndRolesInitialData = {
		id: "",
		name: "",
		user_roles: [],
	};
	const [urgencyRegulationCenterAndRoles, setUrgencyRegulationCenterAndRoles] = useState<
		UrgencyRegulationCenterAndRoles[]
	>([urgencyRegulationCenterAndRolesInitialData]);
	const { Toast } = useSwal();
	const { api } = useApi();
	const { currentAuth, setCurrentAuth } = useAuth();

	const isURCFieldInvalid = !urgencyRegulationCenterAndRoles.every(({ id }) => id);

	const isRolesFieldInvalid = !urgencyRegulationCenterAndRoles.every(
		({ user_roles }) => user_roles.length
	);

	const formik = useFormik({
		initialValues: userInitialValues,
		validationSchema: userRegistrationSchema,
		async onSubmit(values, { setSubmitting }) {
			try {
				const { data } = await api.put("/users/" + values.user_id, {
					...values,
					identifier: onlyNumbers(values.identifier),
					phone: onlyNumbers(values.phone),
					whatsapp: onlyNumbers(values.whatsapp),
					national_health_card: onlyNumbers(values.national_health_card),
				});

				Toast.fire({
					icon: "success",
					title: "Edição realizado com sucesso!",
				});

				fetchPaginatedUsers && fetchPaginatedUsers();
				handleCloseEditUserModal();

				if (values.user_id === currentAuth?.id) {
					setCurrentAuth(data);
				}
			} catch (error) {
				console.log(error);

				Toast.fire({
					icon: "error",
					title: "Erro ao editar usuário!",
				});

				setSubmitting(false);
			}
		},
	});

	const { values } = formik;

	useEffect(() => {
		if (Object.keys(currentUser).length) {
			const currentUserValues = {
				...currentUser,
				identifier: formatIdentifier(currentUser.identifier) || "",
				phone: formatPhone(currentUser.phone) || "",
				federal_unit: String(currentUser.city.federal_unit.id),
				federal_unit_label: currentUser.city.federal_unit.name,
				city_label: currentUser.city.name,
				user_id: currentUser.id,
				cbo_label: currentUser.occupation?.name,
				profiles: [],
				schedulable_id: "",
				schedulable_label: "",
				valid_from: null as Dayjs | null,
				valid_through: null as Dayjs | null,
				clock_in: "",
				clock_out: "",
				schedule_type_id: String(ScheduleType.Default),
				days_of_week: [] as string[],
				created_at: "",
			};

			formik.setValues(currentUserValues);

			setUrgencyRegulationCenterAndRoles(currentUser?.urgency_regulation_centers);
		}
	}, [currentUser]);

	useEffect(() => {
		formik.validateForm();
	}, [values.federal_unit, values.city_id]);

	return (
		<Modal size="xl" show={showEditUserModal} onHide={handleCloseEditUserModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Edição do perfil</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<PatientData formik={formik} />

					<Separator />

					<h3 className="mb-4">Endereço</h3>
					<Address formik={formik} />

					<Separator />

					<h3 className="mb-4">Credenciais</h3>
					<Credentials
						formik={formik}
						urgencyRegulationCenterAndRoles={urgencyRegulationCenterAndRoles}
						setUrgencyRegulationCenterAndRoles={setUrgencyRegulationCenterAndRoles}
					/>

					<div className="d-flex justify-content-center gap-4 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseEditUserModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={
								formik.isSubmitting ||
								!formik.isValid ||
								isURCFieldInvalid ||
								isRolesFieldInvalid
							}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : (
								"Salvar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
