import { useState } from "react";

import Card from "react-bootstrap/Card";

import { useCanAccess } from "@/hooks/useCanAccess";
import { Role } from "@/enums/Role";
import { get } from "@/utils/tracking/accessors";

import { GoogleMaps } from "@/components/Tracking/TrackHistory/TrackHistoryMaps/GoogleMaps";
import { TrackHistoryControls } from "@/components/Tracking/TrackHistory/TrackHistoryControls";
import { OpenStreetMaps } from "@/components/Tracking/TrackHistory/TrackHistoryMaps/OpenStreetMaps";

const DEFAULT_OPTIONS = get().defaultOptions();
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export function TrackHistory() {
	const [trackHistory, setTrackHistory] = useState<TrackHistoryResponse | null>(null);

	const { can } = useCanAccess();

	return (
		<Card>
			<Card.Header className="d-flex justify-content-between align-items-center">
				<h3 className="fw-normal m-0">Histórico de percurso</h3>
			</Card.Header>
			<Card.Body className="pt-6 px-9" style={{ height: "75vh" }}>
				<TrackHistoryControls setTrackHistory={setTrackHistory} />
				{can([Role.manager]) && GOOGLE_MAPS_API_KEY ? (
					<GoogleMaps
						defaultOptions={DEFAULT_OPTIONS}
						trackHistory={trackHistory}
						className="w-100 h-100 pb-20"
					/>
				) : (
					<OpenStreetMaps
						defaultOptions={DEFAULT_OPTIONS}
						trackHistory={trackHistory}
						className="w-100 h-100 rounded pb-20"
					/>
				)}
			</Card.Body>
		</Card>
	);
}
