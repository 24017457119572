import { useEffect } from "react";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";

import { cyclicScheduleTypesValidationSchema } from "@/utils/validation/cyclicScheduleTypesValidationSchema";
import { cyclicScheduleTypesInitialValues } from "@/utils/initialValues/cyclicScheduleTypes";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

type Props = {
	showCyclicScheduleTypeModal: boolean;
	handleCloseCyclicScheduleTypeModal: () => void;
	fetchPaginatedCyclicScheduleTypes: () => void;
	currentCyclicScheduleType?: CyclicScheduleTypes;
};

export function CyclicScheduleTypesModal({
	showCyclicScheduleTypeModal,
	handleCloseCyclicScheduleTypeModal,
	fetchPaginatedCyclicScheduleTypes,
	currentCyclicScheduleType,
}: Props) {
	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	const isEdit = currentCyclicScheduleType && Object.entries(currentCyclicScheduleType).length;

	function handleCloseModal() {
		formik.resetForm();

		handleCloseCyclicScheduleTypeModal();
	}

	const formik = useFormik({
		initialValues: cyclicScheduleTypesInitialValues,
		validationSchema: cyclicScheduleTypesValidationSchema,
		async onSubmit(values, { setSubmitting }) {
			try {
				const method = isEdit ? "put" : "post";
				const route = isEdit
					? `/cyclic-schedule-type/${currentCyclicScheduleType?.id}`
					: "/cyclic-schedule-type";

				await api[method](route, {
					name: values.name,
					work_hours: values.work_hours,
					break_hours: values.break_hours,
				});

				handleCloseModal();
				fetchPaginatedCyclicScheduleTypes();

				Toast.fire({
					icon: "success",
					title: `Escala ${isEdit ? "atualizada" : "cadastrada"} com sucesso!`,
				});
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	const { errors } = formik;

	useEffect(() => {
		if (isEdit) {
			formik.setFieldValue("name", currentCyclicScheduleType?.name);
			formik.setFieldValue("work_hours", currentCyclicScheduleType?.work_hours);
			formik.setFieldValue("break_hours", currentCyclicScheduleType?.break_hours);
		}
	}, [currentCyclicScheduleType]);

	return (
		<Modal size="lg" show={showCyclicScheduleTypeModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{isEdit ? "Editar" : "Nova"} escala</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
					<Row>
						<Col>
							<FormattedFormControl
								id="name"
								label="Nome da escala"
								labelClassName="fw-bold"
								maxLength={100}
								validationMessage={errors.name}
								isInvalid={!!errors.name}
								{...formik.getFieldProps("name")}
								isRequired
							/>
						</Col>

						<Col sm={3}>
							<FormattedFormControl
								id="work_hours"
								label="Horas de trabalho"
								labelClassName="fw-bold"
								type="number"
								validationMessage={errors.work_hours}
								isInvalid={!!errors.work_hours}
								{...formik.getFieldProps("work_hours")}
								isRequired
							/>
						</Col>

						<Col sm={3}>
							<FormattedFormControl
								id="break_hours"
								label="Horas de folga"
								labelClassName="fw-bold"
								type="number"
								validationMessage={errors.break_hours}
								isInvalid={!!errors.break_hours}
								{...formik.getFieldProps("break_hours")}
								isRequired
							/>
						</Col>
					</Row>

					<div className="d-flex justify-content-center gap-4 mt-5">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : isEdit ? (
								"Salvar"
							) : (
								"Cadastrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
