import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { VehicleStatusMessages } from "@/enums/VehicleStatus";

type Props = {
	showVehicleViewingModal: boolean;
	handleCloseVehicleViewingModal: () => void;
	vehicle: Vehicle;
};

export function VehicleViewingModal({
	showVehicleViewingModal,
	handleCloseVehicleViewingModal,
	vehicle,
}: Props) {
	const vehicleType = vehicle.vehicle_type?.name;

	const vehicleStatus =
		VehicleStatusMessages[
			vehicle.latest_vehicle_status_history
				?.vehicle_status_id as keyof typeof VehicleStatusMessages
		];

	return (
		<Modal size="lg" show={showVehicleViewingModal} onHide={handleCloseVehicleViewingModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Visualização de viatura</h2>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex flex-column gap-4">
					<Row>
						<Col>
							<span className="fw-bold">Tipo: </span>
							{vehicleType}
						</Col>

						<Col>
							<span className="fw-bold">Chassi: </span>
							{vehicle.chassis}
						</Col>
					</Row>

					<Row>
						<Col>
							<span className="fw-bold">Placa: </span>
							{vehicle.license_plate}
						</Col>

						<Col>
							<span className="fw-bold">Código: </span>
							{vehicle.code}
						</Col>
					</Row>

					<Row>
						<Col>
							<span className="fw-bold">Base: </span>
							{vehicle.base?.name}
						</Col>

						<Col>
							<span className="fw-bold">Status: </span>
							{vehicleStatus}
						</Col>
					</Row>

					<Row>
						<Col>
							<span className="fw-bold">IMEI rastreador: </span>
							{vehicle?.tracking_device_imei}
						</Col>

						<Col>
							<span className="fw-bold">ID rastreamento: </span>
							{vehicle?.tracking_system_id}
						</Col>
					</Row>
				</div>

				<div className="text-center mt-8">
					<button
						className="btn button-bg-light-color-gray-400"
						onClick={handleCloseVehicleViewingModal}
						type="button"
					>
						Fechar
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
