import { ButtonHTMLAttributes, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import classNames from "clsx";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

import { requesterSatisfactionSchema } from "@/utils/validation/requesterSatisfactionSchema";
import { emojisInitialValues } from "@/utils/initialValues/emojis";
import { useWindowDimensions } from "@/hooks/useWindowDimensions";
import { KTSVG, toAbsoluteUrl } from "@/../_metronic/helpers";
import { useSwal } from "@/hooks/useSwal";
import { useApi } from "@/hooks/useApi";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";
import { Separator } from "@/components/Separator";

type EmojiProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	name: string;
	selected: boolean;
};

export function RateOccurence() {
	const [attendanceMonitoring, setAttendanceMonitoring] = useState<AttendanceMonitoring>(
		{} as AttendanceMonitoring
	);
	const [requesterSatisfaction, setRequesterSatisfaction] = useState<RequesterSatisfaction>(
		{} as RequesterSatisfaction
	);
	const [isLinkExpired, setIsLinkExpired] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { attendance } = attendanceMonitoring || {};
	const { ticket } = attendance || {};
	const { requester } = ticket || {};

	const { attendanceId } = useParams();
	const { Toast } = useSwal();
	const { api } = useApi();
	const { width } = useWindowDimensions();
	const gapEightOrThree = width >= 345 ? "gap-8" : "gap-3";

	const formik = useFormik({
		initialValues: emojisInitialValues,
		validationSchema: requesterSatisfactionSchema,
		onSubmit: async (values, { setSubmitting }) => {
			if (attendanceId) {
				values.attendance_id = attendanceId;
			}

			try {
				const { data } = await api.post(
					"attendance-monitoring/requester-satisfaction",
					values
				);

				setIsLoading(false);
				setRequesterSatisfaction(data);
			} catch (error) {
				console.error(error);

				setSubmitting(false);
				setIsLinkExpired(true);

				Toast.fire({
					icon: "error",
					title: "Erro ao finalizar pesquisa!",
				});
			}
		},
	});

	async function fetchAttendance() {
		try {
			const { data } = await api.get<AttendanceMonitoring>(
				`attendance-monitoring/${attendanceId}`,
				{
					params: {
						loading_requester_satisfaction: 1,
					},
				}
			);

			setAttendanceMonitoring(data);
			setRequesterSatisfaction(data.attendance?.requester_satisfaction);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	const { values, errors } = formik;

	const emojiList = {
		"very-dissatisfied": {
			selected: "very-dissatisfied-red",
			value: 1,
			label: "Muito insatisfeito",
		},
		dissatisfied: {
			selected: "dissatisfied-orange",
			value: 2,
			label: "Insatisfeito",
		},
		neutral: {
			selected: "neutral-yellow",
			value: 3,
			label: "Neutro",
		},
		satisfied: {
			selected: "satisfied-green",
			value: 4,
			label: "Satisfeito",
		},
		"very-satisfied": {
			selected: "very-satisfied-dark-green",
			value: 5,
			label: "Muito satisfeito",
		},
	};

	const Emoji = ({ name, selected, ...rest }: EmojiProps) => {
		const emoji = emojiList[name as keyof typeof emojiList];
		const emojiName = selected ? emoji?.selected : name;

		return (
			<div className="d-flex flex-column gap-1 text-center">
				<button
					type="button"
					className="border border-none rounded-circle p-0 m-0"
					{...rest}
				>
					<KTSVG
						path={`/media/emojis/${emojiName}.svg`}
						className="svg-icon svg-icon-4x"
					/>
				</button>
				<div className="text-center fs-8 w-55px">
					<div>{emoji?.value}</div>
					<div>{emoji?.label}</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (attendanceId) {
			fetchAttendance();
		}
	}, [attendanceId]);

	if (isLinkExpired) {
		return (
			<div className="d-flex flex-center h-75">
				<div className="d-flex flex-column gap-10">
					<img
						src={toAbsoluteUrl("/media/images/monitoring/expired-link.svg")}
						alt="Imagem link expirado"
					/>

					<div className="text-center">
						<h1>Este link não está mais disponível</h1>
					</div>
				</div>
			</div>
		);
	}

	if (isLoading) {
		return (
			<div className="d-flex justify-content-center align-items-center h-75">
				<Spinner animation="border" variant="samu" className="loading-spinner" />
			</div>
		);
	}

	if (!isLoading && requesterSatisfaction?.id) {
		return (
			<div className="d-flex flex-center h-75">
				<div className="d-flex flex-column justify-content-around h-100">
					<h1 className="d-flex justify-content-center">
						Sua avaliação foi enviada com sucesso!
					</h1>

					<img
						src={toAbsoluteUrl("/media/images/evaluation/evaluation-submitted.svg")}
						alt="Imagem de avaliação enviada"
						className="h-50"
					/>

					<div className="text-center">
						<p className="fs-4">
							Obrigado por nos avaliar, sua opinião será de grande ajuda para o SAMU
							192 CE melhorar o atendimento prestado à população.
						</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="fs-4 m-1 overflow-x-hidden overflow-y-auto">
			<h1 className="mb-5 fs-1">Olá {requester?.name},</h1>

			<span>Por favor, avalie nosso atendimento, </span>
			<span className="fw-bold">sua opinião é muito importante para nós!</span>

			<p className="text-samu m-0">* Indica uma pergunta obrigatória</p>

			<p className="fs-2 fw-bold my-4">Dados da ocorrência</p>

			<Row className="mb-3">
				<Col>
					<span className="fw-bold">Protocolo: </span>
					{attendance?.number || "-"}
				</Col>

				<Col>
					<span className="fw-bold">Solicitado por: </span>
					{requester?.name || "-"}
				</Col>
			</Row>

			<span className="fw-bold">Paciente: </span>
			{attendance?.patient?.name || "-"}

			<Separator />

			<p className="fs-2 fw-bold mb-4">Perguntas</p>

			<Form onSubmit={formik.handleSubmit}>
				<p>
					1. Em uma escala de 1 a 5, como você avalia a sua satisfação com o serviço
					oferecido pelo SAMU 192 CE?
					<RedAsterisk />
				</p>

				<div className={classNames("d-flex justify-content-center my-4", gapEightOrThree)}>
					{Object.keys(emojiList).map((emoji) => {
						const emojiSelected = emojiList[emoji as keyof typeof emojiList];
						const emojiValue = emojiSelected.value;

						return (
							<Emoji
								key={emoji}
								name={emoji}
								selected={
									emojiValue === Number(values.scale_satisfaction_service_offered)
										? true
										: false
								}
								onClick={() =>
									formik.setFieldValue(
										"scale_satisfaction_service_offered",
										emojiSelected.value
									)
								}
							/>
						);
					})}
				</div>

				<p>
					2. Em uma escala de 1 a 5, com que nota você avalia o atendimento recebido ao
					telefone 192?
				</p>

				<div className={classNames("d-flex justify-content-center my-4", gapEightOrThree)}>
					{Object.keys(emojiList).map((emoji) => {
						const emojiSelected = emojiList[emoji as keyof typeof emojiList];
						const emojiValue = emojiSelected.value;

						return (
							<Emoji
								key={emoji}
								name={emoji}
								selected={
									emojiValue === Number(values.scale_telephone_attendance)
										? true
										: false
								}
								onClick={() =>
									formik.setFieldValue(
										"scale_telephone_attendance",
										emojiSelected.value
									)
								}
							/>
						);
					})}
				</div>

				<p>3. Como avalia o tempo de atendimento ao telefone?</p>

				<FormGroupRadio
					className="my-4 fs-5"
					requestedValue={values.satisfaction_time_spent_phone_id}
					{...formik.getFieldProps("satisfaction_time_spent_phone_id")}
				>
					<FormRadio id="quick" label="Rápido" value="1" />
					<FormRadio id="within-expectations" label="Dentro do esperado" value="2" />
					<FormRadio id="time-consuming" label="Demorado" value="3" />
					<FormRadio
						id="was-not-who-called"
						label="Não fui eu quem fez a ligação para o telefone 192"
						value="4"
					/>
					<FormRadio
						id="there-was-no-call"
						label="Não houve ligação para o telefone 192"
						value="5"
					/>
				</FormGroupRadio>

				<p>4. Como você avalia o tempo que a ambulância levou para chegar ao local?</p>

				<FormGroupRadio
					className="my-4 fs-5"
					requestedValue={values.satisfaction_time_ambulance_arrive_id}
					{...formik.getFieldProps("satisfaction_time_ambulance_arrive_id")}
				>
					<FormRadio id="fast" label="Rápido" value="1" />
					<FormRadio id="as-expected" label="Dentro do esperado" value="2" />
					<FormRadio id="lengthy" label="Demorado" value="3" />
					<FormRadio
						id="not-there-when-car-arrived"
						label="Não estava no local quando a viatura chegou"
						value="4"
					/>
					<FormRadio
						id="no-emergency-services"
						label="Não houve atendimento de emergência"
						value="5"
					/>
				</FormGroupRadio>

				<p>
					5. Em uma escala de 1 a 5, com que nota você avalia o atendimento prestado pela
					equipe do SAMU 192 CE?
				</p>

				<div className={classNames("d-flex justify-content-center my-4", gapEightOrThree)}>
					{Object.keys(emojiList).map((emoji) => {
						const emojiSelected = emojiList[emoji as keyof typeof emojiList];
						const emojiValue = emojiSelected.value;

						return (
							<Emoji
								key={emoji}
								name={emoji}
								selected={
									emojiValue ===
									Number(values.scale_attendance_provided_mecs_team)
										? true
										: false
								}
								onClick={() =>
									formik.setFieldValue(
										"scale_attendance_provided_mecs_team",
										emojiSelected.value
									)
								}
							/>
						);
					})}
				</div>

				<div className="mb-10">
					<p>
						6. Aproveite este espaço para nos auxiliar ainda mais a melhorar nossos
						serviços. Tem uma crítica, uma sugestão ou um elogio? Escreva pra gente!
					</p>

					<FormControlWithCharactersCount
						id="requester-sugestion"
						maxLength={2000}
						as="textarea"
						placeholder="Descreva"
						labelClassName="fw-bold"
						isInvalid={!!errors.requester_sugestion}
						validationMessage={errors.requester_sugestion}
						{...formik.getFieldProps("requester_sugestion")}
					/>
				</div>

				<div className="d-flex justify-content-center mb-10">
					<button
						className="btn button-bg-samu-color-white fs-3 w-50"
						type="submit"
						disabled={formik.isSubmitting || !formik.isValid}
					>
						Finalização
					</button>
				</div>
			</Form>
		</div>
	);
}
