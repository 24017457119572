import { useEffect } from "react";
import { FormikProps } from "formik";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaPlus, FaTrash } from "react-icons/fa";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { useCanAccess } from "@/hooks/useCanAccess";
import { Role, RoleMessages, roleSlugs } from "@/enums/Role";
import { userInitialValues } from "@/utils/initialValues/user";

import { UrgencyRegulationCentersSelect } from "@/components/Selects/UrgencyRegulationCentersSelect";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { OccupationsSelect } from "@/components/Selects/OccupationsSelect";
import { RolesSelect } from "@/components/Selects/RolesSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	isRegister?: boolean;
	formik: FormikProps<typeof userInitialValues>;
	urgencyRegulationCenterAndRoles: UrgencyRegulationCenterAndRoles[];
	setUrgencyRegulationCenterAndRoles: (
		urgencyRegulationCenterAndRoles: UrgencyRegulationCenterAndRoles[]
	) => void;
	isProfessionalRegistration?: boolean;
};

export function Credentials({
	isRegister,
	formik,
	urgencyRegulationCenterAndRoles,
	setUrgencyRegulationCenterAndRoles,
	isProfessionalRegistration,
}: Props) {
	const { Toast } = useSwal();

	const { values, errors } = formik;

	const { can } = useCanAccess();
	const { admin, coordinator } = Role;
	const { currentAuth } = useAuth();

	const currentUrcId = currentAuth?.current_urgency_regulation_center?.id;
	const isCurrentUser = values.user_id === currentAuth?.id;

	const urgencyRegulationCenterAndRolesInitialData = {
		id: "",
		name: "",
		user_roles: [],
	};

	function handleChangeOccupation(option: ReactSelectOption) {
		formik.setFieldValue("cbo", option.value);
		formik.setFieldValue("cbo_label", option.label);
	}

	function handleAddFields() {
		const lastUpdatedField = urgencyRegulationCenterAndRoles.at(-1);

		if (!lastUpdatedField?.id || !lastUpdatedField?.user_roles.length) {
			Toast.fire({
				icon: "error",
				title: "Preencha todos os campos",
			});

			return;
		}

		setUrgencyRegulationCenterAndRoles([
			...urgencyRegulationCenterAndRoles,
			urgencyRegulationCenterAndRolesInitialData,
		]);
	}

	function handleUpdateUrgencyRegulationCenter(index: number, option: ReactSelectOption) {
		const updatedUrgencyRegulationCenter = [...urgencyRegulationCenterAndRoles];

		updatedUrgencyRegulationCenter[index]["id"] = String(option.value);
		updatedUrgencyRegulationCenter[index]["name"] = option.label;

		setUrgencyRegulationCenterAndRoles(updatedUrgencyRegulationCenter);
	}

	function handleUpdateRoles(index: number, options: ReactSelectOption[]) {
		const currentRoleId = currentAuth?.current_role?.id;

		const urcRoleId = urgencyRegulationCenterAndRoles[index].id;
		const profileRemains = options.filter((option) => option.value === currentRoleId);

		if (isCurrentUser && urcRoleId === currentUrcId && !profileRemains.length) {
			Toast.fire({
				icon: "error",
				title: "Não é possível remover seu perfil estando logado na CRU",
			});

			return;
		}

		const roles = options.map((option) => ({
			id: String(option.value),
			name: roleSlugs[option.label as keyof typeof roleSlugs],
		}));

		const updatedRoles = [...urgencyRegulationCenterAndRoles];
		updatedRoles[index]["user_roles"] = roles;

		setUrgencyRegulationCenterAndRoles(updatedRoles);
	}

	function handleRemoveField(index: number) {
		const updatedUrgencyRegulationCenterAndRoles = [...urgencyRegulationCenterAndRoles];
		updatedUrgencyRegulationCenterAndRoles.splice(index, 1);

		setUrgencyRegulationCenterAndRoles(updatedUrgencyRegulationCenterAndRoles);
	}

	useEffect(() => {
		const profiles = urgencyRegulationCenterAndRoles
			.map((urc) =>
				urc.user_roles.map((role) => ({
					urc_id: urc.id,
					role_id: role.id,
				}))
			)
			.flat();

		formik.setFieldValue("profiles", profiles);
	}, [urgencyRegulationCenterAndRoles]);

	useEffect(() => {
		formik.validateForm();
	}, [values.cbo]);

	return (
		<>
			<Row className="mb-4">
				<Col lg={4}>
					<FormattedFormControl
						id="council-number"
						label="Número conselho"
						labelClassName="fw-bold"
						isInvalid={!!errors.council_number}
						validationMessage={errors.council_number}
						{...formik.getFieldProps("council_number")}
					/>
				</Col>

				<Col lg={4}>
					<Form.Label className="fw-bold" htmlFor="CBO">
						CBO <RedAsterisk />
					</Form.Label>

					<OccupationsSelect
						inputId="CBO"
						isInvalid={!!errors.cbo}
						value={values.cbo ? { value: values.cbo, label: values.cbo_label } : null}
						placeholder="Selecione um CBO"
						onChange={(option) => handleChangeOccupation(option as ReactSelectOption)}
						isProfessionalRegistration={isProfessionalRegistration}
					/>

					{errors.cbo && <span className="text-danger">{errors.cbo}</span>}
				</Col>
			</Row>

			{isRegister && (
				<Row className="mb-4">
					<Col lg={4}>
						<FormattedFormControl
							type="password"
							id="password"
							label="Senha"
							labelClassName="fw-bold"
							isInvalid={!!errors.password}
							{...formik.getFieldProps("password")}
							isRequired
						/>

						<span className="text-gray-700">
							A senha deve conter no mínimo 8 caracteres, uma letra maiúscula, um
							número e um caractere especial
						</span>
					</Col>

					<Col lg={4}>
						<FormattedFormControl
							type="password"
							id="password-confirmation"
							label="Repita a senha"
							labelClassName="fw-bold"
							isInvalid={!!errors.password_confirmation}
							validationMessage={errors.password_confirmation}
							{...formik.getFieldProps("password_confirmation")}
							isRequired
						/>
					</Col>
				</Row>
			)}

			{/* urgency regulation centers and roles */}
			{urgencyRegulationCenterAndRoles.map((urgencyRegulationCenter, index) => {
				const { user_roles } = urgencyRegulationCenter;
				const selectedOptions = urgencyRegulationCenterAndRoles.map(
					(option) => option.name
				);

				if (can([admin, coordinator])) {
					return (
						<div key={`urgency-regulation-center-and-role-${index}`} className="mb-4">
							<Row key={`venous-access-field-${index}`}>
								<Col lg={4}>
									<Form.Label className="fw-bold" htmlFor={`unit-${index}`}>
										Unidade <RedAsterisk />
									</Form.Label>

									<UrgencyRegulationCentersSelect
										inputId={`unit-${index}`}
										urgencyRegulationCenterId={urgencyRegulationCenter.id}
										selectedOptions={selectedOptions}
										isInvalid={!urgencyRegulationCenter.name}
										onChange={(option) =>
											handleUpdateUrgencyRegulationCenter(
												index,
												option as ReactSelectOption
											)
										}
									/>
								</Col>

								<Col lg={4}>
									<Form.Label
										className="fw-bold"
										htmlFor={`patient-role-${index}`}
									>
										Perfil <RedAsterisk />
									</Form.Label>

									<RolesSelect
										inputId={`patient-role-${index}`}
										isInvalid={!user_roles.length}
										value={user_roles.map((role) => ({
											label: RoleMessages[
												role.name as keyof typeof RoleMessages
											],
											value: String(role.id),
										}))}
										placeholder="Selecione um perfil"
										onChange={(options) =>
											handleUpdateRoles(index, options as ReactSelectOption[])
										}
									/>
								</Col>

								<Col lg="auto" className="d-flex align-items-end gap-4">
									{urgencyRegulationCenterAndRoles.length < 3 && (
										<OverlayTrigger overlay={<Tooltip>Adicionar</Tooltip>}>
											<Button
												type="button"
												variant="secondary"
												className="btn-icon btn-sm h-38px w-38px"
												onClick={handleAddFields}
											>
												<FaPlus className="text-gray-700" />
											</Button>
										</OverlayTrigger>
									)}

									{index > 0 && (
										<OverlayTrigger overlay={<Tooltip>Remover</Tooltip>}>
											<Button
												type="button"
												variant="secondary"
												className="btn-icon btn-sm h-38px w-38px"
												onClick={() => handleRemoveField(index)}
												disabled={
													currentUrcId === urgencyRegulationCenter.id &&
													isCurrentUser
												}
											>
												<FaTrash className="text-gray-700" />
											</Button>
										</OverlayTrigger>
									)}
								</Col>
							</Row>

							<Row>
								<Col lg={4}>
									{!urgencyRegulationCenter.name && (
										<span className="text-danger">Campo obrigatório</span>
									)}
								</Col>

								<Col>
									{!user_roles.length && (
										<span className="text-danger">Campo obrigatório</span>
									)}
								</Col>
							</Row>
						</div>
					);
				}

				return null;
			})}
		</>
	);
}
