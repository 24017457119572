import { useEffect, useRef } from "react";

import { APIProvider, Map, MapProps, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";

import { useCanAccess } from "@/hooks/useCanAccess";
import { Role } from "@/enums/Role";
import { get } from "@/utils/tracking/accessors";

import "@/components/Tracking/googleMaps.scss";

type GoogleMapsTrackHistoryProps = {
	defaultOptions: DefaultMapOptions;
	trackHistory: TrackHistoryResponse | null;
} & MapProps;

const allowedRoles = [Role.manager];

type MapObjects = {
	path?: google.maps.Polyline;
	startMarker?: google.maps.marker.AdvancedMarkerElement;
	endMarker?: google.maps.marker.AdvancedMarkerElement;
};

type ApiHandlerProps = { trackHistory: TrackHistoryResponse | null };

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "";

function ApiHandler({ trackHistory }: ApiHandlerProps) {
	const { can } = useCanAccess();
	const map = useMap();
	const markerLibrary = useMapsLibrary("marker");
	const mapObjects = useRef<MapObjects | null>(null);

	useEffect(() => {
		if (!map || !can(allowedRoles) || !trackHistory || !markerLibrary) {
			return;
		}

		const controlsLeft = map.controls[google.maps.ControlPosition.LEFT_BOTTOM];

		const infoCard = get().trackHistoryInfoCardElement(trackHistory);
		const legend = get().trackHistoryLegendElement();

		controlsLeft.clear();
		controlsLeft.push(legend);
		controlsLeft.push(infoCard);

		mapObjects.current?.path?.setMap(null);

		if (mapObjects.current?.startMarker) {
			mapObjects.current.startMarker.map = null;
		}

		if (mapObjects.current?.endMarker) {
			mapObjects.current.endMarker.map = null;
		}

		const trackingData = trackHistory.tracking_data;

		const firstPoint = trackingData.at(0);
		const lastPoint = trackingData.at(-1);

		if (!firstPoint) {
			return;
		}

		const newMapObjects: MapObjects = {};

		const { AdvancedMarkerElement, PinElement } = markerLibrary;

		const startPin = new PinElement({
			background: "#3498DB",
			borderColor: "#3498DB",
			glyphColor: "white",
		});

		const endPin = new PinElement({
			background: "#F88015",
			borderColor: "#F88015",
			glyphColor: "white",
		});

		const originPosition = {
			lat: firstPoint.latitude,
			lng: firstPoint.longitude,
		};

		const startMarker = new AdvancedMarkerElement({
			map,
			position: originPosition,
			content: startPin.element,
			zIndex: 99,
		});

		newMapObjects.startMarker = startMarker;

		if (lastPoint && trackingData.length !== 1) {
			const endMarker = new AdvancedMarkerElement({
				map,
				position: {
					lat: lastPoint.latitude,
					lng: lastPoint.longitude,
				},
				content: endPin.element,
			});

			newMapObjects.endMarker = endMarker;

			const parsedDataArray = trackingData.map((value) => {
				return { lat: value.latitude, lng: value.longitude };
			});

			const ARROW_DECORATOR = {
				icon: {
					path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
					strokeColor: "#d52128",
					fillColor: "#d52128",
					fillOpacity: 0.7,
					strokeOpacity: 0.7,
				},
				offset: "100%",
				repeat: "200px",
			};

			const POLYLINE_CONFIG = {
				geodesic: true,
				strokeColor: "#d52128",
				strokeOpacity: 1.0,
				strokeWeight: 3,
				icons: [ARROW_DECORATOR],
			};

			const newPath = new google.maps.Polyline({
				...POLYLINE_CONFIG,
				path: parsedDataArray,
				map,
			});

			newMapObjects.path = newPath;
		}

		mapObjects.current = newMapObjects;
		map.setZoom(13);
		map.setCenter(originPosition);
	}, [map, trackHistory]);

	return null;
}

export function GoogleMaps({ defaultOptions, trackHistory, ...rest }: GoogleMapsTrackHistoryProps) {
	return (
		<div id="map-container">
			<APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
				<ApiHandler trackHistory={trackHistory} />
				<Map
					mapId="track-history-map"
					defaultCenter={{ lat: defaultOptions.latitude, lng: defaultOptions.longitude }}
					defaultZoom={defaultOptions.zoom}
					gestureHandling="greedy"
					{...rest}
				></Map>
			</APIProvider>
		</div>
	);
}
