import { useEffect } from "react";

import { Control, DomUtil, Map } from "leaflet";

import { get } from "@/utils/tracking/accessors";

type LegendProps = {
	map: Map | null;
	trackHistory: TrackHistoryResponse;
};

export function MapElements({ map, trackHistory }: LegendProps) {
	useEffect(() => {
		if (!map) {
			return;
		}
		const mapElements = new Control({ position: "bottomleft" });

		mapElements.onAdd = () => {
			const div = DomUtil.create("div", "m-0");
			const infoCard = get().trackHistoryInfoCardElement(trackHistory);
			const legend = get().trackHistoryLegendElement();

			div.append(infoCard);
			div.append(legend);

			return div;
		};

		mapElements.addTo(map);

		return () => {
			mapElements.remove();
		};
	}, [map, trackHistory]);

	return null;
}
