import { ChangeEvent, Fragment, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";

import { ImArrowLeft2 } from "react-icons/im";
import { FaPlus } from "react-icons/fa";

import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { usePusher } from "@/hooks/usePusher";
import { VehicleType } from "@/enums/VehicleType";
import { VehicleStatus } from "@/enums/VehicleStatus";
import { AttendanceType } from "@/enums/AttendanceType";
import { filterObjectExcluding } from "@/utils/filterObject";
import { radioOperationSchema } from "@/utils/validation/radioOperationSchema";
import { radioOperationInitialValues } from "@/utils/initialValues/radioOperation";

import { LastMedicalRegulationData } from "@/components/FleetControl/RadioOperation/LastMedicalRegulationData";
import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { LastCommittedVehicle } from "@/components/FleetControl/RadioOperation/LastCommittedVehicle";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { HistoryRegisterModal } from "@/components/Modals/HistoryRegisterModal";
import { OccurrenceDataModal } from "@/components/Modals/OccurrenceDataModal";
import { VehicleStatusModal } from "@/components/Modals/VehicleStatusModal";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { VehicleSelect } from "@/components/Selects/VehicleSelect";
import { UsersSelect } from "@/components/Selects/UsersSelect";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { LateralToolbar } from "@/components/LateralToolbar";
import { OccurrenceData } from "@/components/OccurrenceData";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";
import { Separator } from "@/components/Separator";
import { Bullet } from "@/components/Bullet";

type BaseOccupation = {
	code: string;
	name: string;
};

type FormattedOccupation = {
	occupationName: string;
	occupations: BaseOccupation[];
};

type VehicleSelectOption = ReactSelectOption & {
	extra: {
		vehicleStatus: number;
		vehicleType: number;
	};
};

type UserSelectOption = ReactSelectOption & {
	value: {
		userId: string;
		occupationId: string;
	};
};

export function RadioOperationForm() {
	const [activeFleetControl, setActiveFleetControl] = useState({} as RadioOperationFleet);
	const [lastMedicalRegulation, setLastMedicalRegulation] = useState<MedicalRegulation>(
		{} as MedicalRegulation
	);
	const [radioOperation, setRadioOperation] = useState({} as RadioOperationResponse);
	const [showHistoryRegisterModal, setShowHistoryRegisterModal] = useState(false);
	const [showOccurrenceDataModal, setShowOccurrenceDataModal] = useState(false);
	const [showVehicleStatusModal, setShowVehicleStatusModal] = useState(false);
	const [occurrence, setOccurrence] = useState<Attendable>({} as Attendable);
	const [attendanceType, setAttendanceType] = useState<number>();
	const [inAttendance, setInAttendance] = useState(false);
	const [isPostSubmit, setIsPostSubmit] = useState(true);
	const [changeReason, setChangeReason] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { Swal, Toast, toastRequestErrors, SwalToInAttendance } = useSwal();
	const { attendanceId } = useParams();
	const navigate = useNavigate();
	const { api } = useApi();
	const pusher = usePusher();

	const attendance = occurrence?.attendance;
	const attendableId = attendance?.id;
	const ticket = attendance?.ticket;

	const handleShowOccurrenceDataModal = () => setShowOccurrenceDataModal(true);
	const handleCloseOccurrenceDataModal = () => setShowOccurrenceDataModal(false);

	const handleShowVehicleStatusModal = () => setShowVehicleStatusModal(true);
	const handleCloseVehicleStatusModal = () => setShowVehicleStatusModal(false);

	const handleShowHistoryRegisterModal = () => setShowHistoryRegisterModal(true);
	const handleCloseHistoryRegisterModal = () => setShowHistoryRegisterModal(false);

	const formik = useFormik({
		initialValues: radioOperationInitialValues,
		validationSchema: radioOperationSchema,
		async onSubmit(data) {
			try {
				const filteredFormikValues = filterObjectExcluding(data, ["observation"]);

				const formattedUsers = data.radio_operation_fleet[0].users
					.filter(Boolean)
					.map((user) => {
						if (user.id) {
							return user;
						}

						return {
							external_professional: user.label,
							occupation_id: user.occupation_id,
						};
					});

				const formattedData = {
					...filteredFormikValues,
					attendance_id: attendance?.id,
					radio_operation_fleet: [
						{
							...data.radio_operation_fleet[0],
							users: formattedUsers,
						},
					],
					...(data.observation && {
						notes: [
							{
								observation: data.observation,
							},
						],
					}),
				};

				if (isPostSubmit) {
					await api.post(`/radio-operation`, formattedData);
				} else {
					await api.put(`/radio-operation/${radioOperation.id}`, formattedData);
				}

				Toast.fire({
					icon: "success",
					title: "Chamado registrado com sucesso",
				});

				navigate("/controle-de-frota/ocorrencias");
			} catch (error: any) {
				console.log(error);
				toastRequestErrors(error.response?.data.errors);
			}
		},
	});

	const { values } = formik;

	function setDatesValue(data: RadioOperationResponse) {
		if (!data.vehicle_requested_at) {
			formik.setFieldValue("vehicle_requested_at_date", dayjs().format("YYYY-MM-DD"));
			return;
		}

		if (!data.vehicle_dispatched_at) {
			formik.setFieldValue(
				"vehicle_dispatched_at_date",
				formatDate(data.vehicle_requested_at)
			);
			return;
		}

		if (!data.arrived_to_site_at) {
			formik.setFieldValue("arrived_to_site_at_date", formatDate(data.vehicle_dispatched_at));
			return;
		}

		if (!data.left_from_site_at) {
			formik.setFieldValue("left_from_site_at_date", formatDate(data.arrived_to_site_at));
			return;
		}

		if (!data.arrived_to_destination_at) {
			formik.setFieldValue(
				"arrived_to_destination_at_date",
				formatDate(data.left_from_site_at)
			);
			return;
		}

		if (!data.release_from_destination_at) {
			formik.setFieldValue(
				"release_from_destination_at_date",
				formatDate(data.arrived_to_destination_at)
			);
			return;
		}

		if (!data.vehicle_released_at) {
			formik.setFieldValue(
				"vehicle_released_at_date",
				formatDate(data.release_from_destination_at)
			);
			return;
		}
	}

	async function getRadioOperation() {
		try {
			const { data } = await api.get<RadioOperationResponse>(
				`/radio-operation/show-by-attendance/${attendanceId}`
			);

			const firstFleet = data.fleets[0];

			const awaitingFleetConfirmationValue = firstFleet?.status === 0 ? "1" : "0";
			const awaitingFleetConfirmation = firstFleet ? awaitingFleetConfirmationValue : "";

			const vehicle = data.vehicles.find((vehicle) => vehicle.id === firstFleet.vehicle_id);

			const users = firstFleet?.users.map((user) => ({
				id: user.id,
				label: user.name,
				occupation_id: user.pivot.occupation_id,
			}));

			const externalProfessionals = firstFleet?.external_professionals.map((user) => ({
				id: "",
				label: user.external_professional,
				occupation_id: user.occupation_id,
			}));

			const vehicleBaseCity = vehicle?.base?.city ? `${vehicle?.base?.city?.name} -` : "";

			const radioOperationFleet = firstFleet
				? {
						vehicle_id: firstFleet.vehicle_id,
						vehicle_label: `${vehicleBaseCity} ${vehicle?.vehicle_type?.name || ""} ${
							vehicle?.code
						} - ${vehicle?.license_plate}`,
						vehicle_extra: {
							vehicleStatus: 0,
							vehicleType: 0,
						},
						users: [...users, ...externalProfessionals],
				  }
				: ({} as RadioOperationFleet);

			setActiveFleetControl(radioOperationFleet);

			formik.setValues({
				arrived_to_destination_at_date: formatDate(data.arrived_to_destination_at || ""),
				arrived_to_destination_at_time: formatTime(data.arrived_to_destination_at || ""),
				arrived_to_destination_at: data.arrived_to_destination_at
					? formatDatetime(data.arrived_to_destination_at)
					: "",
				arrived_to_site_at_date: formatDate(data.arrived_to_site_at || ""),
				arrived_to_site_at_time: formatTime(data.arrived_to_site_at || ""),
				arrived_to_site_at: data.arrived_to_site_at
					? formatDatetime(data.arrived_to_site_at)
					: "",
				left_from_site_at_date: formatDate(data.left_from_site_at || ""),
				left_from_site_at_time: formatTime(data.left_from_site_at || ""),
				left_from_site_at: data.left_from_site_at
					? formatDatetime(data.left_from_site_at)
					: "",
				release_from_destination_at_date: formatDate(
					data.release_from_destination_at || ""
				),
				release_from_destination_at_time: formatTime(
					data.release_from_destination_at || ""
				),
				release_from_destination_at: data.release_from_destination_at
					? formatDatetime(data.release_from_destination_at)
					: "",
				vehicle_dispatched_at_date: formatDate(data.vehicle_dispatched_at || ""),
				vehicle_dispatched_at_time: formatTime(data.vehicle_dispatched_at || ""),
				vehicle_dispatched_at: data.vehicle_dispatched_at
					? formatDatetime(data.vehicle_dispatched_at)
					: "",
				vehicle_released_at_date: formatDate(data.vehicle_released_at || ""),
				vehicle_released_at_time: formatTime(data.vehicle_released_at || ""),
				vehicle_released_at: data.vehicle_released_at
					? formatDatetime(data.vehicle_released_at)
					: "",
				vehicle_requested_at_date: formatDate(data.vehicle_requested_at || ""),
				vehicle_requested_at_time: formatTime(data.vehicle_requested_at || ""),
				vehicle_requested_at: data.vehicle_requested_at
					? formatDatetime(data.vehicle_requested_at)
					: "",
				awaiting_fleet_confirmation: awaitingFleetConfirmation,
				radio_operation_fleet: [radioOperationFleet],
				observation: "",
			});

			setDatesValue(data);

			setRadioOperation(data);

			setIsPostSubmit(false);
		} catch (error: any) {
			console.log(error);

			formik.setFieldValue("vehicle_requested_at_date", dayjs().format("YYYY-MM-DD"));
		}
	}

	async function handleCheckAttendance() {
		try {
			const { data } = await api.get(`/attendance/check/radio-operation/${attendanceId}`);

			setInAttendance(true);
			setAttendanceType(data?.ticket_type_id);
		} catch (error: any) {
			console.log(error);

			setAttendanceType(error.response.data.ticket_type_id);
			setInAttendance(false);
		}
	}

	async function handleStartAttendance() {
		try {
			await api.post(`radio-operation/start-attendance/${attendanceId}`);

			setInAttendance(true);
		} catch (error: any) {
			const message = error.response.data.message;

			SwalToInAttendance(message);
		}
	}

	async function handleCloseAttendance() {
		if (!inAttendance) {
			return navigate("/controle-de-frota/ocorrencias");
		}

		Swal.fire({
			title: "As informações não salvas serão perdidas. Deseja continuar?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then(async (result: any) => {
			if (result.isConfirmed) {
				try {
					await api.put(`attendance/${attendanceId}/close`);

					Toast.fire({
						icon: "success",
						title: "Atendimento fechado com sucesso",
					});
				} catch (error: any) {
					console.log(error);

					toastRequestErrors(error.response?.data.errors ?? {});
				} finally {
					navigate("/controle-de-frota/ocorrencias");
				}
			}
		});
	}

	async function handleSearchAttendance() {
		const url =
			attendanceType === AttendanceType.PrimaryOccurrence
				? "/ticket/primary-attendance/"
				: "/ticket/secondary-attendance/";

		try {
			const { data } = await api.get(url + attendanceId);

			setOccurrence(data);
		} catch (error: any) {
			console.log(error);
			toastRequestErrors(error.response?.data.errors ?? {});
		}
	}

	async function handleSearchLastMedicalRegulation() {
		try {
			const { data } = await api.get(`/medical-regulation/latest/${attendableId}`);

			setLastMedicalRegulation(data);

			await getRadioOperation();
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleChangeTimeFields(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		datetimeFieldName: string,
		dateFieldValue: string,
		timeFieldName: string,
		nextDateFieldName?: string
	) {
		const time = event.target.value;

		formik.setFieldValue(timeFieldName, time);

		if (dateFieldValue) {
			formik.setFieldValue(datetimeFieldName, formatDatetime(`${dateFieldValue}T${time}`));
		}

		if (nextDateFieldName && time) {
			formik.setFieldValue(nextDateFieldName, dateFieldValue);
		}
	}

	function handleChangeDateFields(
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		dateFieldName: string,
		datetimeFieldName: string,
		timeFieldName: string,
		nextDateFieldName?: string
	) {
		const date = event.target.value;
		const dateIsValid = dayjs(date).isValid();
		const validDate = dateIsValid ? date : "";

		const time = dateIsValid ? String(values[timeFieldName as keyof typeof values]) : "";
		const datetime = dateIsValid ? formatDatetime(`${date}T${time}`) : "";

		formik.setFieldValue(dateFieldName, validDate);
		formik.setFieldValue(timeFieldName, time);
		formik.setFieldValue(datetimeFieldName, datetime);

		if (nextDateFieldName && time) {
			formik.setFieldValue(nextDateFieldName, validDate);
		}
	}

	function handleRefreshClosedAttendance() {
		const channel = pusher.subscribe(`private-cancel-attendance.refresh.${attendableId}`);

		channel.bind("RefreshCancelAttendance", () => {
			navigate("/controle-de-frota/ocorrencias");
		});
	}

	useEffect(() => {
		if (attendableId) {
			handleRefreshClosedAttendance();
		}

		return () => {
			pusher.unsubscribe(`private-refresh-cancel-attendance.${attendableId}`);
		};
	}, [attendableId]);

	useEffect(() => {
		if (attendanceId) {
			handleCheckAttendance();
		}
	}, [attendanceId]);

	useEffect(() => {
		if (attendanceType) {
			handleSearchAttendance();
		}
	}, [attendanceType]);

	useEffect(() => {
		if (attendableId) {
			handleSearchLastMedicalRegulation();
		}
	}, [attendableId]);

	const [ableOccupations, setAbleOccupations] = useState<AbleOccupation[]>([]);
	const [isLoadingAbleOccupations, setIsLoadingAbleOccupations] = useState(false);

	const vehicleId = values.radio_operation_fleet[0]?.vehicle_id;
	const vehicleLabel = values.radio_operation_fleet[0]?.vehicle_label;
	const vehicleExtra = values.radio_operation_fleet[0]?.vehicle_extra;

	useEffect(() => {
		async function fetchFleetAbleOccupations() {
			try {
				setIsLoadingAbleOccupations(true);

				const { data } = await api.get(
					`/radio-operation/fleet/able-occupations/${vehicleId}`
				);

				setAbleOccupations(data.results);
			} catch (error) {
				console.log(error);
			} finally {
				setIsLoadingAbleOccupations(false);
			}
		}

		if (vehicleId) {
			fetchFleetAbleOccupations();
		} else {
			setAbleOccupations([]);
			formik.setFieldValue("radio_operation_fleet[0].users", []);
		}
	}, [vehicleId]);

	function getFormattedOccupationName(ableOccupation: AbleOccupation) {
		const occupation = ableOccupation.occupation;
		const name = occupation ? occupation.name : "Médico";

		if (name === "Socorrista (exceto médicos e enfermeiros)") {
			return "Condutor";
		}

		return name;
	}

	const uniqueOccupations = ableOccupations
		.filter((occupation) => occupation.required)
		.map((ableOccupation) => {
			const formattedName = getFormattedOccupationName(ableOccupation);

			return {
				occupationName: formattedName,
				occupations: [{ code: ableOccupation.occupation_id, name: formattedName }],
			};
		});

	const groupedOccupations = ableOccupations.filter((occupation) => !occupation.required);
	const groupedOccupation = groupedOccupations.length
		? groupedOccupations.reduce<FormattedOccupation>(
				(accumulator, current, currentIndex) => {
					const slug = currentIndex === 0 ? "" : "/";
					const formattedName = getFormattedOccupationName(current);

					return {
						occupationName: `${accumulator.occupationName}${slug}${formattedName}`,
						occupations: [
							...accumulator.occupations,
							{ code: current.occupation_id, name: formattedName },
						],
					};
				},
				{ occupationName: "", occupations: [] }
		  )
		: undefined;

	const formattedOccupations = [...uniqueOccupations, groupedOccupation];
	const firstFleetUsers = values.radio_operation_fleet[0]?.users;

	function getUsersSelectFieldProps(occupations: string[], index: number) {
		const MEDIC_OCCUPATION_INITIAL_CODE = "2251";
		const isMedic = (occupationId: string | undefined) =>
			occupations.includes(MEDIC_OCCUPATION_INITIAL_CODE) &&
			occupationId?.includes(MEDIC_OCCUPATION_INITIAL_CODE);

		const selectedFleetUser = firstFleetUsers?.find(
			(user) => occupations.includes(user?.occupation_id) || isMedic(user?.occupation_id)
		);

		return {
			onChange(option: unknown) {
				const { value, label } = option as UserSelectOption;
				const occupationId = value.occupationId ?? occupations[0];

				formik.setFieldValue(
					`radio_operation_fleet[0].users[${index}].id`,
					value.userId ?? ""
				);
				formik.setFieldValue(
					`radio_operation_fleet[0].users[${index}].occupation_id`,
					occupationId
				);
				formik.setFieldValue(`radio_operation_fleet[0].users[${index}].label`, label);
			},
			value: {
				value: {
					userId: selectedFleetUser?.id,
					occupationId: selectedFleetUser?.occupation_id,
				},
				label: selectedFleetUser?.label,
			},
		};
	}

	const formatDatetime = (datetime: string) =>
		datetime ? dayjs(datetime).format("YYYY-MM-DDTHH:mm:ss") : "";

	const formatDate = (datetime: string) => (datetime ? dayjs(datetime).format("YYYY-MM-DD") : "");
	const formatTime = (datetime: string) => (datetime ? dayjs(datetime).format("HH:mm") : "");

	const maxDate = dayjs("9999-12-31").format("YYYY-MM-DD");

	function externalProfessionalSelectValue(
		occupations: BaseOccupation[],
		selectedOccupationId: string
	) {
		const occupation = occupations.find(
			(occupation) => occupation.code === selectedOccupationId
		);

		if (!occupation) {
			return null;
		}

		return { value: occupation.code, label: occupation.name };
	}

	const [showChangeReasonField, setShowChangeReasonField] = useState(false);

	function handleChangeVehicle() {
		setShowChangeReasonField(true);

		formik.setFieldValue("radio_operation_fleet[0]", undefined);
	}

	function hideChangeReason() {
		setShowChangeReasonField(false);
		setChangeReason("");
	}

	const [isUpdatingFleetControl, setIsUpdatingFleetControl] = useState(false);
	const [vehicleSelectKey, setVehicleSelectKey] = useState(0);

	async function updateFleetControl() {
		try {
			setIsUpdatingFleetControl(true);

			const formattedUsers = values.radio_operation_fleet[0].users
				.filter(Boolean)
				.map((user) => {
					return {
						id: user.id,
						external_professional: user.label,
						occupation_id: user.occupation_id,
					};
				});

			await api.put(`radio-operation/${radioOperation.id}/update-fleet`, {
				radio_operation_fleet: vehicleId
					? [
							{
								vehicle_id: vehicleId,
								users: formattedUsers,
							},
					  ]
					: null,
				change_reason: changeReason,
			});

			setActiveFleetControl(values.radio_operation_fleet[0]);

			hideChangeReason();

			setRadioOperation({
				...radioOperation,
				vehicle_requested_at: "",
			});

			formik.setFieldValue("vehicle_requested_at", "");
			formik.setFieldValue("vehicle_dispatched_at", "");

			Toast.fire({
				icon: "success",
				text: "Alteração de viatura realizada com sucesso.",
			});
		} catch (error: any) {
			console.log(error);

			const [firstMessage] = Object.values<string>(error.response.data.errors || {});

			Toast.fire({
				icon: "error",
				title: firstMessage || "Erro ao tentar realizar alteração de viatura!",
			});
		} finally {
			setIsUpdatingFleetControl(false);
			setVehicleSelectKey((previous) => previous + 1);
		}
	}

	function handleCancelUpdateFleetControl() {
		formik.setFieldValue("radio_operation_fleet[0]", activeFleetControl);
		hideChangeReason();
	}

	async function handleSelectVehicle(option: VehicleSelectOption) {
		const { value, label, extra } = option;

		const vehicleStatus = extra?.vehicleStatus;

		if (
			vehicleStatus === VehicleStatus.Committed ||
			vehicleStatus === VehicleStatus.Solicited
		) {
			const slug =
				vehicleStatus === VehicleStatus.Committed
					? "empenhada em uma"
					: "solicitada para outra";

			const { isConfirmed } = await Swal.fire({
				title: `VTR está ${slug} ocorrência`,
				text: "Deseja prosseguir?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
			});

			if (!isConfirmed) {
				return;
			}
		}

		formik.setFieldValue("radio_operation_fleet[0].vehicle_id", value);
		formik.setFieldValue("radio_operation_fleet[0].vehicle_label", label);
		formik.setFieldValue("radio_operation_fleet[0].vehicle_extra", extra);
	}

	const handleUpdateFleetControl = () => updateFleetControl();

	const showUpdateFleetControlButton =
		!isPostSubmit &&
		!showChangeReasonField &&
		!radioOperation.attendance.scene_recording &&
		radioOperation.vehicle_requested_at &&
		!radioOperation.arrived_to_site_at;

	const showFleetControlButtons = !isPostSubmit && showChangeReasonField;

	const { errors } = formik;

	const showNotificationHistoryRegister = Boolean(
		attendance?.observations?.length ||
			attendance?.medical_regulations?.length ||
			attendance?.radio_operation?.notes?.length
	);

	const vehicleTimetablesKey = `${values.vehicle_requested_at}${values.vehicle_dispatched_at}${values.arrived_to_site_at}${values.left_from_site_at}${values.arrived_to_destination_at}${values.release_from_destination_at}${values.vehicle_released_at}`;

	useEffect(() => {
		formik.validateForm();
	}, [values.awaiting_fleet_confirmation, vehicleTimetablesKey]);

	return (
		<>
			<LateralToolbar>
				<LateralToolbar.Button onClick={handleShowOccurrenceDataModal}>
					Dados da <br /> ocorrência
				</LateralToolbar.Button>

				<LateralToolbar.Button onClick={handleShowVehicleStatusModal}>
					Status das <br /> viaturas
				</LateralToolbar.Button>

				<LateralToolbar.Button
					onClick={handleShowHistoryRegisterModal}
					showNotificationIndicator={showNotificationHistoryRegister}
				>
					Histórico de registros
				</LateralToolbar.Button>
			</LateralToolbar>

			<Card>
				{isLoading ? (
					<div className="d-flex flex-center h-80vh">
						<Spinner
							variant="samu"
							animation="border"
							style={{ width: "4rem", height: "4rem" }}
						/>
					</div>
				) : (
					<>
						<Card.Header>
							<div className="d-flex justify-content-between align-items-center w-100 default-form-card-header">
								<div>
									{ticket?.opening_at
										? dayjs(ticket.opening_at).format(
												"[Aberto em] DD/MM/YYYY [às] HH:mm[hr]"
										  )
										: ""}
								</div>

								<h2 className="m-0">Rádio Operação</h2>

								<div>
									<button
										type="button"
										className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
										onClick={handleCloseAttendance}
									>
										<ImArrowLeft2 />
										Voltar
									</button>
								</div>
							</div>
						</Card.Header>

						<Card.Body>
							<OccurrenceData
								attendanceType={attendanceType}
								attendable={occurrence}
							/>

							<Separator />

							{lastMedicalRegulation.id && (
								<>
									<LastMedicalRegulationData
										medicalRegulation={lastMedicalRegulation}
									/>

									<Separator />
								</>
							)}

							{inAttendance && (
								<Form onSubmit={formik.handleSubmit} id="radio-operation-form">
									<Row>
										<h3>Controle de frota</h3>
										{Boolean(radioOperation?.fleet_histories?.length) && (
											<>
												<LastCommittedVehicle
													radioOperation={radioOperation}
												/>

												<Separator />
											</>
										)}

										<Row className="mb-4">
											<Col lg={8}>
												<Form.Label
													htmlFor="radio-operation-fleet-vehicle"
													className="fw-bold"
												>
													Selecione a VTR{" "}
													{!showFleetControlButtons && <RedAsterisk />}
												</Form.Label>

												<VehicleSelect
													key={vehicleSelectKey}
													onlyAvailable
													showNoVehicleOption={false}
													isDisabled={
														Boolean(
															activeFleetControl.vehicle_id ||
																isLoadingAbleOccupations
														) && !showChangeReasonField
													}
													inputId="radio-operation-fleet-vehicle"
													name="vehicle_id"
													formatOptionLabel={(data) => {
														const option = data as VehicleSelectOption;

														const label = option.label;
														const vehicleStatus =
															option.extra?.vehicleStatus;

														return (
															<div className="d-flex align-items-center gap-4">
																<Bullet
																	attribute={vehicleStatus}
																	isVehicleStatus
																/>
																{label}
															</div>
														);
													}}
													value={
														vehicleId
															? {
																	value: vehicleId,
																	label: vehicleLabel,
																	extra: vehicleExtra,
															  }
															: null
													}
													onChange={(option) =>
														handleSelectVehicle(
															option as VehicleSelectOption
														)
													}
													isInvalid={!vehicleId}
												/>

												{!vehicleId && (
													<span className="text-danger">
														Campo obrigatório
													</span>
												)}
											</Col>
										</Row>

										{isLoadingAbleOccupations ? (
											<Row className="mb-8">
												<Col lg={8} className="text-center">
													<Spinner animation="border" variant="samu" />
												</Col>
											</Row>
										) : (
											<Col lg={8}>
												<div className="fw-bold mb-2">
													Confirme a Equipe VTR:
												</div>

												<Row className="row-cols-2 row-gap-6 mb-6">
													{formattedOccupations.map(
														(occupation, index) => {
															if (!occupation) {
																return null;
															}

															const { occupationName, occupations } =
																occupation;
															const occupationCodes = occupations.map(
																(occupation) => occupation.code
															);

															const { value, onChange } =
																getUsersSelectFieldProps(
																	occupationCodes,
																	index
																);

															const isExternalProfessional =
																value.label && !value.value.userId;
															const hasMoreThanOneOccupation =
																occupationCodes.length > 1;

															const DRIVER_OCCUPATION = "515135";
															const isAeromedical =
																values.radio_operation_fleet[0]
																	?.vehicle_extra?.vehicleType ===
																VehicleType.aeromedical;

															if (
																occupationCodes.includes(
																	DRIVER_OCCUPATION
																) &&
																!value.label &&
																isAeromedical
															) {
																formik.setFieldValue(
																	`radio_operation_fleet[0].users[${index}].occupation_id`,
																	DRIVER_OCCUPATION
																);

																formik.setFieldValue(
																	`radio_operation_fleet[0].users[${index}].label`,
																	"Piloto CIOPAER"
																);
															}

															return (
																<Fragment
																	key={`radio-operation-fleet-occupation-${index}`}
																>
																	<Col>
																		<Form.Label
																			htmlFor={`radio-operation-fleet-occupation-${index}`}
																			className="fw-bold"
																		>
																			{occupationName}
																		</Form.Label>

																		<UsersSelect
																			inputId={`radio-operation-fleet-occupation-${index}`}
																			isCreatable
																			formatCreateLabel={(
																				inputValue
																			) =>
																				`Profissional externo: "${inputValue}"`
																			}
																			noOptionsMessage={({
																				inputValue,
																			}) =>
																				inputValue
																					? "Nenhum resultado encontrado"
																					: "Sem usuários cadastrados com esse(s) CBO(s). Digite o nome de um profissional externo"
																			}
																			isDisabled={Boolean(
																				activeFleetControl.vehicle_id &&
																					!showChangeReasonField
																			)}
																			occupations={
																				occupationCodes
																			}
																			placeholder={`Selecione um ${occupationName.toLowerCase()}`}
																			onChange={onChange}
																			value={
																				value.label
																					? value
																					: null
																			}
																		/>
																	</Col>

																	{isPostSubmit &&
																		isExternalProfessional &&
																		hasMoreThanOneOccupation && (
																			<Col>
																				<Form.Label
																					htmlFor={`radio-operation-fleet-external-professional-occupation-${index}`}
																					className="fw-bold"
																				>
																					CBO do
																					profissional
																					externo
																				</Form.Label>

																				<ReactSelect
																					inputId={`radio-operation-fleet-external-professional-occupation-${index}`}
																					options={occupations.map(
																						(
																							occupation
																						) => ({
																							value: occupation.code,
																							label: occupation.name,
																						})
																					)}
																					onChange={(
																						option
																					) => {
																						const {
																							value,
																						} =
																							option as ReactSelectOption;

																						formik.setFieldValue(
																							`radio_operation_fleet[0].users[${index}].occupation_id`,
																							value
																						);
																					}}
																					value={externalProfessionalSelectValue(
																						occupations,
																						String(
																							value
																								.value
																								.occupationId
																						)
																					)}
																				/>
																			</Col>
																		)}
																</Fragment>
															);
														}
													)}
												</Row>

												{showChangeReasonField && (
													<Row className="mb-6">
														<FormControlWithCharactersCount
															isRequired
															id="change-reason"
															as="textarea"
															label="Justificativa"
															placeholder="Descreva"
															labelClassName="fw-bold"
															maxLength={250}
															value={changeReason}
															isInvalid={!changeReason}
															validationMessage={
																!changeReason
																	? "Campo obrigatório"
																	: ""
															}
															onChange={(event) =>
																setChangeReason(
																	event.currentTarget.value
																)
															}
														/>
													</Row>
												)}
											</Col>
										)}

										<Row>
											<FormGroupRadio
												isRequired
												inline
												labelClassName="fw-bold"
												name="awaiting_fleet_confirmation"
												requestedValue={values.awaiting_fleet_confirmation}
												onChange={formik.handleChange}
												isInvalid={!!errors.awaiting_fleet_confirmation}
											>
												<FormRadio
													label="Aguardar confirmação da equipe"
													value="1"
													id="awaiting-fleet-confirmation-yes"
												/>
												<FormRadio
													label="Confirmar equipe VTR"
													value="0"
													id="awaiting-fleet-confirmation-no"
												/>
											</FormGroupRadio>

											{errors.awaiting_fleet_confirmation && (
												<span className="text-danger">
													{errors.awaiting_fleet_confirmation}
												</span>
											)}
										</Row>

										{showUpdateFleetControlButton && (
											<div className="mt-6">
												<button
													className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2"
													onClick={handleChangeVehicle}
												>
													<FaPlus /> Alterar empenho de VTR
												</button>
											</div>
										)}

										{showFleetControlButtons && (
											<div className="mt-6 d-flex align-items-center gap-2">
												<button
													type="button"
													className="btn button-bg-light-color-gray-400 py-2"
													onClick={handleCancelUpdateFleetControl}
												>
													Cancelar
												</button>

												<button
													type="button"
													className="btn button-bg-samu-color-white py-2"
													onClick={handleUpdateFleetControl}
													disabled={
														!changeReason || isUpdatingFleetControl
													}
												>
													{isUpdatingFleetControl ? (
														<Spinner animation="border" size="sm" />
													) : (
														"Salvar"
													)}
												</button>
											</div>
										)}
									</Row>

									<Separator />

									<div>
										<h3>Observações</h3>

										{radioOperation?.notes?.map((note, index) => (
											<div
												key={`radio-operation-note-${index}`}
												className="fw-bold"
											>
												<Row>
													<Col lg={4}>
														<span>Data e hora: </span>
														<span className="fw-bold">
															{dayjs(note.datetime).format(
																"DD/MM/YYYY HH:mm:ss"
															)}
														</span>
													</Col>

													<Col>
														<span>Profissional: </span>
														<span className="fw-bold">
															{note.responsible_professional}
														</span>
													</Col>
												</Row>

												<Row className="mb-4">
													<Col>
														<span>Observação: </span>
														<span>{note.observation}</span>
													</Col>
												</Row>
											</div>
										))}

										<Col lg={10}>
											<FormControlWithCharactersCount
												as="textarea"
												label="Observação"
												labelClassName="fw-bold"
												placeholder="Descreva"
												maxLength={2000}
												{...formik.getFieldProps("observation")}
											/>
										</Col>
									</div>

									<Separator />

									<Row>
										<h3>Horários</h3>

										<Row className="my-4 row-gap-4">
											<Col lg={3}>
												<Form.Label className="fw-bold text-nowrap">
													Solicitação VTR
												</Form.Label>

												<Row className="align-items-start">
													<Col lg={6}>
														<FormattedFormControl
															type="date"
															id="vehicle-requested-at-date"
															name="vehicle_requested_at_date"
															value={values.vehicle_requested_at_date}
															onChange={(event) =>
																handleChangeDateFields(
																	event,
																	"vehicle_requested_at_date",
																	"vehicle_requested_at",
																	"vehicle_requested_at_time",
																	"vehicle_dispatched_at_date"
																)
															}
															isInvalid={
																!!errors.vehicle_requested_at
															}
															validationMessage={
																errors.vehicle_requested_at
															}
															max={maxDate}
														/>
													</Col>

													<Col lg={6}>
														<FormattedFormControl
															type="time"
															id="vehicle-requested-at-time"
															value={values.vehicle_requested_at_time}
															onChange={(event) =>
																handleChangeTimeFields(
																	event,
																	"vehicle_requested_at",
																	values.vehicle_requested_at_date,
																	"vehicle_requested_at_time",
																	"vehicle_dispatched_at_date"
																)
															}
															isInvalid={
																!!errors.vehicle_requested_at
															}
														/>
													</Col>
												</Row>
											</Col>

											<Col lg={3}>
												<Form.Label className="fw-bold text-nowrap">
													Saída VTR
												</Form.Label>

												<Row className="align-items-start">
													<Col lg={6}>
														<FormattedFormControl
															type="date"
															id="vehicle-dispatched-at-date"
															name="vehicle_dispatched_at_date"
															value={
																values.vehicle_dispatched_at_date
															}
															onChange={(event) =>
																handleChangeDateFields(
																	event,
																	"vehicle_dispatched_at_date",
																	"vehicle_dispatched_at",
																	"vehicle_dispatched_at_time",
																	"arrived_to_site_at_date"
																)
															}
															isInvalid={
																!!errors.vehicle_dispatched_at
															}
															validationMessage={
																errors.vehicle_dispatched_at
															}
															max={maxDate}
														/>
													</Col>

													<Col lg={6}>
														<FormattedFormControl
															type="time"
															id="vehicle-dispatched-at-time"
															value={
																values.vehicle_dispatched_at_time
															}
															onChange={(event) =>
																handleChangeTimeFields(
																	event,
																	"vehicle_dispatched_at",
																	values.vehicle_dispatched_at_date,
																	"vehicle_dispatched_at_time",
																	"arrived_to_site_at_date"
																)
															}
															isInvalid={
																!!errors.vehicle_dispatched_at
															}
														/>
													</Col>
												</Row>
											</Col>

											<Col lg={3}>
												<Form.Label className="fw-bold text-nowrap">
													Chegada local
												</Form.Label>

												<Row className="align-items-start">
													<Col lg={6}>
														<FormattedFormControl
															type="date"
															id="arrived-to-site-at-date"
															name="arrived_to_site_at_date"
															value={values.arrived_to_site_at_date}
															onChange={(event) =>
																handleChangeDateFields(
																	event,
																	"arrived_to_site_at_date",
																	"arrived_to_site_at",
																	"arrived_to_site_at_time",
																	"left_from_site_at_date"
																)
															}
															isInvalid={!!errors.arrived_to_site_at}
															validationMessage={
																errors.arrived_to_site_at
															}
															max={maxDate}
														/>
													</Col>

													<Col lg={6}>
														<FormattedFormControl
															type="time"
															id="arrived-to-site-at-time"
															value={values.arrived_to_site_at_time}
															onChange={(event) =>
																handleChangeTimeFields(
																	event,
																	"arrived_to_site_at",
																	values.arrived_to_site_at_date,
																	"arrived_to_site_at_time",
																	"left_from_site_at_date"
																)
															}
															isInvalid={!!errors.arrived_to_site_at}
														/>
													</Col>
												</Row>
											</Col>

											<Col lg={3}>
												<Form.Label className="fw-bold text-nowrap">
													Saída local
												</Form.Label>

												<Row className="align-items-start">
													<Col lg={6}>
														<FormattedFormControl
															type="date"
															id="left-from-site-at-date"
															name="left_from_site_at_date"
															value={values.left_from_site_at_date}
															onChange={(event) =>
																handleChangeDateFields(
																	event,
																	"left_from_site_at_date",
																	"left_from_site_at",
																	"left_from_site_at_time",
																	"arrived_to_destination_at_date"
																)
															}
															isInvalid={!!errors.left_from_site_at}
															validationMessage={
																errors.left_from_site_at
															}
															max={maxDate}
														/>
													</Col>

													<Col lg={6}>
														<FormattedFormControl
															type="time"
															id="left-from-site-at-time"
															value={values.left_from_site_at_time}
															onChange={(event) =>
																handleChangeTimeFields(
																	event,
																	"left_from_site_at",
																	values.left_from_site_at_date,
																	"left_from_site_at_time",
																	"arrived_to_destination_at_date"
																)
															}
															isInvalid={!!errors.left_from_site_at}
														/>
													</Col>
												</Row>
											</Col>

											<Col lg={3}>
												<Form.Label className="fw-bold text-nowrap">
													Chegada destino
												</Form.Label>

												<Row className="align-items-start">
													<Col lg={6}>
														<FormattedFormControl
															type="date"
															id="arrived-to-destination-at-date"
															name="arrived_to_destination_at_date"
															value={
																values.arrived_to_destination_at_date
															}
															onChange={(event) =>
																handleChangeDateFields(
																	event,
																	"arrived_to_destination_at_date",
																	"arrived_to_destination_at",
																	"arrived_to_destination_at_time",
																	"release_from_destination_at_date"
																)
															}
															isInvalid={
																!!errors.arrived_to_destination_at
															}
															validationMessage={
																errors.arrived_to_destination_at
															}
															max={maxDate}
														/>
													</Col>

													<Col lg={6}>
														<FormattedFormControl
															type="time"
															id="arrived-to-destination-at-time"
															value={
																values.arrived_to_destination_at_time
															}
															onChange={(event) =>
																handleChangeTimeFields(
																	event,
																	"arrived_to_destination_at",
																	values.arrived_to_destination_at_date,
																	"arrived_to_destination_at_time",
																	"release_from_destination_at_date"
																)
															}
															isInvalid={
																!!errors.arrived_to_destination_at
															}
														/>
													</Col>
												</Row>
											</Col>

											<Col lg={3}>
												<Form.Label className="fw-bold text-nowrap">
													Liberação destino
												</Form.Label>

												<Row className="align-items-start">
													<Col lg={6}>
														<FormattedFormControl
															type="date"
															id="release-from-destination-at-date"
															name="release_from_destination_at_date"
															value={
																values.release_from_destination_at_date
															}
															onChange={(event) =>
																handleChangeDateFields(
																	event,
																	"release_from_destination_at_date",
																	"release_from_destination_at",
																	"release_from_destination_at_time",
																	"vehicle_released_at_date"
																)
															}
															isInvalid={
																!!errors.release_from_destination_at
															}
															validationMessage={
																errors.release_from_destination_at
															}
															max={maxDate}
														/>
													</Col>

													<Col lg={6}>
														<FormattedFormControl
															type="time"
															id="release-from-destination-at-time"
															value={
																values.release_from_destination_at_time
															}
															onChange={(event) =>
																handleChangeTimeFields(
																	event,
																	"release_from_destination_at",
																	values.release_from_destination_at_date,
																	"release_from_destination_at_time",
																	"vehicle_released_at_date"
																)
															}
															isInvalid={
																!!errors.release_from_destination_at
															}
														/>
													</Col>
												</Row>
											</Col>

											<Col lg={3}>
												<Form.Label className="fw-bold text-nowrap">
													Liberação VTR
												</Form.Label>

												<Row className="align-items-start">
													<Col lg={6}>
														<FormattedFormControl
															type="date"
															id="vehicle-released-at-date"
															name="vehicle_released_at_date"
															value={values.vehicle_released_at_date}
															onChange={(event) =>
																handleChangeDateFields(
																	event,
																	"vehicle_released_at_date",
																	"vehicle_released_at",
																	"vehicle_released_at_time"
																)
															}
															isInvalid={!!errors.vehicle_released_at}
															validationMessage={
																errors.vehicle_released_at
															}
															max={maxDate}
														/>
													</Col>

													<Col lg={6}>
														<FormattedFormControl
															type="time"
															id="vehicle-released-at-time"
															value={values.vehicle_released_at_time}
															onChange={(event) =>
																handleChangeTimeFields(
																	event,
																	"vehicle_released_at",
																	values.vehicle_released_at_date,
																	"vehicle_released_at_time"
																)
															}
															isInvalid={!!errors.vehicle_released_at}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Row>
								</Form>
							)}

							<div className="d-flex justify-content-center mt-16 gap-4">
								{inAttendance && (
									<button
										type="button"
										className="btn button-bg-light-color-gray-400 gap-2 w-100px"
										title="Ao clicar o atendimento voltará ao status anterior e ficará disponível para que outros usuários possam atendê-lo."
										onClick={handleCloseAttendance}
									>
										Fechar
									</button>
								)}

								<button
									type={inAttendance ? "submit" : "button"}
									className="btn button-bg-samu-color-white"
									onClick={!inAttendance ? handleStartAttendance : () => {}}
									disabled={
										(inAttendance && !formik.isValid) || formik.isSubmitting
									}
									form="radio-operation-form"
								>
									{!inAttendance ? (
										"Iniciar rádio operação"
									) : formik.isSubmitting ? (
										<Spinner animation="border" size="sm" />
									) : (
										"Registrar"
									)}
								</button>
							</div>
						</Card.Body>
					</>
				)}
			</Card>

			<OccurrenceDataModal
				showOccurrenceDataModal={showOccurrenceDataModal}
				handleCloseOccurrenceDataModal={handleCloseOccurrenceDataModal}
				attendanceId={attendance?.id}
			/>

			<VehicleStatusModal
				showVehicleStatusModal={showVehicleStatusModal}
				handleCloseVehicleStatusModal={handleCloseVehicleStatusModal}
				handleShowVehicleStatusModal={handleShowVehicleStatusModal}
			/>

			<HistoryRegisterModal
				showHistoryRegisterModal={showHistoryRegisterModal}
				handleCloseHistoryRegisterModal={handleCloseHistoryRegisterModal}
				attendanceId={attendance?.id}
			/>
		</>
	);
}
