import { useEffect } from "react";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { placeInitialValues } from "@/utils/initialValues/place";
import { placeValidationSchema } from "@/utils/validation/placeValidationSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

type Props = {
	showPlaceModal: boolean;
	handleClosePlaceModal: () => void;
	fetchPaginatedPlaces: () => void;
	currentPlace?: Place;
};

export function PlaceModal({
	showPlaceModal,
	handleClosePlaceModal,
	fetchPaginatedPlaces,
	currentPlace,
}: Props) {
	const { Toast, toastRequestErrors } = useSwal();
	const { currentAuth } = useAuth();
	const { api } = useApi();

	const isEdit = currentPlace && Object.entries(currentPlace).length;

	const formik = useFormik({
		initialValues: placeInitialValues,
		validationSchema: placeValidationSchema,
		async onSubmit({ name }, { setSubmitting }) {
			try {
				const method = isEdit ? "put" : "post";
				const route = isEdit ? `/place/${currentPlace?.id}` : "/place";

				await api[method](route, { name });

				handleCloseModal();
				fetchPaginatedPlaces();

				Toast.fire({
					icon: "success",
					title: `Local ${currentPlace ? "atualizado" : "cadastrado"} com sucesso!`,
				});
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	function handleCloseModal() {
		formik.resetForm();

		handleClosePlaceModal();
	}

	const { values, errors } = formik;

	useEffect(() => {
		if (isEdit) {
			formik.setFieldValue("name", currentPlace?.name || "");
		}
	}, [currentPlace]);

	useEffect(() => {
		if (showPlaceModal) {
			formik.validateForm();
		}
	}, [values.name, showPlaceModal]);

	return (
		<Modal size="lg" show={showPlaceModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{isEdit ? "Editar" : "Cadastrar"} local</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
					<FormattedFormControl
						id="name"
						label="Nome do local"
						labelClassName="fw-bold"
						isInvalid={!!errors.name}
						{...formik.getFieldProps("name")}
						validationMessage={errors.name}
						isRequired
					/>

					<FormattedFormControl
						id="urc"
						label="CRU"
						value={currentAuth?.current_urgency_regulation_center?.name}
						labelClassName="fw-bold"
						disabled
					/>

					<div className="d-flex justify-content-center gap-4 mt-5">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : isEdit ? (
								"Salvar"
							) : (
								"Cadastrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
