import { useRef } from "react";

import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps, Additional } from "../ReactSelect";

type VehicleSelectProps = ReactSelectProps & {
	onlyAvailable?: boolean;
	onlyTrackable?: boolean;
	showNoVehicleOption?: boolean;
	showAllVehicles?: boolean;
	fetchRoute?: string;
};

export function VehicleSelect({
	onlyAvailable,
	onlyTrackable = false,
	showNoVehicleOption = true,
	showAllVehicles = false,
	fetchRoute = "/vehicles",
	...rest
}: VehicleSelectProps) {
	const abortControllerRef = useRef(new AbortController());
	const firstRenderRef = useRef(true);
	const { api } = useApi();

	async function fetchVehicles(page: number, search: string = "") {
		abortControllerRef.current.abort();
		abortControllerRef.current = new AbortController();

		const { data } = await api.get(fetchRoute, {
			params: {
				page,
				search,
				...(onlyTrackable && { only_trackable: true }),
				...(onlyAvailable && { by_disponibility: true }),
				...(showAllVehicles && { show_all: true }),
			},
			signal: abortControllerRef.current.signal,
		});

		return data as PaginatedVehicles;
	}

	const loadAsyncVehiclesOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: Vehicle, { page }: Additional) {
			try {
				if (firstRenderRef.current) {
					firstRenderRef.current = false;
					const defaultOptions = showNoVehicleOption
						? [
								{
									label: "Sem viatura",
									value: "",
								},
						  ]
						: [];

					return {
						options: defaultOptions,
						hasMore: true,
						additional: {
							page: 1,
						},
					};
				}

				const vehicles = await fetchVehicles(page, search);

				return {
					options: vehicles.results?.map((vehicle) => ({
						value: vehicle.id,
						label: vehicle?.description,
						extra: {
							vehicleStatus: vehicle.latest_vehicle_status_history?.vehicle_status_id,
							vehicleType: vehicle.vehicle_type?.id,
						},
					})),
					hasMore: Boolean(vehicles.links?.next),
					additional: {
						page: page + 1,
					},
				};
			} catch (error) {
				console.error(error);

				return {
					options: [],
					hasMore: false,
					additional: {
						page: 1,
					},
				};
			}
		},
	};

	return (
		<ReactSelect
			{...loadAsyncVehiclesOptionsProps}
			{...rest}
			placeholder="Selecione uma viatura"
		/>
	);
}
