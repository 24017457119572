import { useEffect, useState } from "react";

import { MapContainer, MapContainerProps, Marker, TileLayer } from "react-leaflet";
import { Icon, Map, Symbol } from "leaflet";

import { PolylineDecorator } from "./PolylineDecorator";
import { MapElements } from "./MapElements";

import "react-leaflet-fullscreen-control";
import "leaflet/dist/leaflet.css";

type OpenStreetMapsProps = {
	defaultOptions: DefaultMapOptions;
	trackHistory: TrackHistoryResponse | null;
} & MapContainerProps;

const originIcon = new Icon({
	iconUrl: `${window.location.origin}/media/icons/tracking/origin.svg`,
	iconSize: [45, 45],
});

const destinationIcon = new Icon({
	iconUrl: `${window.location.origin}/media/icons/tracking/destination.svg`,
	iconSize: [45, 45],
});

const arrowPattern = [
	{
		offset: 50,
		repeat: 100,
		symbol: Symbol.arrowHead({
			pixelSize: 15,
			pathOptions: { fillOpacity: 0.7, weight: 0, color: "#3388FF", opacity: 0.7 },
		}),
	},
];

export function OpenStreetMaps({ defaultOptions, trackHistory, ...rest }: OpenStreetMapsProps) {
	const [map, setMap] = useState<Map | null>(null);
	const { tracking_data: trackingData } = trackHistory || {};

	const positions =
		trackingData?.map((value) => ({ lat: value.latitude, lng: value.longitude })) || [];

	const firstPoint = positions.at(0);
	const lastPoint = positions.at(-1);

	useEffect(() => {
		if (!trackHistory || !map) {
			return;
		}

		const { tracking_data: trackingData } = trackHistory;

		if (!trackingData.length) {
			return;
		}
		const firstPoint = trackingData.at(0) as TrackingData;

		const center = { lat: firstPoint.latitude, lng: firstPoint.longitude };
		map.setView(center, 13);
	}, [trackHistory, map]);

	return (
		<MapContainer
			ref={setMap}
			center={{ lat: defaultOptions.latitude, lng: defaultOptions.longitude }}
			zoom={defaultOptions.zoom}
			fullscreenControl
			attributionControl={false}
			{...rest}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				maxZoom={25}
			/>

			{firstPoint?.lat && (
				<Marker position={{ lat: firstPoint.lat, lng: firstPoint.lng }} icon={originIcon} />
			)}
			{lastPoint && positions.length !== 1 && (
				<Marker
					position={{ lat: lastPoint.lat, lng: lastPoint.lng }}
					icon={destinationIcon}
				/>
			)}

			{trackHistory && trackingData?.length && (
				<>
					<PolylineDecorator
						patterns={arrowPattern}
						positions={positions}
						pathOptions={{ color: "#45bdff", fillColor: "#45bdff" }}
					/>
					<MapElements trackHistory={trackHistory} map={map} />
				</>
			)}
		</MapContainer>
	);
}
