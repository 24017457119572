import { useEffect, useRef } from "react";

import { Polyline, PolylineProps, useMap } from "react-leaflet";
import { Polyline as LeafletPolyline, polylineDecorator, PolylineDecoratorOptions } from "leaflet";
import { LineString, MultiLineString } from "geojson";

import "leaflet-arrowheads";
import "leaflet-polylinedecorator";

type PolylineRef = (PolylineProps & LeafletPolyline<LineString | MultiLineString, any>) | null;

type Props = PolylineProps & PolylineDecoratorOptions;

export const PolylineDecorator = ({ patterns, ...rest }: Props) => {
	const polylineRef = useRef<PolylineRef>(null);
	const map = useMap();

	useEffect(() => {
		if (!map || !polylineRef.current) {
			return;
		}

		const decorator = polylineDecorator(polylineRef.current, { patterns });

		decorator.addTo(map);

		return () => {
			decorator.removeFrom(map);
		};
	}, [rest.positions]);

	return <Polyline {...rest} ref={polylineRef} />;
};
