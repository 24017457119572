import { Route, Routes } from "react-router-dom";

import { Tracking } from "@/pages/Tracking";
import { TrackHistory } from "@/pages/TrackHistory";

export function TrackingRoutes() {
	return (
		<Routes>
			<Route path="/mapa-de-viaturas" element={<Tracking />} />
			<Route path="/historico-de-percurso" element={<TrackHistory />} />
		</Routes>
	);
}
