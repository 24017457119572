import dayjs from "dayjs";
import { DateSchema } from "yup";

import Yup from "@/utils/mixins/yup";

export const trackHistoryValidationSchema = Yup.object().shape({
	vehicle: Yup.object().shape({
		value: Yup.string().uuid().required("Campo obrigatório"),
	}),
	start_date: Yup.date().required("Campo obrigatório"),
	end_date: Yup.date()
		.required("Campo obrigatório")
		.when("start_date", (start_date, schema: DateSchema) =>
			Boolean(start_date)
				? schema.max(dayjs(start_date).add(2, "days"), "Período máximo de 48h")
				: schema
		),
});
