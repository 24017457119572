import { renderToStaticMarkup, renderToString } from "react-dom/server";
import dayjs from "dayjs";
import classNames from "clsx";

import {
	TrackingVehicleImagesMessages,
	VehicleStatus,
	VehicleStatusMessages,
	VehicleStatusesTextColorsClasses,
	unmodifiableVehicleStatuses,
} from "@/enums/VehicleStatus";

import { AttendanceRedirect } from "@/components/AttendanceRedirect";
import { TrackHistoryInfoCard } from "@/components/Tracking/TrackHistory/TrackHistoryInfoCard";
import { TrackHistoryLegend } from "@/components/Tracking/TrackHistory/TrackHistoryLegend";

function getLegendContent(
	showNoCommunicationIndicator = true,
	statuses: MapVehicleStatuses = "all"
) {
	const container = document.createElement("div");
	container.classList.add(
		"bg-samu",
		"py-2",
		"w-100",
		"d-flex",
		"justify-content-around",
		"align-items-center",
		"flex-wrap",
		"gap-2"
	);

	const allStatuses = Object.entries(TrackingVehicleImagesMessages);
	const isAll = statuses === "all";

	const vehicleStatuses = isAll
		? allStatuses
		: allStatuses.filter(([status]) => statuses.includes(Number(status)));

	const vehiclesImagesMessages = [
		...vehicleStatuses,
		showNoCommunicationIndicator ? ["no-communication", "no-communication"] : undefined,
	].filter(Boolean) as Array<string[]>;

	function createLegendElement(legendText: string, pathname: string) {
		const image = document.createElement("img");
		image.src = `${window.location.origin}/media/icons/tracking/${pathname}.svg`;
		image.style.transform = "scale(0.8)";

		const text = document.createElement("span");
		text.textContent = legendText;
		text.classList.add("text-white", "fw-bold", "fs-6");

		const legend = document.createElement("div");
		legend.classList.add("d-flex", "align-items-center", "gap-1");
		legend.append(image, text);

		container.append(legend);
	}

	vehiclesImagesMessages.forEach(([status, filename]) => {
		const imagePathname = `${filename}-vehicle`;

		const isNoCommunication = status === "no-communication";

		const noCommunicationMessage = "Sem comunicação";
		const defaultMessage = VehicleStatusMessages[Number(status) as VehicleStatus];

		const legendText = isNoCommunication ? noCommunicationMessage : defaultMessage;

		createLegendElement(legendText, imagePathname);
	});

	createLegendElement("Base", "default-base");

	return container;
}

function getContentString(data: TrackingExtraData) {
	const hasStatus = data.status !== null && data.status !== undefined;
	const isSolicitedOrCommited = unmodifiableVehicleStatuses.includes(
		data.status as VehicleStatus
	);
	const hasAttendance = data.attendance !== null && isSolicitedOrCommited;
	const isUnavailable = data.status === VehicleStatus.Unavailable;
	const isNoCommunication = data.status === null;

	function getStatus() {
		if (hasStatus) {
			if (hasAttendance) {
				const status = data.status as VehicleStatus;

				const attendanceRedirect = renderToString(
					<AttendanceRedirect attendanceId={data.attendance?.id}>
						<span
							className={classNames(
								"text-decoration-underline",
								VehicleStatusesTextColorsClasses[status]
							)}
						>
							{VehicleStatusMessages[status]} - N° {data.attendance?.number}
						</span>
					</AttendanceRedirect>
				);

				return attendanceRedirect;
			}

			if (isUnavailable) {
				return `${VehicleStatusMessages[data.status as VehicleStatus]} - ${
					data.description
				}`;
			}

			return VehicleStatusMessages[data.status as VehicleStatus];
		}

		if (isNoCommunication) {
			return "Sem comunicação";
		}

		return "Status indefinido, relação não configurada";
	}

	const status = getStatus();

	return `
        <div class="d-flex align-items-start flex-column gap-2">
            <h3 class="text-samu">${data.name}</h3>
            <strong class="${VehicleStatusesTextColorsClasses[data.status as VehicleStatus]}">
				${status}
			</strong>
            <strong>
                Última conexão: <span class="fw-normal">${dayjs(data.lastConnection).format(
					"DD/MM/YYYY HH:mm"
				)}</span>
            </strong>
            <strong>
                Velocidade: <span class="fw-normal">${data.speed} km/h</span>
            </strong>
        </div>
    `;
}

function getTrackingExtra(data: VehicleTrackingData, showNoCommunicationIndicator = true) {
	const lastConnectionIsGreaterThanOrEqualToFifteenMinutes =
		dayjs().diff(data.last_connection, "minute") >= 15;

	const lastVehicleStatus = data.vehicle?.latest_vehicle_status_history?.vehicle_status_id as
		| VehicleStatus
		| undefined;

	const needsToShowIndicator =
		showNoCommunicationIndicator && lastConnectionIsGreaterThanOrEqualToFifteenMinutes;

	const lastStatus = needsToShowIndicator ? null : lastVehicleStatus;

	const formattedName = getTrackingName(data);

	const extra = {
		imei: data.imei,
		name: formattedName,
		lastConnection: data.last_connection,
		speed: data.speed,
		status: lastStatus,
		attendance: data.vehicle?.latest_vehicle_status_history.attendance,
		description: data.vehicle?.latest_vehicle_status_history.description,
	};

	return extra;
}

function getTrackingName(data: VehicleTrackingData) {
	const vehicleType = data.vehicle?.vehicle_type?.name || "";
	const code = data.vehicle?.code || "";
	const city = data.vehicle?.base?.city?.name || "";

	const name = `${vehicleType} ${code} ${city && ` - ${city}`}`;

	return name;
}

function getTrackingImage(status?: VehicleStatus | null) {
	const filename = status ? TrackingVehicleImagesMessages[status] : "no-communication";

	return `${window.location.origin}/media/icons/tracking/${filename}-vehicle.svg`;
}

function getTrackingOptions(data: VehicleTrackingData, showNoCommunicationIndicator = true) {
	const trackingExtra = getTrackingExtra(data, showNoCommunicationIndicator);
	const trackingImage = getTrackingImage(trackingExtra.status);

	const options = {
		position: {
			lat: data.latitude,
			lng: data.longitude,
		},
		title: trackingExtra.name,
		icon: trackingImage,
		extra: trackingExtra,
	};

	return options;
}

function getDefaultOptions(): DefaultMapOptions {
	return {
		latitude: Number(-4.0911583333333335),
		longitude: Number(-38.49744),
		zoom: Number(10),
	};
}

function getTrackHistoryInfoCardElement(trackHistory: TrackHistoryResponse): HTMLDivElement {
	const infoCardData = {
		vehicle: trackHistory.vehicle,
		startAddress: trackHistory.start_address,
		endAddress: trackHistory.end_address,
		averageSpeed: Number(trackHistory.average_speed).toFixed(2),
	};

	const staticHTML = renderToStaticMarkup(<TrackHistoryInfoCard data={infoCardData} />);
	const element = document.createElement("div");
	element.innerHTML = staticHTML;

	return element;
}

function getTrackHistoryLegendElement(): HTMLDivElement {
	const staticHTML = renderToStaticMarkup(<TrackHistoryLegend />);
	const element = document.createElement("div");
	element.innerHTML = staticHTML;

	return element;
}

export function get() {
	return {
		legendContent: getLegendContent,
		contentString: getContentString,
		trackingExtra: getTrackingExtra,
		trackingName: getTrackingName,
		trackingImage: getTrackingImage,
		trackingOptions: getTrackingOptions,
		defaultOptions: getDefaultOptions,
		trackHistoryInfoCardElement: getTrackHistoryInfoCardElement,
		trackHistoryLegendElement: getTrackHistoryLegendElement,
	};
}
