import { useEffect, useRef, useState } from "react";

import Card from "react-bootstrap/Card";
import { FaFilter } from "react-icons/fa6";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";
import { useCanAccess } from "@/hooks/useCanAccess";
import { VehicleStatus } from "@/enums/VehicleStatus";
import { Role } from "@/enums/Role";

import { GoogleMaps } from "@/components/Tracking/Maps/GoogleMaps";
import { OpenStreetMap } from "@/components/Tracking/Maps/OpenStreetMap";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { VehicleStatusesFilterModal } from "@/components/Modals/VehicleStatusesFilterModal";
import { get } from "@/utils/tracking/accessors";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const DEFAULT_OPTIONS = get().defaultOptions();

export function Tracking() {
	const { api } = useApi();
	const { currentAuth } = useAuth();
	const { can, roleIs } = useCanAccess();
	const { Toast } = useSwal();

	const interval = useRef<NodeJS.Timeout | null>(null);
	const [trackingData, setTrackingData] = useState<VehicleTrackingData[]>([]);
	const [search, setSearch] = useState("");
	const [bases, setBases] = useState<BaseOrUnit[]>([]);
	const [vehicleStatusesToFilter, setVehicleStatusesToFilter] = useState<number[]>([]);
	const [showVehicleStatusesFilterModal, setShowVehicleStatusesFilterModal] = useState(false);
	const [latitude, setLatitude] = useState<number>(0);
	const [longitude, setLongitude] = useState<number>(0);
	const [zoom, setZoom] = useState<number>(0);
	const [mapKey, setMapKey] = useState<string>();

	const debouncedSearch = useDebounce(search, 700);

	const handleShowVehicleStatusesFilterModal = () => setShowVehicleStatusesFilterModal(true);
	const handleCloseVehicleStatusesFilterModal = () => setShowVehicleStatusesFilterModal(false);

	const DEFAULT_REMAINING_SECONDS = 30;

	const defaultOptions = {
		latitude: latitude || DEFAULT_OPTIONS.latitude,
		longitude: longitude || DEFAULT_OPTIONS.longitude,
		zoom: zoom || DEFAULT_OPTIONS.zoom,
	};

	const vehicleStatusesToFilterLength = vehicleStatusesToFilter.length;

	const { manager, radioOperator } = Role;
	const needsToFilterVehicles = roleIs([manager, radioOperator]) || vehicleStatusesToFilterLength;

	const managerAndRadioOperationStatuses = [
		VehicleStatus.Active,
		VehicleStatus.Solicited,
		VehicleStatus.Committed,
		VehicleStatus.Unavailable,
	];

	function getStatuses(): MapVehicleStatuses {
		if (needsToFilterVehicles) {
			if (vehicleStatusesToFilterLength) {
				return vehicleStatusesToFilter;
			}

			return managerAndRadioOperationStatuses;
		}

		return "all";
	}

	const showNoCommunicationIndicator = false;
	const statuses = getStatuses();

	const defaultProps = {
		defaultOptions,
		trackingData,
		requestTrackingData,
		showNoCommunicationIndicator,
		statuses,
		bases,
		fetchAllBases,
	};

	const isFirstRender = useRef(true);

	async function requestTrackingData() {
		try {
			const url = "/vehicles-tracking/all-vehicles-current-location";

			const { data } = await api.get<VehicleTrackingData[]>(url, {
				params: {
					...(needsToFilterVehicles && {
						vehicle_statuses_to_search: vehicleStatusesToFilterLength
							? vehicleStatusesToFilter
							: managerAndRadioOperationStatuses,
					}),
					search: debouncedSearch,
				},
			});

			setTrackingData(data);

			const trackingDataLength = data.length;

			if (trackingDataLength === 1) {
				const firstVehicle = data[0];
				const vehicleLatitude = firstVehicle.latitude;
				const vehicleLongitude = firstVehicle.longitude;

				setLatitude(vehicleLatitude);
				setLongitude(vehicleLongitude);
				setZoom(12);
			} else if (!trackingDataLength) {
				Toast.fire({
					title: "Nenhum veículo foi encontrado!",
					icon: "error",
					timer: 3000,
				});
			} else {
				setLatitude(defaultOptions.latitude);
				setLongitude(defaultOptions.longitude);
				setZoom(defaultOptions.zoom);
			}

			fetchAllBases();
		} catch (error) {
			console.error(error);
		}

		if (isFirstRender.current) {
			isFirstRender.current = false;
		}
	}

	async function fetchAllBases() {
		try {
			const { data } = await api.get("/bases/tracking-bases");

			setBases(data.results);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		if (interval.current) {
			clearInterval(interval.current);
		}

		interval.current = setInterval(() => {
			setMapKey(new Date().getTime().toString());
		}, DEFAULT_REMAINING_SECONDS * 1000);

		return () => {
			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, [currentAuth, debouncedSearch, vehicleStatusesToFilter]);

	useEffect(() => {
		if (!isFirstRender.current) {
			setMapKey(new Date().getTime().toString());
		}
	}, [debouncedSearch, vehicleStatusesToFilter]);

	return (
		<Card className="bg-transparent">
			<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
				<SearchFormControl
					placeholder="Pesquisar veículo"
					onChange={(event) => setSearch(event.target.value)}
				/>

				<button
					className="d-flex align-items-center gap-4 btn button-bg-white-color-samu"
					onClick={handleShowVehicleStatusesFilterModal}
				>
					<FaFilter /> Filtrar
				</button>
			</div>

			<Card.Body className="p-0" style={{ height: "75vh" }}>
				{can([manager]) && GOOGLE_MAPS_API_KEY ? (
					<GoogleMaps key={mapKey} {...defaultProps} />
				) : (
					<OpenStreetMap key={mapKey} {...defaultProps} />
				)}
			</Card.Body>

			<VehicleStatusesFilterModal
				showVehicleStatusesFilterModal={showVehicleStatusesFilterModal}
				handleCloseVehicleStatusesFilterModal={handleCloseVehicleStatusesFilterModal}
				setVehicleStatusesToFilter={setVehicleStatusesToFilter}
				vehicleStatusesToFilter={vehicleStatusesToFilter}
			/>
		</Card>
	);
}
