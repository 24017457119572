import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "clsx";

import InfiniteScroll from "react-infinite-scroll-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Skeleton from "react-loading-skeleton";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";

import { Role } from "@/enums/Role";
import { useApi } from "@/hooks/useApi";
import { useDebounce } from "@/hooks/useDebounce";
import { useCanAccess } from "@/hooks/useCanAccess";
import { VehicleStatus, VehicleStatusMessages } from "@/enums/VehicleStatus";
import { vehicleStatusColors, vehicleStatusHovers } from "@/utils/colors/vehicleStatus";

import { ChangeVehicleStatusAndBaseModal } from "@/components/Modals/ChangeVehicleStatusAndBaseModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { Bullet } from "@/components/Bullet";

import "./styles.scss";

type Props = {
	showVehicleStatusModal: boolean;
	handleCloseVehicleStatusModal: () => void;
	handleShowVehicleStatusModal: () => void;
};

export function VehicleStatusModal({
	showVehicleStatusModal,
	handleCloseVehicleStatusModal,
	handleShowVehicleStatusModal,
}: Props) {
	const [paginatedCities, setPaginatedCities] = useState<PaginatedCity>({} as PaginatedCity);
	const [currentVehicle, setCurrentVehicle] = useState<Vehicle>({} as Vehicle);
	const [showChangeVehicleStatusAndBaseModal, setShowChangeVehicleStatusAndBaseModal] =
		useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const debouncedSearch = useDebounce(search, 500);
	const { can } = useCanAccess();
	const { api } = useApi();

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);
	const location = useLocation();

	const { results } = paginatedCities;

	const { admin, radioOperator } = Role;

	const Loader = () => {
		return (
			<>
				{Array.from({ length: 10 }, (_, index) => (
					<Fragment key={`vehicle-status-modal-loader-${index}`}>
						<Skeleton height={40} />
					</Fragment>
				))}
			</>
		);
	};

	async function fetchCities(isSearch?: boolean, pageToUse?: number) {
		setIsLoading(true);

		try {
			const { data } = await api.get("/city-vehicles", {
				params: { page: pageToUse || (isSearch ? 1 : page), search },
			});

			if (isSearch) {
				forcePage.current = 1;
				setPaginatedCities(data);
			} else {
				const combinedResults = [...(results || []), ...data.results];

				setPaginatedCities({
					...data,
					results: combinedResults,
				});
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleNextPage() {
		if (forcePage.current !== page) {
			forcePage.current += 1;
			fetchCities(false, forcePage.current);
			return;
		}

		setPage(page + 1);
	}

	const handleShowChangeVehicleStatusAndBaseModal = (vehicle: Vehicle) => {
		if (can([radioOperator, admin])) {
			setCurrentVehicle(vehicle);
			handleCloseVehicleStatusModal();
			setShowChangeVehicleStatusAndBaseModal(true);
		}
	};

	const handleCloseChangeVehicleStatusAndBaseModal = () => {
		setShowChangeVehicleStatusAndBaseModal(false);
	};

	useEffect(() => {
		if (page > 1) {
			forcePage.current = page;
			fetchCities();
		}
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		setPaginatedCities({} as PaginatedCity);

		if (showVehicleStatusModal) {
			fetchCities(true);
		}
	}, [debouncedSearch, showVehicleStatusModal]);

	return (
		<>
			<Modal
				size="xl"
				show={showVehicleStatusModal}
				onHide={handleCloseVehicleStatusModal}
				onExit={() => {
					setSearch("");
				}}
			>
				<Modal.Header closeButton>
					<span className="btn-close invisible" />
					<h2 className="m-0">Status das viaturas</h2>
				</Modal.Header>

				<Modal.Body
					id="vehicle-status-modal-body"
					className="overflow-y-scroll h-80vh pt-0"
				>
					<div
						className="modal-dialog position-fixed mt-0 ps-5 pe-11 bg-white"
						id="vehicle-status-modal-body-top"
					>
						<div className="d-flex justify-content-between align-items-center py-4">
							<div>
								<h3 className="m-0">Cidades</h3>
								Status das viaturas por cidade
							</div>

							<SearchFormControl
								placeholder="Pesquisar viatura"
								onChange={(event) => setSearch(event.target.value)}
							/>
						</div>

						<div className="d-flex justify-content-center flex-wrap gap-4 mb-4">
							<div className="d-flex align-items-center gap-2 fw-bold">
								<Bullet attribute={2} /> Ativa
							</div>

							<div className="d-flex align-items-center gap-2 fw-bold">
								<Bullet attribute={5} /> Empenhada
							</div>

							<div className="d-flex align-items-center gap-2 fw-bold">
								<Bullet attribute={1} /> Indisponível
							</div>

							<div className="d-flex align-items-center gap-2 fw-bold">
								<Bullet attribute={3} /> Em manutenção
							</div>

							<div className="d-flex align-items-center gap-2 fw-bold">
								<Bullet attribute={4} /> Solicitada
							</div>

							<div className="d-flex align-items-center gap-2 fw-bold">
								<Bullet attribute={6} /> Baixada
							</div>
						</div>
					</div>

					<InfiniteScroll
						className={classNames(
							"mt-8rem d-flex flex-column",
							isLoading ? "gap-6" : "gap-4"
						)}
						dataLength={results?.length || 0}
						next={handleNextPage}
						hasMore={
							paginatedCities.meta?.current_page < paginatedCities.meta?.last_page ||
							false
						}
						scrollableTarget="vehicle-status-modal-body"
						loader={<Loader />}
					>
						{results?.map((city, index) => (
							<div
								className="rounded city-with-border py-2 px-6"
								key={`city-${index}`}
							>
								<div className="fw-bold">{city?.name}</div>

								<div className="d-flex flex-wrap gap-4">
									{city?.vehicles &&
										city.vehicles.map((vehicle, index) => {
											const vehicleStatusId =
												vehicle.latest_vehicle_status_history
													?.vehicle_status_id;

											const vehicleStatusMessage =
												VehicleStatusMessages[
													vehicleStatusId as keyof typeof VehicleStatusMessages
												];

											const vehicleStatusDescription =
												vehicle.latest_vehicle_status_history?.description;

											const vehicleStatusMessageFormatted =
												vehicleStatusDescription
													? `${vehicleStatusMessage} - ${vehicleStatusDescription}`
													: vehicleStatusMessage;

											const vehicleTypeMessage = vehicle.vehicle_type?.name;

											const vehicleStatusColor =
												vehicleStatusColors[vehicleStatusId - 1];

											const vehicleStatusHover =
												vehicleStatusHovers[vehicleStatusId - 1];

											const vehicleOccurrenceNumber =
												vehicle.latest_vehicle_status_history.attendance
													?.number;

											const attendanceId =
												vehicle.latest_vehicle_status_history
													?.attendance_id;

											const isCommittedOrSolicited = [
												VehicleStatus.Committed,
												VehicleStatus.Solicited,
											].includes(vehicleStatusId);

											return (
												<div
													className={classNames(
														"d-flex align-items-center gap-2 hover-formatted",
														can([radioOperator, admin]) &&
															"cursor-pointer",
														vehicleStatusHover
													)}
													key={`vehicle-${index}`}
													onClick={() =>
														handleShowChangeVehicleStatusAndBaseModal(
															vehicle
														)
													}
												>
													<OverlayTrigger
														delay={{ show: 250, hide: 650 }}
														overlay={
															<Tooltip>
																<span
																	className={classNames(
																		"fw-bold",
																		isCommittedOrSolicited &&
																			"text-decoration-underline",
																		vehicleStatusColor
																	)}
																>
																	{isCommittedOrSolicited ? (
																		<Link
																			target="_blank"
																			to={`/ocorrencias/resumo/${attendanceId}`}
																			state={{
																				pathname: "/resumo",
																				from: location,
																			}}
																			className={
																				vehicleStatusColor
																			}
																		>
																			{vehicleStatusMessage} -
																			N{" "}
																			{
																				vehicleOccurrenceNumber
																			}
																		</Link>
																	) : (
																		vehicleStatusMessageFormatted
																	)}
																</span>
															</Tooltip>
														}
														placement="bottom-start"
													>
														<div className="d-flex gap-2 align-items-center">
															<Bullet
																attribute={vehicleStatusId}
																isVehicleStatus
															/>
															{`${vehicleTypeMessage} ${vehicle.code}`}
														</div>
													</OverlayTrigger>
												</div>
											);
										})}
								</div>
							</div>
						))}

						{isLoading && !results?.length && <Loader />}

						{!isLoading && !results?.length && (
							<h2 className="text-center mt-12 mb-0">Viatura não encontrada</h2>
						)}
					</InfiniteScroll>
				</Modal.Body>
			</Modal>

			<ChangeVehicleStatusAndBaseModal
				showChangeVehicleStatusAndBaseModal={showChangeVehicleStatusAndBaseModal}
				handleCloseChangeVehicleStatusAndBaseModal={
					handleCloseChangeVehicleStatusAndBaseModal
				}
				handleShowVehicleStatusModal={handleShowVehicleStatusModal}
				vehicle={currentVehicle}
			/>
		</>
	);
}
