import { ReactNode } from "react";
import classNames from "clsx";

type Props = {
	children: ReactNode;
	timeLineBadgeIconClassNames?: string;
	timeLineContainerClassNames?: string;
};

const TimelineRoot = ({ children, timeLineContainerClassNames }: Props) => (
	<div className={classNames("timeline-label", timeLineContainerClassNames)}>{children}</div>
);

const TimelineItem = ({ children }: Props) => <div className="timeline-item">{children}</div>;

const VerticalLine = () => <div className="timeline-label" />;

const TimelineContent = ({ children, timeLineBadgeIconClassNames }: Props) => (
	<>
		<div className="timeline-badge">
			<i
				className={classNames(
					"fa fa-genderless fs-1 color-dark-samu",
					timeLineBadgeIconClassNames
				)}
			></i>
		</div>

		<div className="ps-3 w-100">{children}</div>
	</>
);

export const Timeline = TimelineRoot as typeof TimelineRoot & {
	Item: typeof TimelineItem;
	VerticalLine: typeof VerticalLine;
	Content: typeof TimelineContent;
};

Timeline.Item = TimelineItem;
Timeline.VerticalLine = VerticalLine;
Timeline.Content = TimelineContent;
