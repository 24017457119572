import { useEffect } from "react";

import { AxiosResponse } from "axios";
import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { deviceValidationSchema } from "@/utils/validation/deviceValidationSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { VehicleSelect } from "@/components/Selects/VehicleSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type RequestMethod = "post" | "put";
type RequestInfo = { method: RequestMethod; url: string };
type MobileDeviceResponse = AxiosResponse & {
	results: MobileDevice;
};

type Props = {
	showDeviceModal: boolean;
	modalTitle?: string;
	handleCloseDeviceModal: () => void;
	device?: MobileDevice;
	beforeSubmit: (device: MobileDevice, action: RequestMethod) => Promise<void>;
};

export function DeviceModal({
	showDeviceModal,
	handleCloseDeviceModal,
	modalTitle,
	device,
	beforeSubmit,
}: Props) {
	const { api } = useApi();
	const { Toast, toastRequestErrors } = useSwal();

	const deviceNameLength = device && Object.entries(device).length;

	const formik = useFormik({
		initialValues: {
			name: "",
			vehicle_id: "",
			vehicle_label: "",
		},
		validationSchema: deviceValidationSchema,
		async onSubmit(values, { setSubmitting, resetForm }) {
			function getRequestInfo(): RequestInfo {
				if (deviceNameLength) {
					return {
						method: "put",
						url: `/mobile-device/${device.id}`,
					};
				}

				return {
					method: "post",
					url: "/mobile-device",
				};
			}

			const { method, url } = getRequestInfo();

			try {
				const { data } = await api[method]<MobileDeviceResponse>(url, {
					...(deviceNameLength && { pin: device.pin.code }),
					...values,
				});

				handleCloseDeviceModal();
				await beforeSubmit(data.results, method);

				resetForm();

				Toast.fire({
					icon: "success",
					title: `Dispositivo ${
						deviceNameLength ? "atualizado" : "cadastrado"
					} com sucesso!`,
				});
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	const { values, errors } = formik;

	const selectedVehicle = values.vehicle_id
		? {
				id: values.vehicle_id,
				label: values.vehicle_label,
		  }
		: {
				label: "Sem viatura",
				value: "",
		  };

	const handleChangeVehicleSelect = (option: unknown) => {
		const { label, value } = option as ReactSelectOption;
		formik.setFieldValue("vehicle_id", value);
		formik.setFieldValue("vehicle_label", label);
	};

	useEffect(() => {
		if (deviceNameLength) {
			formik.setValues({
				name: device.name,
				vehicle_id: device?.vehicle_id || "",
				vehicle_label: device?.vehicle?.description || "",
			});
		}
	}, [device]);

	useEffect(() => {
		formik.validateForm();
	}, [values.vehicle_id]);

	return (
		<Modal size="lg" show={showDeviceModal} onHide={handleCloseDeviceModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{modalTitle || "Dispositivo"}</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<FormattedFormControl
						id="name"
						label="Nome do dispositivo"
						labelClassName="fw-bold"
						validationMessage={errors.name}
						isInvalid={!!errors.name}
						{...formik.getFieldProps("name")}
						isRequired
					/>

					<Form.Label className="fw-bold mt-3">
						VTR Vinculada <RedAsterisk />
					</Form.Label>

					<VehicleSelect
						id="vehicle_id"
						fetchRoute="/mobile-device/able-vehicles"
						value={selectedVehicle}
						isInvalid={!!errors.vehicle_id}
						onChange={handleChangeVehicleSelect}
					/>

					{Boolean(errors.vehicle_id) && (
						<span className="text-danger">{errors.vehicle_id}</span>
					)}

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseDeviceModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							Salvar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
