import { Route, Routes } from "react-router-dom";

import { Monitoring } from "@/pages/OccurrenceMonitoring/Monitoring";
import { RateOccurence } from "@/pages/RateOccurence";

export function OccurrenceMonitoringRoutes() {
	return (
		<Routes>
			<Route path="/acompanhamento/:attendanceId" element={<Monitoring />} />
			<Route
				path="/acompanhamento/avaliar-atendimento/:attendanceId"
				element={<RateOccurence />}
			/>
		</Routes>
	);
}
