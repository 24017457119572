import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import { MdSpeed } from "react-icons/md";

import { Separator } from "@/components/Separator";

import "./styles.scss";
export type TrackHistoryInfoCardProps = {
	data: {
		vehicle: Vehicle;
		startAddress: ReverseGeocodeResponseWithDatetime;
		endAddress: ReverseGeocodeResponseWithDatetime;
		averageSpeed: string;
	};
};

export function TrackHistoryInfoCard({ data }: TrackHistoryInfoCardProps) {
	const { vehicle, startAddress, endAddress, averageSpeed } = data;

	return (
		<Card className="m-4">
			<Card.Header className="d-flex justify-content-between align-items-center bg-samu px-3 py-4 track-history-card-header">
				<h3 className="fw-normal text-white m-0">
					{vehicle.vehicle_type?.name} {vehicle.code} ({vehicle.license_plate}) -{" "}
					{vehicle.base?.city?.name}
				</h3>
			</Card.Header>
			<Card.Body className="p-3">
				<h6>Trajeto</h6>
				<ul className="mb-0 d-flex flex-column gap-4">
					<li className="">
						<p className="mb-1 fs-7 address-text">
							<span className="fw-bolder"> Ponto Inicial: </span>{" "}
							{startAddress.address}
						</p>
						<p className="mb-0 fs-8">
							<span className="fw-bolder">Data e Hora: </span>
							{dayjs(startAddress.datetime).format("DD/MM/YYYY [às] HH:mm:ss")}
						</p>
					</li>
					<Separator className="p-0" />
					<li>
						<p className="mb-1 fs-7 address-text">
							<span className="fw-bolder"> Ponto Final: </span>
							{endAddress.address}
						</p>
						<p className="mb-0 fs-8">
							<span className="fw-bolder">Data e Hora: </span>
							{dayjs(endAddress.datetime).format("DD/MM/YYYY [às] HH:mm:ss")}
						</p>
					</li>
				</ul>
				<Separator className="my-4" />
				<p className="d-flex align-items-center gap-1 mt-2 fs-8">
					<span className="d-flex align-items-center gap-1">
						<MdSpeed size={16} /> Velocidade Média:{" "}
					</span>
					{averageSpeed} km/h
				</p>
			</Card.Body>
		</Card>
	);
}
