import { useEffect } from "react";
import { useFormik } from "formik";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import { IoMdInformationCircle } from "react-icons/io";
import { GoAlertFill } from "react-icons/go";

import { monitoringValidationSchema } from "@/utils/validation/monitoringValidationSchema";
import { monitoringInitialValues } from "@/utils/initialValues/monitoring";
import { useSwal } from "@/hooks/useSwal";
import { useApi } from "@/hooks/useApi";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { Separator } from "@/components/Separator";

export function Monitoring() {
	const { Toast } = useSwal();
	const { api } = useApi();

	const formik = useFormik({
		initialValues: monitoringInitialValues,
		validationSchema: monitoringValidationSchema,
		async onSubmit(values) {
			try {
				await api.put("monitoring-settings", values);

				Toast.fire({
					icon: "success",
					title: "Informações salvas com sucesso!",
				});
			} catch (error: any) {
				console.log(error);

				Toast.fire({
					icon: "error",
					title: error.response?.data?.message || "Erro ao salvar informações",
				});
			}
		},
	});

	async function getMonitoringSettings() {
		try {
			const { data } = await api.get("monitoring-settings");

			formik.setValues(data);
		} catch (error: any) {
			console.log(error);
		}
	}

	const { values, errors } = formik;
	const isChecked = Boolean(values.enable_attendance_monitoring);

	useEffect(() => {
		getMonitoringSettings();
	}, []);

	useEffect(() => {
		if (isChecked) {
			formik.setFieldValue("enable_attendance_monitoring", true);
		}
	}, [isChecked]);

	return (
		<Card>
			<Card.Header className="d-flex flex-between">
				<p className="m-0 fs-2">Monitoramento</p>
			</Card.Header>

			<Card.Body>
				{!isChecked && (
					<div
						className="d-flex gap-5 alert alert-warning border border-dashed border-warning py-3 px-5"
						role="alert"
					>
						<div className="d-flex align-items-center fs-2rem color-yellow">
							<GoAlertFill />
						</div>

						<div>
							<p className="m-0 fs-5 fw-bold text-dark">
								A opção de envio de mensagem de monitoramento está desativada
							</p>

							<p className="m-0 fs-7 text-dark">
								Com essa opção desativada o solicitante não terá o envio da mensagem
								de monitoramento via whatssap enviada
							</p>
						</div>
					</div>
				)}

				<Form onSubmit={formik.handleSubmit}>
					<div className="d-flex gap-3">
						<input
							type="checkbox"
							checked={Boolean(values.enable_attendance_monitoring)}
							onChange={(event) => {
								formik.setFieldValue(
									"enable_attendance_monitoring",
									event.target.checked
								);
							}}
							id="enable-attendance-monitoring"
							name="enable_attendance_monitoring"
						/>

						<Form.Label htmlFor="enable-attendance-monitoring" className="m-0">
							Habilitar enviar mensagem de monitoramento via Whatsapp
						</Form.Label>
					</div>

					<Separator className="my-5" />

					<OverlayTrigger
						placement="right"
						overlay={
							<Tooltip>
								Tempo em horas para expirar link de acesso ao monitoramento de
								ocorrência e a pesquisa de satisfação
							</Tooltip>
						}
					>
						<div className="d-flex gap-1 w-25">
							<h3>Tempo de expiração do link</h3>

							<IoMdInformationCircle className="color-dark-gray fs-4 mb-4" />
						</div>
					</OverlayTrigger>

					<div className="w-15 mt-3 gap-4 d-flex">
						<FormattedFormControl
							type="number"
							isInvalid={!!errors.link_validation_time}
							validationMessage={errors.link_validation_time}
							{...formik.getFieldProps("link_validation_time")}
						/>

						<span className="fs-4 d-flex align-items-center w-75">Horas</span>
					</div>

					<div className="d-flex justify-content-center mt-7">
						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={!!errors.link_validation_time}
						>
							Salvar
						</button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
}
