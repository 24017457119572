import { useEffect, useState } from "react";

import Card from "react-bootstrap/Card";

import { PowerBIReports } from "@/components/Configurations/PowerBIReports";

export function PowerBIReportsView() {
	const [currentPowerBIUrl, setCurrentPowerBIUrl] = useState("");

	const handleSetCurrentPowerBIUrl = (url: string) => setCurrentPowerBIUrl(url);

	useEffect(() => {
		const iframe = document.getElementById("power-bi-report-iframe") as HTMLIFrameElement;

		if (iframe) {
			window.scrollTo({
				top: document.body.scrollHeight,
				behavior: "smooth",
			});
		}
	}, [currentPowerBIUrl]);

	return (
		<>
			<Card>
				<Card.Body id="power-bi-reports" className="overflow-y-scroll max-h-610px pt-0">
					<PowerBIReports
						onlyShow
						handleSetCurrentPowerBIUrl={handleSetCurrentPowerBIUrl}
					/>
				</Card.Body>
			</Card>

			{currentPowerBIUrl && (
				<iframe
					id="power-bi-report-iframe"
					className="mt-5 border rounded"
					src={currentPowerBIUrl}
					width="100%"
					height={500}
					title="Exibição de relatórios Power BI"
				/>
			)}
		</>
	);
}
