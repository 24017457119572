import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { FaPlus, FaTrash, FaEye, FaEyeSlash } from "react-icons/fa";
import { IoIosOpen } from "react-icons/io";
import { MdEdit } from "react-icons/md";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { RoleMessages } from "@/enums/Role";
import { useDebounce } from "@/hooks/useDebounce";
import { createSentence } from "@/utils/createSentence";

import { PowerBIReportModal } from "@/components/Configurations/PowerBIReports/PowerBIReportModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PaginationLinks } from "@/components/PaginationLinks";

type Props = {
	activeTab?: string;
	onlyShow?: boolean;
	handleSetCurrentPowerBIUrl?: (url: string) => void;
};

export function PowerBIReports({ activeTab, onlyShow = false, handleSetCurrentPowerBIUrl }: Props) {
	const [powerBIReports, setPowerBIReports] = useState<PaginatedPowerBIReport>(
		{} as PaginatedPowerBIReport
	);
	const [currentPowerBIReport, setCurrentPowerBIReport] = useState<PowerBIReport>(
		{} as PowerBIReport
	);
	const [showPowerBIReportsModal, setShowPowerBIReportsModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);
	const [currentPowerBIBeingViewed, setCurrentPowerBIBeingViewed] = useState("");

	const debouncedSearch = useDebounce(search, 500);
	const isFirstRender = useRef(true);
	const { Swal, Toast } = useSwal();
	const forcePage = useRef(1);
	const { api } = useApi();

	const { results } = powerBIReports;

	async function fetchPaginatedPowerBIReports(pageToUse?: number) {
		setLoading(true);

		try {
			const { data } = await api.get("/power-bi-report", {
				params: {
					page: pageToUse || page,
					per_page: 5,
					search: debouncedSearch,
				},
			});

			setPowerBIReports(data);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchPaginatedPowerBIReports(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	function handleShowPowerBIReportModal(result?: PowerBIReport) {
		setShowPowerBIReportsModal(true);

		if (result) {
			setCurrentPowerBIReport(result);

			return;
		}

		setCurrentPowerBIReport({} as PowerBIReport);
	}

	function handleClosePowerBIReportsModal() {
		setShowPowerBIReportsModal(false);
		setCurrentPowerBIReport({} as PowerBIReport);
	}

	async function fetchPowerBIReports(pageToUse?: number) {
		setLoading(true);

		try {
			const { data } = await api.get("/power-bi-report", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
				},
			});

			setPowerBIReports(data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}

	async function handleRemovePowerBIReport(powerBIReportId: string) {
		const { isConfirmed } = await Swal.fire({
			title: "Tem certeza que deseja deletar o relatório?",
			icon: "warning",
			showCancelButton: true,
		});

		if (isConfirmed) {
			try {
				await api.delete(`/power-bi-report/${powerBIReportId}`);

				if (powerBIReports.results.length === 1 && powerBIReports.meta.current_page > 1) {
					setPage(page - 1);
				}

				fetchPowerBIReports();

				Toast.fire({
					icon: "success",
					title: `Relatório deletado com sucesso!`,
				});
			} catch (error) {
				console.log(error);
			}
		}
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPaginatedPowerBIReports();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (activeTab === "power-bi-reports" || onlyShow) {
			fetchPaginatedPowerBIReports();
		}
	}, [debouncedSearch, activeTab, onlyShow]);

	return (
		<>
			{onlyShow ? (
				<Card.Header className="d-flex align-items-center justify-content-between p-0">
					<h3 className="fw-normal m-0">Relatórios do Power BI</h3>

					<SearchFormControl
						placeholder="Pesquisar relatório"
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Card.Header>
			) : (
				<div className="d-flex align-items-center justify-content-between my-6">
					<button
						className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
						onClick={() => handleShowPowerBIReportModal()}
					>
						<FaPlus /> Cadastrar
					</button>

					<SearchFormControl
						placeholder="Pesquisar relatório"
						onChange={(event) => setSearch(event.target.value)}
					/>
				</div>
			)}

			<Table className="table-row-dashed table-row-gray-300">
				<thead>
					<tr className="fw-bold fs-6">
						<th>Nome</th>
						{!onlyShow && <th>Perfil com acesso</th>}
						<th>Descrição</th>
						<th>Ações</th>
					</tr>
				</thead>
				<tbody>
					{loading && <TableBodySkeleton columns={onlyShow ? 3 : 4} />}

					{results?.map((result, index) => {
						const roleNames = createSentence(
							result?.roles.map(
								(role) => RoleMessages[role.name as keyof typeof RoleMessages]
							)
						);

						const isCurrentPowerBIBeingViewed = currentPowerBIBeingViewed === result.id;

						return (
							<tr key={`power-bi-reports-${index}`}>
								<td className="align-middle">{result?.name}</td>

								{!onlyShow && <td className="align-middle">{roleNames}</td>}

								<td className="align-middle">
									<OverlayTrigger
										overlay={<Tooltip>{result?.description}</Tooltip>}
									>
										<span className="text-limit">{result?.description}</span>
									</OverlayTrigger>
								</td>

								<td className="align-middle w-1px">
									<div className="d-flex gap-2">
										{handleSetCurrentPowerBIUrl && (
											<OverlayTrigger
												overlay={
													<Tooltip>
														{isCurrentPowerBIBeingViewed
															? "Fechar"
															: "Visualizar"}
													</Tooltip>
												}
											>
												<Button
													className="btn btn-icon btn-sm bg-secondary"
													onClick={() => {
														handleSetCurrentPowerBIUrl(
															isCurrentPowerBIBeingViewed
																? ""
																: result.url
														);
														setCurrentPowerBIBeingViewed(
															isCurrentPowerBIBeingViewed
																? ""
																: result.id
														);
													}}
												>
													{isCurrentPowerBIBeingViewed ? (
														<FaEyeSlash className="text-gray-700 fs-4" />
													) : (
														<FaEye className="text-gray-700 fs-4" />
													)}
												</Button>
											</OverlayTrigger>
										)}

										<OverlayTrigger overlay={<Tooltip>Abrir</Tooltip>}>
											<Link
												className="btn btn-icon btn-sm bg-secondary"
												to={result.url}
												target="_blank"
											>
												<IoIosOpen className="text-gray-700 fs-4" />
											</Link>
										</OverlayTrigger>

										{!onlyShow && (
											<>
												<OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
													<Button
														variant="secondary"
														className="btn-icon btn-sm"
														onClick={() => {
															handleShowPowerBIReportModal &&
																handleShowPowerBIReportModal(
																	result
																);
														}}
													>
														<MdEdit className="text-gray-700" />
													</Button>
												</OverlayTrigger>

												<OverlayTrigger
													overlay={<Tooltip>Excluir</Tooltip>}
												>
													<Button
														variant="secondary"
														className="btn-icon btn-sm"
														onClick={() => {
															handleRemovePowerBIReport &&
																handleRemovePowerBIReport(
																	result.id
																);
														}}
													>
														{loading ? (
															<Spinner
																variant="samu"
																animation="border"
																size="sm"
															/>
														) : (
															<FaTrash className="text-gray-700" />
														)}
													</Button>
												</OverlayTrigger>
											</>
										)}
									</div>
								</td>
							</tr>
						);
					})}

					{!results?.length && (
						<tr>
							<td className="text-center" colSpan={12}>
								<h2 className="mt-12 mb-0">Nenhum relatório encontrado</h2>
							</td>
						</tr>
					)}
				</tbody>
			</Table>

			{Boolean(results?.length) && (
				<div className="d-flex justify-content-end mt-8">
					<PaginationLinks
						itemsPerPage={5}
						totalItems={powerBIReports.meta?.total}
						forcePage={forcePage.current - 1}
						changeSelectedPage={handleChangeSelectedPage}
					/>
				</div>
			)}

			<PowerBIReportModal
				showPowerBIReportsModal={showPowerBIReportsModal}
				handleClosePowerBIReportsModal={handleClosePowerBIReportsModal}
				fetchPaginatedPowerBIReports={fetchPaginatedPowerBIReports}
				currentPowerBIReport={currentPowerBIReport}
			/>
		</>
	);
}
