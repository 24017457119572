import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";

import { ImArrowLeft2 } from "react-icons/im";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { AttendanceType } from "@/enums/AttendanceType";
import { AttendanceStatus } from "@/enums/AttendanceStatus";
import { NatureType, NatureTypeMessages } from "@/enums/NatureType";

import { filterObjectExcluding } from "@/utils/filterObject";
import { sceneRegisterSchema } from "@/utils/validation/sceneRegisterSchema";
import { sceneRegisterInitialValues } from "@/utils/initialValues/sceneRegister";

import { HistoryRegisterModal } from "@/components/Modals/HistoryRegisterModal";
import { OccurrenceDataModal } from "@/components/Modals/OccurrenceDataModal";
import { OccurrenceData } from "@/components/OccurrenceData";
import { LateralToolbar } from "@/components/LateralToolbar";
import { SceneRegisterForm } from "./SceneRegisterForm";

export function SceneRegister() {
	const [showHistoryRegisterModal, setShowHistoryRegisterModal] = useState(false);
	const [counterReferrals, setCounterReferrals] = useState<CounterReferral[]>([]);
	const [showOccurrenceDataModal, setShowOccurrenceDataModal] = useState(false);
	const [alreadyHasSceneRegister, setAlreadyHasSceneRegister] = useState(false);
	const [occurrence, setOccurrence] = useState<Attendable>({} as Attendable);
	const [isStartingAttendance, setIsStartingAttendance] = useState(false);
	const [attendanceType, setAttendanceType] = useState<number>();
	const [sceneRegisterId, setSceneRegisterId] = useState("");
	const [inAttendance, setInAttendance] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { SwalToInAttendance, Toast, toastRequestErrors, Swal } = useSwal();
	const { attendanceId } = useParams();
	const navigate = useNavigate();
	const { api } = useApi();

	const handleShowHistoryRegisterModal = () => setShowHistoryRegisterModal(true);
	const handleCloseHistoryRegisterModal = () => setShowHistoryRegisterModal(false);

	const handleShowOccurrenceDataModal = () => setShowOccurrenceDataModal(true);
	const handleCloseOccurrenceDataModal = () => setShowOccurrenceDataModal(false);

	const attendance = occurrence?.attendance;

	const formik = useFormik({
		initialValues: sceneRegisterInitialValues,
		validationSchema: sceneRegisterSchema,
		async onSubmit(data) {
			try {
				const procedures = data.procedures.map((option) => ({
					procedure_code: (option as ReactSelectOption).value,
				}));

				const medicines = data.medicines.map((option) => ({
					medicine_id: (option as ReactSelectOption).value,
				}));

				const antecedentsTypes = data.antecedents.map((option) => ({
					antecedent_type_id: (option as ReactSelectOption).value,
				}));

				const filteredValues = filterObjectExcluding(data, [
					"procedures",
					"medicines",
					"antecedents",
				]);

				const formattedData = {
					...filteredValues,
					attendance_id: attendance.id,
					procedures,
					medicines,
					antecedentsTypes,
				};

				await api.post(`/scene-recording`, formattedData);

				Toast.fire({
					icon: "success",
					title: "Chamado registrado com sucesso",
				});

				navigate("/ocorrencias");
			} catch (error: any) {
				console.log(error);
				toastRequestErrors(error.response?.data.errors);
			}
		},
	});

	const { values } = formik;

	async function handleStartAttendance() {
		try {
			formik.setFieldValue("metrics[0].start_at", dayjs().format("YYYY-MM-DD[T]HH:mm"));
			setIsStartingAttendance(true);

			await api.post(`scene-recording/start-attendance/${attendanceId}`);

			setInAttendance(true);
		} catch (error: any) {
			const message = error.response.data.message;

			SwalToInAttendance(message);
		} finally {
			setIsStartingAttendance(false);
		}
	}

	const hasFetchedSceneRegister = useRef(false);

	async function handleGetSceneRegister(ticketTypeId: number, selectedSceneRegisterId: string) {
		try {
			if (!selectedSceneRegisterId) {
				formik.setValues(sceneRegisterInitialValues);

				setAttendanceType(ticketTypeId);
				formik.setFieldValue("attendanceType", ticketTypeId);

				if (attendanceType === ticketTypeId) {
					await handleSearchAttendance();
				}

				throw new Error("Scene register id is not defined");
			}

			const { data } = await api.get(`/scene-recording/${selectedSceneRegisterId}`);

			const formattedData = Object.fromEntries(
				Object.entries(data).map(([key, value]) => {
					if (value === null) {
						return [key, ""];
					}

					if (key === "death_at") {
						return [
							key,
							value ? dayjs(value as string).format("YYYY-MM-DD[T]HH:MM") : "",
						];
					}

					return [key, value];
				})
			);

			const diagnosticHypotheses =
				data.diagnostic_hypotheses as DiagnosticHypothesisWithPivot[];

			const trasnformedDiagnosticHypothesis = diagnosticHypotheses?.map(
				(diagnosticHypothesis) => {
					const natureId = diagnosticHypothesis.pivot.nature_type_id;
					const recommended = diagnosticHypothesis.pivot.recommended;
					const applied = diagnosticHypothesis.pivot.applied;

					return {
						nature_type_id: natureId,
						nature_type_label: NatureTypeMessages[natureId as NatureType],
						diagnostic_hypothesis_id: diagnosticHypothesis.id,
						diagnostic_hypothesis_label: diagnosticHypothesis.name,
						recommended,
						applied,
					};
				}
			);

			formik.setValues({ ...values, ...formattedData });
			formik.setFieldValue("diagnostic_hypotheses", trasnformedDiagnosticHypothesis);

			const transformedProcedures = (data.procedures as SceneRecordingProcedure[])?.map(
				(procedure) => ({
					value: procedure.procedure_code,
					label: `${procedure.procedure_code} - ${procedure.procedure_label}`,
				})
			);

			const transformedMedicines = (data.medicines as SceneRecordingMedicine[])?.map(
				(medicine) => ({
					value: medicine.medicine_id,
					label: medicine.medicine_label,
				})
			);

			const transformedAntecedents = (
				data.antecedents_types as SceneRegisterAntecedent[]
			)?.map((antecedent) => ({
				value: antecedent.antecedent_type.id,
				label: antecedent.antecedent_type.name,
			}));

			formik.setFieldValue("procedures", transformedProcedures);
			formik.setFieldValue("medicines", transformedMedicines);
			formik.setFieldValue("antecedents", transformedAntecedents);

			setInAttendance(true);
			setAlreadyHasSceneRegister(true);

			setSceneRegisterId(data.id);
			setCounterReferrals(data.counter_referrals);
		} catch (error: any) {
			console.log(error);

			if (isStartingAttendance) {
				setInAttendance(true);
			} else {
				setInAttendance(false);
			}

			setAlreadyHasSceneRegister(false);
		} finally {
			setAttendanceType(ticketTypeId);
			formik.setFieldValue("attendanceType", ticketTypeId);

			setIsLoadingSceneRegisterForm(false);
			hasFetchedSceneRegister.current = true;
			setIsLoading(false);
		}
	}

	async function handleCheckAttendance(selectedSceneRegisterId: string) {
		try {
			const { data } = await api.get(`/attendance/check/scene-recording/${attendanceId}`);

			const selectedSceneRegisterIndex = sceneRegisterIdentifiers.results?.findIndex(
				(sceneRegister) => sceneRegister.id === selectedSceneRegisterId
			);

			const isLastSceneRegister = selectedSceneRegisterIndex === -1;

			if (!isLastSceneRegister) {
				handleGetSceneRegister(data?.ticket_type_id, selectedSceneRegisterId);

				setInAttendance(
					data?.attendance_status_id === AttendanceStatus.InAttendanceSceneRecord ?? true
				);

				return;
			}

			formik.setValues({ ...sceneRegisterInitialValues, patient: values.patient });

			setAlreadyHasSceneRegister(false);
			setAttendanceType(data?.ticket_type_id);
			formik.setFieldValue("attendanceType", data?.ticket_type_id);
			setInAttendance(true);
			setIsLoadingSceneRegisterForm(false);
		} catch (error: any) {
			console.log(error);

			handleGetSceneRegister(error.response.data.ticket_type_id, selectedSceneRegisterId);
		}
	}

	async function handleCloseAttendance() {
		if (!inAttendance) {
			return navigate("/ocorrencias");
		}

		Swal.fire({
			title: "As informações não salvas serão perdidas. Deseja continuar?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					await api.put(`attendance/${attendanceId}/close`);

					Toast.fire({
						icon: "success",
						title: "Atendimento fechado com sucesso",
					});
				} catch (error: any) {
					console.log(error);

					toastRequestErrors(error.response?.data.errors ?? {});
				} finally {
					navigate("/ocorrencias");
				}
			}
		});
	}

	async function handleSearchAttendance() {
		const url =
			attendanceType === AttendanceType.PrimaryOccurrence
				? "/ticket/primary-attendance/"
				: "/ticket/secondary-attendance/";

		try {
			const { data } = await api.get(url + attendanceId, {
				params: {
					with_latest_medical_regulation: 1,
					with_diagnostic_hypotheses_latest_medical_regulation: 1,
					with_latest_scene_recording: 1,
					with_diagnostic_hypotheses_latest_scene_recording: 1,
				},
			});

			setOccurrence(data);

			const patient = data.attendance?.patient;

			formik.setFieldValue("patient.name", patient?.name ?? "");
			formik.setFieldValue("patient.gender_code", patient?.gender_code ?? "");
			formik.setFieldValue("patient.age", patient?.age ? String(patient.age) : "");
			formik.setFieldValue("patient.time_unit_id", patient?.time_unit_id ?? "");
		} catch (error: any) {
			console.log(error);
			toastRequestErrors(error.response?.data.errors ?? {});
		} finally {
			setIsLoading(false);
		}
	}

	const [isLoadingSceneRegisterForm, setIsLoadingSceneRegisterForm] = useState(false);
	const [sceneRegisterIdentifiers, setSceneRegisterIdentifiers] =
		useState<PaginatedSceneRegisterIdentifiers>({} as PaginatedSceneRegisterIdentifiers);

	async function handleGetSceneRegisterIdentifiers() {
		try {
			const { data } = await api.get(`/scene-recording/identifiers/${attendanceId}`);
			const [firstSceneRegisterIdentifier] = data.results ?? [];
			const firstSceneRegisterId = firstSceneRegisterIdentifier?.id ?? "";

			setSceneRegisterIdentifiers(data);
			setSceneRegisterId(firstSceneRegisterId);

			await handleCheckAttendance(firstSceneRegisterId);
		} catch (error: any) {
			console.log(error);
			toastRequestErrors(error.response?.data.errors ?? {});
		}
	}

	useEffect(() => {
		if (attendanceType) {
			handleSearchAttendance();
		}
	}, [attendanceType]);

	useEffect(() => {
		if (inAttendance) {
			formik.validateForm();
		}
	}, [inAttendance]);

	useEffect(() => {
		if (attendanceId) {
			handleGetSceneRegisterIdentifiers();
		}
	}, [attendanceId]);

	useEffect(() => {
		if (!hasFetchedSceneRegister.current) {
			return;
		}

		setIsLoadingSceneRegisterForm(true);
		handleCheckAttendance(sceneRegisterId);
	}, [sceneRegisterId]);

	const SceneRegisterFormLoader = () => (
		<div className="d-flex flex-center">
			<Spinner
				variant="samu"
				animation="border"
				style={{
					width: "3rem",
					height: "3rem",
				}}
			/>
		</div>
	);

	function handleTransformedDiagnosticHypothesis(
		diagnosticHypotheses: DiagnosticHypothesisWithPivot[]
	) {
		return diagnosticHypotheses?.map((diagnosticHypothesis) => {
			const natureId = diagnosticHypothesis.pivot.nature_type_id;
			const recommended = diagnosticHypothesis.pivot.recommended;
			const applied = diagnosticHypothesis.pivot.applied;

			return {
				nature_type_id: natureId,
				nature_type_label: NatureTypeMessages[natureId as NatureType],
				diagnostic_hypothesis_id: Number(diagnosticHypothesis.id),
				diagnostic_hypothesis_label: diagnosticHypothesis.name,
				recommended,
				applied,
			};
		});
	}

	const diagnosticHypothesesLatestMedicalRegulation = occurrence?.attendance
		?.latest_medical_regulation?.diagnostic_hypotheses as DiagnosticHypothesisWithPivot[];

	const diagnosticHypothesesLatestSceneRecording = occurrence?.attendance?.scene_recording
		?.diagnostic_hypotheses as DiagnosticHypothesisWithPivot[];

	const transformedDiagnosticHypothesisLatestMedicalRegulation =
		handleTransformedDiagnosticHypothesis(diagnosticHypothesesLatestMedicalRegulation);

	const transformedDiagnosticHypothesisLatestSceneRecording =
		handleTransformedDiagnosticHypothesis(diagnosticHypothesesLatestSceneRecording);

	const sceneRegisterFormProps = {
		formik,
		sceneRegisterId,
		alreadyHasSceneRegister,
		inAttendance,
		attendanceId,
		isStartingAttendance,
		handleStartAttendance,
		handleCloseAttendance,
		counterReferrals,
		setCounterReferrals,
		transformedDiagnosticHypothesisLatestMedicalRegulation,
		attendanceType,
		transformedDiagnosticHypothesisLatestSceneRecording,
	};

	const showNotificationHistoryRegister = Boolean(
		attendance?.observations?.length ||
			attendance?.medical_regulations?.length ||
			attendance?.radio_operation?.notes?.length
	);

	return (
		<>
			<LateralToolbar>
				<LateralToolbar.Button onClick={handleShowOccurrenceDataModal}>
					Dados da <br /> ocorrência
				</LateralToolbar.Button>

				<LateralToolbar.Button
					onClick={handleShowHistoryRegisterModal}
					showNotificationIndicator={showNotificationHistoryRegister}
				>
					Histórico de registros
				</LateralToolbar.Button>
			</LateralToolbar>

			<Card>
				{isLoading ? (
					<div className="d-flex flex-center h-80vh">
						<Spinner
							variant="samu"
							animation="border"
							style={{ width: "4rem", height: "4rem" }}
						/>
					</div>
				) : (
					<>
						<Card.Header>
							<div className="d-flex justify-content-between align-items-center w-100 default-form-card-header">
								<div>
									{dayjs(occurrence?.attendance?.ticket?.opening_at).format(
										"[Aberto em] DD/MM/YYYY [às] HH:mm[hr]"
									)}
								</div>

								<h2 className="m-0">Registro de cena</h2>

								<div>
									<button
										type="button"
										className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
										onClick={handleCloseAttendance}
									>
										<ImArrowLeft2 />
										Voltar
									</button>
								</div>
							</div>
						</Card.Header>

						<Card.Body>
							<OccurrenceData
								attendanceType={attendanceType}
								attendable={occurrence}
								summarize
							/>

							{sceneRegisterIdentifiers.results?.length ? (
								<Tabs
									unmountOnExit
									className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder mt-5"
									activeKey={sceneRegisterId}
									onSelect={(selectedKey) =>
										setSceneRegisterId(selectedKey ?? "")
									}
								>
									{sceneRegisterIdentifiers.results.map(
										(sceneRegisterIdentifier, index) => {
											const eventKey = sceneRegisterIdentifier.id;

											return (
												<Tab
													key={eventKey}
													eventKey={eventKey}
													title={`Registro de cena ${index + 1}`}
													className="mt-5"
												>
													{isLoadingSceneRegisterForm ? (
														<SceneRegisterFormLoader />
													) : (
														<SceneRegisterForm
															{...sceneRegisterFormProps}
														/>
													)}
												</Tab>
											);
										}
									)}

									<Tab
										eventKey=""
										title={`Registro de cena ${
											sceneRegisterIdentifiers.results.length + 1
										}`}
										className="mt-5"
									>
										{isLoadingSceneRegisterForm ? (
											<SceneRegisterFormLoader />
										) : (
											<SceneRegisterForm {...sceneRegisterFormProps} />
										)}
									</Tab>
								</Tabs>
							) : (
								<div className="mt-5">
									<SceneRegisterForm {...sceneRegisterFormProps} />
								</div>
							)}
						</Card.Body>
					</>
				)}
			</Card>

			<HistoryRegisterModal
				showHistoryRegisterModal={showHistoryRegisterModal}
				handleCloseHistoryRegisterModal={handleCloseHistoryRegisterModal}
				attendanceId={attendanceId as string}
			/>

			<OccurrenceDataModal
				showOccurrenceDataModal={showOccurrenceDataModal}
				handleCloseOccurrenceDataModal={handleCloseOccurrenceDataModal}
				attendanceId={attendance?.id}
			/>
		</>
	);
}
