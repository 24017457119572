import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { useSwal } from "./hooks/useSwal";
import { AuthInit, useAuth } from "./modules/auth";
import { useCanAccess } from "./hooks/useCanAccess";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";

export function App() {
	const navigate = useNavigate();
	const { logout } = useAuth();
	const { Swal } = useSwal();

	const { location } = document;

	const canCheckIdle =
		location.pathname !== "/login" &&
		!location.pathname.includes("monitoramento-de-ocorrencia");

	useCanAccess();

	const onIdle = () => {
		if (canCheckIdle) {
			Swal.fire({
				title: "Parece que você ficou inativo por um tempo. Para sua segurança, estamos redirecionando você para a tela de login.",
				icon: "warning",
				heightAuto: false,
			});

			logout();

			navigate("/login");
		}
	};

	const timeout = Number(process.env.REACT_APP_TIMEOUT) || 6 * 60 * 60 * 1000; // 6 hours

	useIdleTimer({
		onIdle,
		timeout,
		throttle: 500,
	});

	function getChatwootSecret() {
		const secret = process.env.REACT_APP_CHATWOOT_SECRET;

		return secret || "";
	}

	useEffect(() => {
		if (!location.host.includes("portal")) {
			document.documentElement.style.setProperty("--samu", "#009ef7");
		}

		window.getChatwootSecret = getChatwootSecret;
	}, []);

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<I18nProvider>
				<LayoutProvider>
					<AuthInit>
						<Outlet />
						<MasterInit />
					</AuthInit>
				</LayoutProvider>
			</I18nProvider>
		</Suspense>
	);
}
