import { useState, useRef, useEffect } from "react";

import { FaPowerOff } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";

import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { PaginationLinks } from "@/components/PaginationLinks";
import { PinModal } from "@/components/Configurations/MobileDevices/PinModal";
import { DeviceModal } from "@/components/Configurations/MobileDevices/DeviceModal";

export function MobileDevices() {
	const { api } = useApi();
	const { Toast } = useSwal();

	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 500);

	const [devices, setDevices] = useState<PaginatedMobileDevice>({} as PaginatedMobileDevice);

	const [skeleton, setSkeleton] = useState(true);
	const [showDeviceModal, setShowDeviceModal] = useState(false);
	const [showPinModal, setShowPinModal] = useState(false);
	const [currentDevice, setCurrentDevice] = useState<MobileDevice>({} as MobileDevice);
	const [usedPin, setUsedPin] = useState("");
	const [page, setPage] = useState(1);

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);

	const { results } = devices;

	async function fetchDevices(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/mobile-device", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
				},
			});

			setDevices(data);
			setSkeleton(false);
		} catch (error) {
			console.log(error);
			setSkeleton(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchDevices(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	function handleShowMobileDeviceModal() {
		setCurrentDevice({} as MobileDevice);

		setShowPinModal(false);
		setShowDeviceModal(true);
	}

	async function handleBeforeSubmitDevice(device: MobileDevice, method: "post" | "put") {
		await fetchDevices();

		if (method === "post") {
			setShowPinModal(true);
			setUsedPin(device.pin.code);
		}
	}

	function handleEditDevice(device: MobileDevice) {
		setCurrentDevice(device);
		setShowDeviceModal(true);
	}

	async function handleUnlinkDevice(deviceId: string) {
		try {
			await api.patch(`/mobile-device/${deviceId}/unlink`);
			await fetchDevices();

			Toast.fire({
				icon: "success",
				title: "Dispositivo desvinculado com sucesso",
			});
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: "Erro ao desvincular dispositivo",
			});

			console.log(error);
		}
	}

	const handleOpenNewDeviceModal = () => {
		setCurrentDevice({} as MobileDevice);
		setShowDeviceModal(true);
	};

	useEffect(() => {
		forcePage.current = page;
		fetchDevices();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePage.current = 1;
		fetchDevices(1);
	}, [debouncedSearch]);

	return (
		<>
			<button
				className="d-flex align-items-center btn button-bg-white-color-samu gap-4 mb-6"
				onClick={handleOpenNewDeviceModal}
			>
				<FaPlus /> Novo dispositivo
			</button>

			<Card>
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">Lista de dispositivos móveis</h3>

					<div>
						<SearchFormControl
							placeholder="Pesquisar dispositivo"
							onChange={(event) => setSearch(event.target.value)}
						/>
					</div>
				</Card.Header>

				<Card.Body>
					<Table className="table-row-dashed table-row-gray-300">
						<thead>
							<tr className="fw-bold fs-6">
								<th>Nome do dispositivo</th>
								<th>Base</th>
								<th>VTR</th>
								<th>PIN</th>
								<th>Ações</th>
							</tr>
						</thead>

						<tbody>
							{skeleton ? (
								<TableBodySkeleton columns={5} />
							) : (
								<>
									{results?.map((device, index) => (
										<tr key={index}>
											<td className="align-middle">{device.name}</td>
											<td className="align-middle">
												{device?.vehicle?.base?.name || ""}
											</td>
											<td className="align-middle">
												{device?.vehicle?.description || ""}
											</td>
											<td className="align-middle">
												{`${device.pin.code.slice(
													0,
													3
												)} ${device.pin.code.slice(-3)}`}
											</td>
											<td className="align-middle w-1px">
												<div className="d-flex gap-2">
													<OverlayTrigger
														overlay={<Tooltip>Editar</Tooltip>}
													>
														<Button
															variant="secondary"
															className="btn-icon btn-sm"
															onClick={() => handleEditDevice(device)}
														>
															<MdEdit className="text-gray-700" />
														</Button>
													</OverlayTrigger>

													{device.is_active && (
														<OverlayTrigger
															overlay={<Tooltip>Desvincular</Tooltip>}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																onClick={() =>
																	handleUnlinkDevice(device.id)
																}
															>
																<FaPowerOff className="text-gray-700" />
															</Button>
														</OverlayTrigger>
													)}
												</div>
											</td>
										</tr>
									))}

									{!results?.length && (
										<tr>
											<td className="text-center" colSpan={12}>
												<h2 className="mt-12 mb-0">
													Não há dispositivos cadastrados
												</h2>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</Table>

					{Boolean(results?.length) && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								itemsPerPage={10}
								totalItems={devices.meta?.total}
								forcePage={forcePage.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<DeviceModal
				showDeviceModal={showDeviceModal}
				handleCloseDeviceModal={() => setShowDeviceModal(false)}
				beforeSubmit={handleBeforeSubmitDevice}
				device={currentDevice}
			/>

			<PinModal
				showPinModal={showPinModal}
				handleClosePinModal={() => setShowPinModal(false)}
				beforeCheck={handleShowMobileDeviceModal}
				currentPin={usedPin}
				onlyShow
			/>
		</>
	);
}
