export function TrackHistoryLegend() {
	return (
		<div
			className="bg-white d-flex gap-3 p-4 m-4 rounded align-items-center"
			id="track-history-legend"
		>
			<span>
				<img
					className="me-2"
					src={`${window.location.origin}/media/icons/tracking/track.svg`}
					alt="Ícone de linha com seta para indicar direção"
				/>
				Rota percorrida
			</span>

			<span>
				<img
					className="me-2"
					src={`${window.location.origin}/media/icons/tracking/origin.svg`}
					alt="Ícone de de marcador azul para indicar origem"
				/>
				Origem
			</span>

			<span>
				<img
					className="me-2"
					src={`${window.location.origin}/media/icons/tracking/destination.svg`}
					alt="Ícone de de marcador laranja para indicar origem"
				/>
				Destino
			</span>
		</div>
	);
}
