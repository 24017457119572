import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { FormikProps } from "formik";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { FaPlus } from "react-icons/fa";
import { FaTrash, FaTimes, FaCheck } from "react-icons/fa";
import { AiOutlineClear } from "react-icons/ai";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { usePusher } from "@/hooks/usePusher";
import { ClosingType } from "@/enums/ClosingType";
import { PriorityType } from "@/enums/PriorityType";
import { AttendanceType } from "@/enums/AttendanceType";
import { TransferReason } from "@/enums/TransferReason";

import { conductOptions } from "@/utils/options/conduct";
import { timeUnitsOptions } from "@/utils/options/timeUnits";
import { woundTypesOptions } from "@/utils/options/woundTypes";
import { genderCodesOptions } from "@/utils/options/genderCodes";
import { natureTypesOptions } from "@/utils/options/natureTypes";
import { bleedingTypesOptions } from "@/utils/options/bleedingTypes";
import { sweatingTypesOptions } from "@/utils/options/sweatingTypes";
import { woundPlaceTypesOptions } from "@/utils/options/woundPlaceTypes";
import { antecedentTypesOptions } from "@/utils/options/antecedentTypes";
import { skinColorationTypesOptions } from "@/utils/options/skinColorationTypes";
import { sceneRegisterInitialValues } from "@/utils/initialValues/sceneRegister";
import { indicatedThrombolyticsMessage } from "@/utils/indicatedThrombolyticsMessage";
import { supportingOrganizationsOptions } from "@/utils/options/supportingOrganizations";
import { counterReferralReasonTypesOptions } from "@/utils/options/counterReferralReasonTypes";
import { filterSelectedDiagnosticHypotheses } from "@/utils/filterSelectedDiagnosticHypotheses";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { DiagnosticHypothesesSelect } from "@/components/Selects/DiagnosticHypothesesSelect";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { ProceduresSelect } from "@/components/Selects/ProceduresSelect";
import { MedicinesSelect } from "@/components/Selects/MedicinesSelect";
import { FormattedBadge } from "@/components/Badges/FormattedBadge";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { UnitsSelect } from "@/components/Selects/UnitsSelect";
import { IcdsSelect } from "@/components/Selects/IcdsSelect";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";
import { Separator } from "@/components/Separator";

type SceneRegisterInitialValues = typeof sceneRegisterInitialValues;
type KeyOfInitialValues = keyof SceneRegisterInitialValues;

type ReactSelectFilterOption<Option> = {
	readonly label: string;
	readonly value: string;
	readonly data: Option;
};

type SceneRegisterFormProps = {
	formik: FormikProps<SceneRegisterInitialValues>;
	inAttendance: boolean;
	attendanceId?: string;
	alreadyHasSceneRegister: boolean;
	handleStartAttendance: () => Promise<void>;
	handleCloseAttendance: () => Promise<void>;
	isStartingAttendance: boolean;
	sceneRegisterId: string | undefined;
	counterReferrals: CounterReferral[];
	setCounterReferrals: Dispatch<SetStateAction<CounterReferral[]>>;
	transformedDiagnosticHypothesisLatestMedicalRegulation:
		| TransformedDiagnosticHypothesis[]
		| undefined;
	attendanceType?: number;
	transformedDiagnosticHypothesisLatestSceneRecording:
		| TransformedDiagnosticHypothesis[]
		| undefined;
};

export function SceneRegisterForm({
	formik,
	inAttendance,
	attendanceId,
	alreadyHasSceneRegister,
	handleStartAttendance,
	handleCloseAttendance,
	isStartingAttendance,
	sceneRegisterId,
	counterReferrals,
	setCounterReferrals,
	transformedDiagnosticHypothesisLatestMedicalRegulation,
	attendanceType,
	transformedDiagnosticHypothesisLatestSceneRecording,
}: SceneRegisterFormProps) {
	const [diagnosticLabelIAM, setDiagnosticLabelIAM] = useState<string | null>(null);
	const pusher = usePusher();
	const navigate = useNavigate();

	const { values, errors } = formik;

	const isSecondaryAttendance = attendanceType === AttendanceType.SecondaryOccurrence;
	const isDiagnosticHypothesesFieldsInvalid =
		!values.closed &&
		values.diagnostic_hypotheses.some(
			({ nature_type_id, diagnostic_hypothesis_id }) =>
				!nature_type_id || !diagnostic_hypothesis_id
		);

	// formatted options

	const supportingOrganizations = supportingOrganizationsOptions.filter(
		(option) => !values.support_needed_description.includes(option.label)
	);

	// select options

	const selectedGenderCode = genderCodesOptions.find(
		(genderCodeOption) => genderCodeOption.value === values.patient.gender_code || null
	);
	const selectedTimeUnit = timeUnitsOptions.find(
		(timeUnit) => timeUnit.value === String(values.patient.time_unit_id)
	);
	const selectedBleeding = bleedingTypesOptions.find(
		(bleedingType) => bleedingType.value === values.bleeding_type_id || null
	);
	const selectedSweating = sweatingTypesOptions.find(
		(sweatingType) => sweatingType.value === values.sweating_type_id || null
	);
	const selectedSkinColoration = skinColorationTypesOptions.find(
		(skinColorationType) => skinColorationType.value === values.skin_coloration_type_id || null
	);

	const selectedSupportNeededDescriptions = values.support_needed_description
		.filter(Boolean)
		.map((description) => ({
			label: description,
			value: description,
		}));

	// generic change handlers

	function handleChangeFieldWithDescription(event: ChangeEvent<HTMLInputElement>) {
		const value = event.target.value;

		if (!Number(value)) {
			const name = event.target.name;
			const descriptionFieldName = `${name}_description` as KeyOfInitialValues;

			const emptyValue = Array.isArray(values[descriptionFieldName]) ? [] : "";

			formik.setFieldValue(descriptionFieldName, emptyValue);
		}

		formik.handleChange(event);
	}

	function clearUnitDestination() {
		formik.setFieldValue("unit_destination_id", "");
		formik.setFieldValue("unit_destination_label", "");
	}

	function handleChangeCheckboxField(
		event: ChangeEvent<HTMLInputElement>,
		field: KeyOfInitialValues
	) {
		const fields = values[field] as string[];
		const value = event.target.value;

		if (event.target.checked) {
			formik.setFieldValue(field, [...fields, value]);
		} else {
			if (value === "Atendimento com Remoção") {
				clearUnitDestination();
			}

			formik.setFieldValue(
				field,
				fields.filter((_field) => _field !== value)
			);
		}
	}

	// multiple options change handlers

	function handleAddMetric() {
		const defaultValues = {
			...sceneRegisterInitialValues.metrics[0],
			start_at: dayjs().format("YYYY-MM-DD[T]HH:mm"),
		};

		formik.setFieldValue("metrics", [...values.metrics, defaultValues]);
	}

	function handleDeleteMetric(indexToRemove: number) {
		formik.setFieldValue(
			"metrics",
			values.metrics.filter((_, index) => index !== indexToRemove)
		);
	}

	function handleAddWound() {
		formik.setFieldValue("wounds", [...values.wounds, sceneRegisterInitialValues.wounds[0]]);
	}

	function handleDeleteWound(indexToRemove: number) {
		formik.setFieldValue(
			"wounds",
			values.wounds.filter((_, index) => index !== indexToRemove)
		);
	}

	function handleAddConduct() {
		formik.setFieldValue("conducts", [
			...values.conducts,
			sceneRegisterInitialValues.conducts[0],
		]);
	}

	function handleDeleteConduct(indexToRemove: number) {
		formik.setFieldValue(
			"conducts",
			values.conducts.filter((_, index) => index !== indexToRemove)
		);
	}

	function handleDisplayIAM(diagnosticHypothesisLabel: string) {
		const IAM_LABEL = "IAM";
		const IAM_LABEL_SECONDARY = "INFARTO AGUDO DO MIOCÁRDIO (IAM)";

		if (
			diagnosticHypothesisLabel === IAM_LABEL ||
			diagnosticHypothesisLabel === IAM_LABEL_SECONDARY
		) {
			setDiagnosticLabelIAM(diagnosticHypothesisLabel);
		}
	}

	function handleChangeDiagnosticHypotheses(option: ReactSelectOption, index: number) {
		const { label, value } = option as ReactSelectOption;

		formik.setFieldValue(`diagnostic_hypotheses[${index}].diagnostic_hypothesis_id`, value);
		formik.setFieldValue(`diagnostic_hypotheses[${index}].diagnostic_hypothesis_label`, label);

		handleDisplayIAM((option as ReactSelectOption).label);
	}

	// closed and death fields change handlers

	function clearDeathFields() {
		const deathFields = [
			"death_at",
			"death_type",
			"death_professional",
			"death_professional_registration_number",
		];

		deathFields.forEach((field) => {
			formik.setFieldValue(field, "");
		});
	}

	function clearClosingType() {
		formik.setFieldValue("closing_type_id", "");
		clearDeathFields();
	}

	function handleChangeClosed(event: ChangeEvent<HTMLInputElement>) {
		const checked = event.target.checked;

		if (!checked) {
			clearClosingType();
		}

		formik.handleChange(event);
	}

	function handleChangeClosingType(event: ChangeEvent<HTMLInputElement>) {
		const value = event.target.value;

		if (value !== "1") {
			clearDeathFields();
		}

		formik.handleChange(event);
	}

	function handleCleanRadiosIAM(index: string | number) {
		formik.setFieldValue(`diagnostic_hypotheses[${index}].applied`, "");
		formik.setFieldValue(`diagnostic_hypotheses[${index}].recommended`, "");
	}

	// counter referral

	const { api } = useApi();
	const { Toast, toastRequestErrors } = useSwal();
	const hasDiagnosticHypothesesFound = useRef(false);

	const [isCreatingCounterReferral, setIsCreatingCounterReferral] = useState(false);
	const [isSubmittingCounterReferral, setIsSubmittingCounterReferral] = useState(false);
	const [counterReferralReason, setCounterReferralReason] = useState<number>();
	const [counterReferralUnitDestination, setCounterReferralUnitDestination] =
		useState<ReactSelectOption>({} as ReactSelectOption);

	const selectedCounterReferralReason = counterReferralReasonTypesOptions.find(
		(reason) => reason.value === counterReferralReason
	);

	const isDisabledSaveButtonConduct =
		!counterReferralUnitDestination?.value || !selectedCounterReferralReason?.value;

	function handleStartCounterReferral() {
		setIsCreatingCounterReferral(true);
	}

	function handleCancelCounterReferral() {
		setIsCreatingCounterReferral(false);
		setCounterReferralReason(undefined);
		setCounterReferralUnitDestination({} as ReactSelectOption);
	}

	async function handleSubmitCounterReferral() {
		try {
			setIsSubmittingCounterReferral(true);

			const { data } = await api.post("/scene-recording/counter-referral", {
				scene_recording_id: sceneRegisterId,
				reason_id: counterReferralReason,
				unit_destination_id: counterReferralUnitDestination.value,
			});

			const newCounterReferral: CounterReferral = {
				...data,
				unit_destination: {
					id: counterReferralUnitDestination.value,
					name: counterReferralUnitDestination.label,
				},
			};

			setCounterReferrals([...counterReferrals, newCounterReferral]);
			handleCancelCounterReferral();

			Toast.fire({
				icon: "success",
				title: "Contrarreferência registrada com sucesso",
			});
		} catch (error: any) {
			console.log(error);
			toastRequestErrors(error.response?.data.errors ?? {});
		} finally {
			setIsSubmittingCounterReferral(false);
		}
	}

	const CounterReferralButton = () => (
		<Col md={4}>
			<div className="h-100 d-flex align-items-end">
				<button
					type="button"
					className="btn btn-sm button-bg-samu-color-white"
					onClick={handleStartCounterReferral}
				>
					Contrarreferência
				</button>
			</div>
		</Col>
	);

	function handleAddDiagnosticHypothesis() {
		const defaultValues = {
			...sceneRegisterInitialValues.diagnostic_hypotheses[0],
		};

		formik.setFieldValue("diagnostic_hypotheses", [
			...values.diagnostic_hypotheses,
			defaultValues,
		]);
	}

	function handleDeleteDiagnosticHypothesis(indexToRemove: number) {
		const filteredDiagnosticHypotheses = values.diagnostic_hypotheses.filter(
			(_, index) => index !== indexToRemove
		);

		formik.setFieldValue("diagnostic_hypotheses", filteredDiagnosticHypotheses);
	}

	function handleChangeNatureTypeDiagnosticHypothesis(index: number, option: ReactSelectOption) {
		const { value, label } = option;

		formik.setFieldValue(`diagnostic_hypotheses[${index}].nature_type_id`, value);
		formik.setFieldValue(`diagnostic_hypotheses[${index}].nature_type_label`, label);

		formik.setFieldValue(`diagnostic_hypotheses[${index}].diagnostic_hypothesis_id`, "");
		formik.setFieldValue(`diagnostic_hypotheses[${index}].diagnostic_hypothesis_label`, "");
	}

	function handleRefreshClosedAttendance() {
		const channel = pusher.subscribe(`private-cancel-attendance.refresh.${attendanceId}`);

		channel.bind("RefreshCancelAttendance", () => {
			navigate("/ocorrencias");
		});
	}

	useEffect(() => {
		if (attendanceId) {
			handleRefreshClosedAttendance();
		}

		return () => {
			pusher.unsubscribe(`private-refresh-cancel-attendance.${attendanceId}`);
		};
	}, [attendanceId]);

	useEffect(() => {
		if (
			!alreadyHasSceneRegister &&
			!hasDiagnosticHypothesesFound.current &&
			transformedDiagnosticHypothesisLatestMedicalRegulation &&
			!transformedDiagnosticHypothesisLatestSceneRecording
		) {
			formik.setFieldValue(
				"diagnostic_hypotheses",
				transformedDiagnosticHypothesisLatestMedicalRegulation
			);

			hasDiagnosticHypothesesFound.current = true;
		}
	}, [alreadyHasSceneRegister, transformedDiagnosticHypothesisLatestMedicalRegulation]);

	useEffect(() => {
		if (
			!alreadyHasSceneRegister &&
			!hasDiagnosticHypothesesFound.current &&
			transformedDiagnosticHypothesisLatestSceneRecording
		) {
			formik.setFieldValue(
				"diagnostic_hypotheses",
				transformedDiagnosticHypothesisLatestSceneRecording
			);

			hasDiagnosticHypothesesFound.current = true;
		}
	}, [alreadyHasSceneRegister, transformedDiagnosticHypothesisLatestSceneRecording]);

	useEffect(() => {
		values.diagnostic_hypotheses?.map((diagnosticHypothesis) =>
			handleDisplayIAM(diagnosticHypothesis.diagnostic_hypothesis_label)
		);
	}, [values.diagnostic_hypotheses]);

	return (
		<Form onSubmit={formik.handleSubmit}>
			<Row>
				<Col md={6}>
					<FormattedFormControl
						id="patient-name"
						label="Nome do paciente"
						labelClassName="fw-bold"
						disabled={!inAttendance || alreadyHasSceneRegister}
						{...formik.getFieldProps("patient.name")}
					/>
				</Col>

				<Col>
					<Row>
						<Col md={6}>
							<Form.Label htmlFor="patient-gender-code" className="fw-bold">
								Sexo
							</Form.Label>

							<ReactSelect
								inputId="patient-gender-code"
								options={genderCodesOptions}
								isDisabled={!inAttendance || alreadyHasSceneRegister}
								value={selectedGenderCode}
								placeholder="Selecione"
								onChange={(option) =>
									formik.setFieldValue(
										"patient.gender_code",
										(option as ReactSelectOption).value
									)
								}
							/>
						</Col>

						<Col>
							<Form.Label htmlFor="patient-age" className="fw-bold">
								Idade
							</Form.Label>

							<Row>
								<Col lg={4}>
									<FormattedFormControl
										id="patient-age"
										mask={Number}
										isMaskedInput
										disabled={!inAttendance || alreadyHasSceneRegister}
										{...formik.getFieldProps("patient.age")}
									/>
								</Col>

								<Col>
									<ReactSelect
										options={timeUnitsOptions}
										isDisabled={!inAttendance || alreadyHasSceneRegister}
										value={selectedTimeUnit}
										onChange={(option) =>
											formik.setFieldValue(
												"patient.time_unit_id",
												(option as ReactSelectOption).value
											)
										}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>

			{!inAttendance ? (
				<div className="d-flex justify-content-center mt-16">
					<button
						className="btn button-bg-samu-color-white"
						type="button"
						onClick={handleStartAttendance}
						disabled={isStartingAttendance}
					>
						{isStartingAttendance ? (
							<Spinner animation="border" size="sm" />
						) : (
							"Iniciar registro de cena"
						)}
					</button>
				</div>
			) : (
				<>
					<Separator />

					<Row className="gap-4">
						<h3>Registro da cena</h3>

						<div>
							{values.diagnostic_hypotheses.map((diagnosticHypothesis, index) => {
								const diagnosticHypothesisLabel =
									diagnosticHypothesis.diagnostic_hypothesis_label;
								const isIAM = diagnosticLabelIAM === diagnosticHypothesisLabel;

								const useOfThrombolyticsMessage = indicatedThrombolyticsMessage(
									diagnosticHypothesis.recommended,
									diagnosticHypothesis.applied
								);

								return (
									<Row key={`diagnostic-hipothesis-${index}`} className="mt-4">
										<Col lg={4}>
											<Form.Label htmlFor="nature-type" className="fw-bold">
												Natureza {!values.closed && <RedAsterisk />}
											</Form.Label>

											<ReactSelect
												inputId="nature-type"
												options={natureTypesOptions}
												placeholder="Selecione uma natureza"
												value={
													diagnosticHypothesis.nature_type_id
														? {
																value: diagnosticHypothesis.nature_type_id,
																label: diagnosticHypothesis.nature_type_label,
														  }
														: null
												}
												onChange={(option) =>
													handleChangeNatureTypeDiagnosticHypothesis(
														index,
														option as ReactSelectOption
													)
												}
												isInvalid={
													!diagnosticHypothesis.nature_type_id &&
													!alreadyHasSceneRegister &&
													!values.closed
												}
												isDisabled={alreadyHasSceneRegister}
											/>

											{!diagnosticHypothesis.nature_type_id &&
												!alreadyHasSceneRegister &&
												!values.closed && (
													<span className="text-danger">
														Campo obrigatório
													</span>
												)}
										</Col>

										<Col lg={5}>
											<Form.Label
												htmlFor="diagnostic-hypothesis"
												className="fw-bold"
											>
												Hipótese diagnóstica{" "}
												{!values.closed && <RedAsterisk />}
											</Form.Label>

											<DiagnosticHypothesesSelect
												inputId="diagnostic-hypothesis"
												natureTypes={diagnosticHypothesis.nature_type_id}
												value={
													diagnosticHypothesis.diagnostic_hypothesis_id
														? {
																value: diagnosticHypothesis.diagnostic_hypothesis_id,
																label: diagnosticHypothesis.diagnostic_hypothesis_label,
														  }
														: null
												}
												onChange={(option: any) =>
													handleChangeDiagnosticHypotheses(option, index)
												}
												isInvalid={
													!diagnosticHypothesis.diagnostic_hypothesis_id &&
													!alreadyHasSceneRegister &&
													!values.closed
												}
												isDisabled={
													!diagnosticHypothesis.nature_type_id ||
													alreadyHasSceneRegister
												}
												filterOption={(
													candidate: ReactSelectFilterOption<unknown>,
													input: string
												) =>
													filterSelectedDiagnosticHypotheses(
														candidate,
														input,
														values
													)
												}
											/>

											{!diagnosticHypothesis.diagnostic_hypothesis_id &&
												!alreadyHasSceneRegister &&
												!values.closed && (
													<span className="text-danger">
														Campo obrigatório
													</span>
												)}

											{isIAM && !alreadyHasSceneRegister && (
												<Col className="mt-3">
													<div className="fw-bold mb-2">
														Uso de trombolíticos indicado?
													</div>

													<div className="d-flex gap-3">
														<div className="d-flex flex-column gap-3 justify-content-center">
															<FormGroupRadio
																className="d-grid grid-template-col-10rem-auto"
																requestedValue={
																	diagnosticHypothesis.recommended
																}
																{...formik.getFieldProps(
																	`diagnostic_hypotheses[${index}].recommended`
																)}
															>
																<FormRadio
																	label="Recomendado"
																	value="2"
																	id={`IAM-recommended-scene-register-${index}`}
																/>
																<FormRadio
																	label="Não recomendado"
																	value="1"
																	id={`IAM-not-recommended-scene-register-${index}`}
																/>
															</FormGroupRadio>

															{diagnosticHypothesis.recommended && (
																<FormGroupRadio
																	className="d-grid grid-template-col-10rem-auto"
																	requestedValue={
																		diagnosticHypothesis.applied
																	}
																	{...formik.getFieldProps(
																		`diagnostic_hypotheses[${index}].applied`
																	)}
																>
																	<FormRadio
																		label="Aplicado"
																		value="2"
																		id={`IAM-applied-scene-register-${index}`}
																	/>
																	<FormRadio
																		label="Não aplicado"
																		value="1"
																		id={`IAM-not-applied-scene-register-${index}`}
																	/>
																</FormGroupRadio>
															)}
														</div>

														<OverlayTrigger
															overlay={<Tooltip>Limpar</Tooltip>}
														>
															<div className="d-flex align-items-center">
																<Button
																	type="button"
																	variant="secondary"
																	className="btn-icon h-38px w-38px"
																	onClick={() =>
																		handleCleanRadiosIAM(index)
																	}
																>
																	<AiOutlineClear className="text-gray-700" />
																</Button>
															</div>
														</OverlayTrigger>
													</div>
												</Col>
											)}

											{isIAM &&
												alreadyHasSceneRegister &&
												diagnosticHypothesis.recommended && (
													<div className="mt-2">
														<span className="fw-bold">
															Uso de trombolíticos indicado?{" "}
														</span>
														{useOfThrombolyticsMessage}
													</div>
												)}
										</Col>

										{!alreadyHasSceneRegister && (
											<Col className="d-flex gap-2 pt-8">
												<Button
													type="button"
													variant="secondary"
													className="btn-icon h-38px w-38px"
													onClick={handleAddDiagnosticHypothesis}
												>
													<FaPlus className="text-gray-700" />
												</Button>

												{index !== 0 && (
													<Button
														type="button"
														variant="secondary"
														className="btn-icon h-38px w-38px"
														onClick={() =>
															handleDeleteDiagnosticHypothesis(index)
														}
													>
														<FaTrash className="text-gray-700" />
													</Button>
												)}
											</Col>
										)}
									</Row>
								);
							})}
						</div>

						<FormControlWithCharactersCount
							as="textarea"
							id="scene-description"
							label="Descrição da cena"
							labelClassName="fw-bold"
							placeholder="Descreva"
							maxLength={1000}
							disabled={alreadyHasSceneRegister}
							{...formik.getFieldProps("scene_description")}
						/>

						<Row>
							<Col lg={4}>
								<Form.Label htmlFor="icd-code" className="fw-bold">
									Buscar CID
								</Form.Label>

								<IcdsSelect
									inputId="icd-code"
									value={
										values.icd_code
											? {
													label: values.icd_code_label,
													value: values.icd_code,
											  }
											: null
									}
									placeholder="Selecione um CID"
									onChange={(option) => {
										const { label, value } = option as ReactSelectOption;
										formik.setFieldValue("icd_code", value);
										formik.setFieldValue("icd_code_label", label);
									}}
									isDisabled={alreadyHasSceneRegister}
								/>
							</Col>

							<Col lg={4}>
								<FormattedFormControl
									id="victim-type"
									label="Tipo de vítima"
									labelClassName="fw-bold"
									disabled={alreadyHasSceneRegister}
									{...formik.getFieldProps("victim_type")}
								/>
							</Col>

							<Col>
								<FormattedFormControl
									id="security-equipment"
									label="Equipamento de segurança"
									labelClassName="fw-bold"
									disabled={alreadyHasSceneRegister}
									{...formik.getFieldProps("security_equipment")}
								/>
							</Col>
						</Row>
					</Row>

					<Separator />

					<Row className="d-flex flex-column gap-4">
						<h3>Sinais vitais</h3>

						{values.metrics.map((_, index, array) => (
							<Row key={`scene-register-metric-${index}`}>
								<Col lg={2}>
									<FormattedFormControl
										type="datetime-local"
										id={`metrics-start-at-${index}`}
										label="Data e hora início"
										labelClassName="fw-bold"
										{...formik.getFieldProps(`metrics[${index}].start_at`)}
										readOnly
										disabled
									/>
								</Col>

								<Col lg={1}>
									<FormattedFormControl
										id={`metrics-diagnostic-evaluation-${index}`}
										label="DX"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps(
											`metrics[${index}].diagnostic_evaluation`
										)}
									/>
								</Col>

								<Col lg={2}>
									<Form.Label
										htmlFor={`metrics-systolic-blood-pressure-${index}`}
										className="fw-bold"
									>
										Pressão arterial
									</Form.Label>

									<Row>
										<Col lg={5} className="pe-0">
											<FormattedFormControl
												maxLength={3}
												id={`metrics-systolic-blood-pressure-${index}`}
												disabled={alreadyHasSceneRegister}
												{...formik.getFieldProps(
													`metrics[${index}].systolic_blood_pressure`
												)}
											/>
										</Col>

										<Col lg="auto" className="d-flex flex-center">
											<span>x</span>
										</Col>

										<Col className="ps-0">
											<FormattedFormControl
												maxLength={3}
												id={`metrics-diastolic-blood-pressure-${index}`}
												disabled={alreadyHasSceneRegister}
												{...formik.getFieldProps(
													`metrics[${index}].diastolic_blood_pressure`
												)}
											/>
										</Col>
									</Row>
								</Col>

								<Col lg={1}>
									<FormattedFormControl
										id={`metrics-heart-rate-${index}`}
										label="FC"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps(`metrics[${index}].heart_rate`)}
									/>
								</Col>

								<Col lg={1}>
									<FormattedFormControl
										id={`metrics-respiratory-frequency-${index}`}
										label="FR"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps(
											`metrics[${index}].respiratory_frequency`
										)}
									/>
								</Col>

								<Col lg={1}>
									<FormattedFormControl
										id={`metrics-temperature-${index}`}
										label="Temp"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps(`metrics[${index}].temperature`)}
										onChange={(event) =>
											formik.setFieldValue(
												`metrics[${index}].temperature`,
												event.currentTarget.value.replace(",", ".")
											)
										}
									/>
								</Col>

								<Col lg={1}>
									<FormattedFormControl
										maxLength={10}
										id={`metrics-oxygen-saturation-${index}`}
										label="Sat"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps(
											`metrics[${index}].oxygen_saturation`
										)}
									/>
								</Col>

								<Col lg={1}>
									<FormattedFormControl
										id={`metrics-glasgow-scale-${index}`}
										label="Glasgow"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps(`metrics[${index}].glasgow_scale`)}
									/>
								</Col>

								{!alreadyHasSceneRegister && index === array.length - 1 && (
									<Col className="d-flex align-items-end gap-2">
										<Button
											type="button"
											variant="secondary"
											className="btn-icon h-38px w-38px"
											onClick={handleAddMetric}
										>
											<FaPlus className="text-gray-700" />
										</Button>

										{index !== 0 && (
											<Button
												type="button"
												variant="secondary"
												className="btn-icon h-38px w-38px"
												onClick={() => handleDeleteMetric(index)}
											>
												<FaTrash className="text-gray-700" />
											</Button>
										)}
									</Col>
								)}
							</Row>
						))}

						<Row>
							<Col lg={4}>
								<Form.Label htmlFor="bleeding-type" className="fw-bold">
									Sangramento
								</Form.Label>

								<ReactSelect
									inputId="bleeding-type"
									options={bleedingTypesOptions}
									value={selectedBleeding}
									placeholder="Selecione um sangramento"
									onChange={(option) =>
										formik.setFieldValue(
											"bleeding_type_id",
											(option as ReactSelectOption).value
										)
									}
									isDisabled={alreadyHasSceneRegister}
								/>
							</Col>

							<Col lg={4}>
								<Form.Label htmlFor="sweating-type" className="fw-bold">
									Sudorese
								</Form.Label>

								<ReactSelect
									inputId="sweating-type"
									options={sweatingTypesOptions}
									value={selectedSweating}
									placeholder="Selecione uma sudorese"
									onChange={(option) =>
										formik.setFieldValue(
											"sweating_type_id",
											(option as ReactSelectOption).value
										)
									}
									isDisabled={alreadyHasSceneRegister}
								/>
							</Col>

							<Col>
								<Form.Label htmlFor="skin-coloration-type" className="fw-bold">
									Coloração da pele
								</Form.Label>

								<ReactSelect
									inputId="skin-coloration-type"
									options={skinColorationTypesOptions}
									value={selectedSkinColoration}
									placeholder="Selecione uma coloração"
									onChange={(option) =>
										formik.setFieldValue(
											"skin_coloration_type_id",
											(option as ReactSelectOption).value
										)
									}
									isDisabled={alreadyHasSceneRegister}
								/>
							</Col>
						</Row>

						<Row>
							<FormGroupRadio
								inline
								label="Prioridade"
								labelClassName="fw-bold"
								isInvalid={!!errors.priority_type_id}
								requestedValue={values.priority_type_id}
								disabled={alreadyHasSceneRegister}
								isRequired={!values.closed}
								{...formik.getFieldProps("priority_type_id")}
							>
								<FormRadio
									id="priority-type-red"
									label={<FormattedBadge attribute={PriorityType.Red} />}
									value="5"
								/>
								<FormRadio
									id="priority-type-orange"
									label={<FormattedBadge attribute={PriorityType.Orange} />}
									value="4"
								/>
								<FormRadio
									id="priority-type-yellow"
									label={<FormattedBadge attribute={PriorityType.Yellow} />}
									value="3"
								/>
								<FormRadio
									id="priority-type-green"
									label={<FormattedBadge attribute={PriorityType.Green} />}
									value="2"
								/>
								<FormRadio
									id="priority-type-blue"
									label={<FormattedBadge attribute={PriorityType.Blue} />}
									value="1"
								/>
							</FormGroupRadio>

							{errors.priority_type_id && (
								<span className="text-danger">{errors.priority_type_id}</span>
							)}
						</Row>

						<FormControlWithCharactersCount
							as="textarea"
							id="observations"
							label="Observações"
							labelClassName="fw-bold"
							placeholder="Descreva"
							maxLength={1000}
							disabled={alreadyHasSceneRegister}
							{...formik.getFieldProps("observations")}
						/>
					</Row>

					<Separator />

					<Row>
						<Col lg={7} className="d-flex flex-column gap-4">
							{values.wounds.map((wound, index, array) => (
								<div
									className="d-flex gap-4 pe-0"
									key={`scene-register-wound-${index}`}
								>
									<Col lg={5}>
										<Form.Label
											htmlFor={`wound-type-${index}`}
											className="fw-bold"
										>
											Ferimento
										</Form.Label>

										<ReactSelect
											inputId={`wound-type-${index}`}
											options={woundTypesOptions}
											value={woundTypesOptions.find(
												(woundType) =>
													woundType.value === wound.wound_type_id
											)}
											onChange={(option) =>
												formik.setFieldValue(
													`wounds[${index}].wound_type_id`,
													(option as ReactSelectOption).value
												)
											}
											isDisabled={alreadyHasSceneRegister}
										/>
									</Col>

									<Col lg={5}>
										<Form.Label
											htmlFor={`wound-place-type-${index}`}
											className="fw-bold"
										>
											Local do ferimento
										</Form.Label>

										<ReactSelect
											inputId={`wound-place-type-${index}`}
											options={woundPlaceTypesOptions}
											value={woundPlaceTypesOptions.find(
												(woundPlaceType) =>
													woundPlaceType.value ===
													wound.wound_place_type_id
											)}
											onChange={(option) =>
												formik.setFieldValue(
													`wounds[${index}].wound_place_type_id`,
													(option as ReactSelectOption).value
												)
											}
											isDisabled={alreadyHasSceneRegister}
										/>
									</Col>

									{!alreadyHasSceneRegister && index === array.length - 1 && (
										<div className="d-flex align-items-end justify-content-center gap-2 px-0">
											<Button
												type="button"
												variant="secondary"
												className="btn-icon h-38px w-38px"
												onClick={handleAddWound}
											>
												<FaPlus className="text-gray-700" />
											</Button>

											{index !== 0 && (
												<Button
													type="button"
													variant="secondary"
													className="btn-icon h-38px w-38px"
													onClick={() => handleDeleteWound(index)}
												>
													<FaTrash className="text-gray-700" />
												</Button>
											)}
										</div>
									)}
								</div>
							))}
						</Col>

						<Col lg="auto" className="border-end-dashed border-secondary ms-4" />

						<Col lg={4} className="ps-15 pe-0">
							<Form.Label htmlFor="procedures" className="fw-bold">
								Procedimento
							</Form.Label>

							<ProceduresSelect
								isMulti
								inputId="procedures"
								isDisabled={alreadyHasSceneRegister}
								value={values.procedures}
								onChange={(options) => formik.setFieldValue("procedures", options)}
							/>
						</Col>
					</Row>

					<Separator />

					<Row>
						<Col lg={7}>
							<div className="d-flex gap-4 pe-0">
								<Col lg={5}>
									<Form.Label htmlFor="antecedents" className="fw-bold">
										Antecedentes
									</Form.Label>

									<ReactSelect
										isMulti
										inputId="antecedents"
										options={antecedentTypesOptions}
										isDisabled={alreadyHasSceneRegister}
										value={values.antecedents}
										onChange={(options) =>
											formik.setFieldValue("antecedents", options)
										}
									/>
								</Col>

								<Col lg={5}>
									<FormattedFormControl
										id="allergy"
										label="Alergia"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps("allergy")}
									/>
								</Col>
							</div>
						</Col>

						<Col lg="auto" className="border-end-dashed border-secondary ms-4" />

						<Col lg={4} className="ps-15 pe-0">
							<Form.Label htmlFor="medicines" className="fw-bold">
								Medicamento - unidade
							</Form.Label>

							<MedicinesSelect
								isMulti
								id="medicines"
								isDisabled={alreadyHasSceneRegister}
								value={values.medicines}
								onChange={(options) => {
									formik.setFieldValue("medicines", options);
								}}
							/>
						</Col>
					</Row>

					<Separator />

					<Row>
						<Row>
							<Col lg={3}>
								<h3 className="mb-4">
									Necessita de apoio? {!values.closed && <RedAsterisk />}
								</h3>

								<FormGroupRadio
									inline
									isInvalid={!!errors.support_needed}
									name="support_needed"
									requestedValue={values.support_needed}
									onChange={handleChangeFieldWithDescription}
									disabled={alreadyHasSceneRegister}
									isRequired={!values.closed}
								>
									<FormRadio label="Sim" value="1" id="support-needed-yes" />
									<FormRadio label="Não" value="0" id="support-needed-no" />
								</FormGroupRadio>
							</Col>

							{Number(values.support_needed) === 1 && (
								<Col lg={3}>
									<Form.Label
										htmlFor="support-needed-description"
										className="fw-bold"
									>
										Tipo de apoio
									</Form.Label>

									<ReactSelect
										isMulti
										isDisabled={alreadyHasSceneRegister}
										inputId="support-needed-description"
										options={supportingOrganizations}
										value={selectedSupportNeededDescriptions}
										onChange={(options) => {
											const newValues = (options as ReactSelectOption[]).map(
												(option) => String(option.label)
											);

											formik.setFieldValue(
												"support_needed_description",
												newValues
											);
										}}
									/>
								</Col>
							)}
						</Row>

						{errors.support_needed && (
							<span className="text-danger">{errors.support_needed}</span>
						)}
					</Row>

					<Separator />

					<Row className="gap-4">
						<h3>Conduta {!values.closed && <RedAsterisk />}</h3>

						<Row>
							<div className="d-flex gap-4">
								<div className="d-flex gap-2">
									<input
										type="checkbox"
										id="conduct-type-residential-service"
										value="Atendimento na Residência"
										checked={values.conduct_types.includes(
											"Atendimento na Residência"
										)}
										onChange={(event) =>
											handleChangeCheckboxField(event, "conduct_types")
										}
										disabled={alreadyHasSceneRegister}
									/>

									<Form.Label
										htmlFor="conduct-type-residential-service"
										className="m-0"
									>
										Atendimento na residência
									</Form.Label>
								</div>

								<div className="d-flex gap-2">
									<input
										type="checkbox"
										id="conduct-type-removal-service"
										value="Atendimento com Remoção"
										checked={values.conduct_types.includes(
											"Atendimento com Remoção"
										)}
										onChange={(event) =>
											handleChangeCheckboxField(event, "conduct_types")
										}
										disabled={alreadyHasSceneRegister}
									/>

									<Form.Label
										htmlFor="conduct-type-removal-service"
										className="m-0"
									>
										Atendimento com remoção
									</Form.Label>
								</div>

								<div className="d-flex gap-2">
									<input
										type="checkbox"
										id="conduct-type-vehicle"
										value="Conduta no Veículo"
										checked={values.conduct_types.includes(
											"Conduta no Veículo"
										)}
										onChange={(event) =>
											handleChangeCheckboxField(event, "conduct_types")
										}
										disabled={alreadyHasSceneRegister}
									/>

									<Form.Label htmlFor="conduct-type-vehicle" className="m-0">
										Conduta no veículo
									</Form.Label>
								</div>
							</div>

							{errors.conduct_types && (
								<span className="text-danger">{errors.conduct_types}</span>
							)}
						</Row>

						{values.conduct_types.includes("Atendimento com Remoção") && (
							<>
								<Row>
									<Col md={4}>
										<Form.Label htmlFor="unit-destination" className="fw-bold">
											Unidade de destino
										</Form.Label>

										<UnitsSelect
											inputId="unit-destination"
											isInvalid={!!errors.unit_destination_id}
											value={{
												label: values.unit_destination_label,
												value: values.unit_destination_id,
											}}
											onChange={(option) => {
												const { value, label } =
													option as ReactSelectOption;

												formik.setFieldValue("unit_destination_id", value);
												formik.setFieldValue(
													"unit_destination_label",
													label
												);
											}}
											isDisabled={alreadyHasSceneRegister}
										/>
									</Col>

									{isSecondaryAttendance && (
										<Col lg="auto">
											<FormGroupRadio
												inline
												label="Tipo de vaga"
												labelClassName="fw-bold"
												isInvalid={!!errors.vacancy_type_id}
												requestedValue={values.vacancy_type_id}
												disabled={alreadyHasSceneRegister}
												isRequired={!!values.unit_destination_id}
												{...formik.getFieldProps("vacancy_type_id")}
											>
												<FormRadio
													id="zero-vacancy"
													label="Vaga zero"
													value={TransferReason.ZeroVacancy}
												/>
												<FormRadio
													id="regulated-vacancy"
													label="Vaga regulada"
													value={TransferReason.RegulatedVacancy}
												/>
											</FormGroupRadio>

											{errors.vacancy_type_id && (
												<span className="text-danger">
													{errors.vacancy_type_id}
												</span>
											)}
										</Col>
									)}

									{alreadyHasSceneRegister &&
										!isCreatingCounterReferral &&
										counterReferrals.length === 0 && <CounterReferralButton />}
								</Row>

								{counterReferrals.map((counterReferral, index, array) => (
									<Row key={`scene-register-counter-referral-${index}`}>
										<Col md={4}>
											<UnitsSelect
												isDisabled
												value={{
													value: counterReferral.unit_destination_id,
													label: counterReferral.unit_destination.name,
												}}
											/>
										</Col>

										{alreadyHasSceneRegister &&
											!isCreatingCounterReferral &&
											index === array.length - 1 && <CounterReferralButton />}
									</Row>
								))}
							</>
						)}

						{isCreatingCounterReferral && (
							<Row>
								<Col md={4}>
									<Form.Label
										htmlFor="counterreferral-unit-destination"
										className="fw-bold"
									>
										Nova unidade de destino
									</Form.Label>

									<UnitsSelect
										inputId="counterreferral-unit-destination"
										value={counterReferralUnitDestination}
										onChange={(option) =>
											setCounterReferralUnitDestination(
												option as ReactSelectOption
											)
										}
										isDisabled={isSubmittingCounterReferral}
									/>
								</Col>

								<Col md={3}>
									<Form.Label
										htmlFor="counterreferral-reason"
										className="fw-bold"
									>
										Selecione o motivo
									</Form.Label>

									<ReactSelect
										inputId="counterreferral-reason"
										options={counterReferralReasonTypesOptions}
										onChange={(option) => {
											const { value } = option as ReactSelectOption;
											setCounterReferralReason(Number(value));
										}}
										value={selectedCounterReferralReason}
										isDisabled={isSubmittingCounterReferral}
									/>
								</Col>

								<Col>
									<div className="h-100 d-flex align-items-end gap-2">
										<Button
											type="button"
											variant="secondary"
											className="btn-icon h-38px w-38px"
											disabled={isSubmittingCounterReferral}
											onClick={handleCancelCounterReferral}
										>
											<FaTimes className="text-gray-700 fs-4" />
										</Button>

										<Button
											type="button"
											className="btn-icon h-38px w-38px button-bg-samu-color-white"
											disabled={
												isSubmittingCounterReferral ||
												isDisabledSaveButtonConduct
											}
											onClick={handleSubmitCounterReferral}
										>
											{isSubmittingCounterReferral ? (
												<Spinner animation="border" size="sm" />
											) : (
												<FaCheck className="fs-4" />
											)}
										</Button>
									</div>
								</Col>
							</Row>
						)}

						<Row>
							<h6>Conduta</h6>

							{values.conducts.map((conduct, index, array) => (
								<Row key={`scene-register-${index}`}>
									<Col lg={3}>
										<ReactSelect
											inputId={`conduct-id-${index}`}
											options={conductOptions}
											value={conductOptions.find(
												(conductOption) =>
													conductOption.value === conduct.conduct_id ||
													null
											)}
											placeholder="Selecione uma conduta"
											onChange={(option) =>
												formik.setFieldValue(
													`conducts[${index}].conduct_id`,
													(option as ReactSelectOption).value
												)
											}
											isDisabled={alreadyHasSceneRegister}
										/>
									</Col>

									<Col lg={7}>
										<FormattedFormControl
											placeholder="Descreva"
											disabled={alreadyHasSceneRegister}
											{...formik.getFieldProps(
												`conducts[${index}].conduct_description`
											)}
										/>
									</Col>

									{!alreadyHasSceneRegister && index === array.length - 1 && (
										<Col className="d-flex align-items-end gap-2">
											<Button
												type="button"
												variant="secondary"
												className="btn-icon h-38px w-38px"
												onClick={handleAddConduct}
											>
												<FaPlus className="text-gray-700" />
											</Button>

											{index !== 0 && (
												<Button
													type="button"
													variant="secondary"
													className="btn-icon h-38px w-38px"
													onClick={() => handleDeleteConduct(index)}
												>
													<FaTrash className="text-gray-700" />
												</Button>
											)}
										</Col>
									)}
								</Row>
							))}
						</Row>
					</Row>

					<Separator />

					<Row className="mb-4">
						<div className="d-flex gap-2">
							<input
								type="checkbox"
								id="closed"
								name="closed"
								checked={values.closed}
								onChange={handleChangeClosed}
								disabled={alreadyHasSceneRegister}
							/>
							<Form.Label htmlFor="closed" className="m-0">
								Encerramento
							</Form.Label>
						</div>
					</Row>

					{values.closed && (
						<FormGroupRadio
							inline
							labelClassName="fs-3 fw-bold"
							name="closing_type_id"
							requestedValue={values.closing_type_id}
							onChange={handleChangeClosingType}
							disabled={alreadyHasSceneRegister}
						>
							<FormRadio label="Óbito" value="1" id="closing-type-death" />
							<FormRadio
								label="Endereço não localizado"
								value="2"
								id="closing-type-address-not-found"
							/>
							<FormRadio
								label="Evasão do local"
								value="3"
								id="closing-type-evading"
							/>
							<FormRadio
								label="Falsa ocorrência"
								value="4"
								id="closing-type-false-occurrence"
							/>
							<FormRadio
								label="Recusa atendimento"
								value="5"
								id="closing-type-refusal-attendance"
							/>
							<FormRadio
								label="Recusa remoção"
								value="6"
								id="closing-type-refusal-removal"
							/>
							<FormRadio
								label="Removido por terceiros"
								value="7"
								id="closing-type-removed-by-third-parties"
							/>
							<FormRadio
								label="Remoção por outra VTR"
								value="8"
								id="closing-type-removed-by-other-vehicle"
							/>
						</FormGroupRadio>
					)}

					{values.closing_type_id &&
						Number(values.closing_type_id) !== ClosingType.Death && (
							<FormControlWithCharactersCount
								as="textarea"
								id="justification"
								label="Justificativa"
								labelClassName="fw-bold mt-4"
								placeholder="Descreva"
								maxLength={1000}
								{...formik.getFieldProps("closed_justification")}
							/>
						)}

					{values.closing_type_id &&
						Number(values.closing_type_id) === ClosingType.Death && (
							<Row className="mt-4">
								<Col md={3}>
									<FormattedFormControl
										type="datetime-local"
										id="death-at"
										label="Data e hora"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps("death_at")}
									/>
								</Col>

								<Col md={2}>
									<FormattedFormControl
										id="death-type"
										label="Tipo de óbito"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps("death_type")}
									/>
								</Col>

								<Col md={5}>
									<FormattedFormControl
										id="death-professional"
										label="Nome do profissional"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps("death_professional")}
									/>
								</Col>

								<Col>
									<FormattedFormControl
										id="death-professional-registration-number"
										label="Número do registro"
										labelClassName="fw-bold"
										disabled={alreadyHasSceneRegister}
										{...formik.getFieldProps(
											"death_professional_registration_number"
										)}
									/>
								</Col>
							</Row>
						)}

					{!alreadyHasSceneRegister && (
						<div className="d-flex justify-content-center text-center mt-16 gap-4">
							{inAttendance && (
								<button
									type="button"
									className="btn button-bg-light-color-gray-400 gap-2 w-100px"
									title="Ao clicar o atendimento voltará ao status anterior e ficará disponível para que outros usuários possam atendê-lo."
									onClick={handleCloseAttendance}
								>
									Fechar
								</button>
							)}

							<button
								type="submit"
								className="btn button-bg-samu-color-white"
								disabled={
									!formik.isValid ||
									formik.isSubmitting ||
									isDiagnosticHypothesesFieldsInvalid
								}
							>
								{formik.isSubmitting ? (
									<Spinner animation="border" size="sm" />
								) : (
									"Registrar"
								)}
							</button>
						</div>
					)}
				</>
			)}
		</Form>
	);
}
