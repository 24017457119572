import dayjs from "dayjs";

export const trackHistoryControlsInitialValues = {
	vehicle: {
		value: "",
		label: "",
	},
	start_date: dayjs().startOf("day").add(1, "second").format("YYYY-MM-DDTHH:mm:ss"),
	end_date: dayjs().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
};
