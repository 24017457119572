import Button from "react-bootstrap/Button";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";

import { StatusBadge } from "@/components/Badges/StatusBadge";

type Props = {
	attendanceId: number | string;
	statusId: number;
	fetchAttendances: () => Promise<void>;
};

export function StatusButton({ attendanceId, statusId, fetchAttendances }: Props) {
	const { api } = useApi();
	const { Swal, Toast, toastRequestErrors } = useSwal();

	const handleCloseAttendance = async () => {
		const { isConfirmed } = await Swal.fire({
			title: "Deseja liberar o atendimento?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		});

		if (isConfirmed) {
			try {
				await api.put(`/attendance/${attendanceId}/close`);

				Toast.fire({
					title: "Atendimento liberado com sucesso",
					icon: "success",
				});
			} catch (error: any) {
				console.error(error);

				toastRequestErrors(error.response?.data.errors ?? {});
			} finally {
				fetchAttendances();
			}
		}
	};

	return (
		<Button onClick={handleCloseAttendance} className="bg-transparent p-0">
			<StatusBadge statusId={statusId} />
		</Button>
	);
}
