import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useFormik } from "formik";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { FaSearch } from "react-icons/fa";

import { trackHistoryControlsInitialValues } from "@/utils/initialValues/trackHistoryControls";
import { trackHistoryValidationSchema } from "@/utils/validation/trackHistory";
import { useApi } from "@/hooks/useApi";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { VehicleSelect } from "@/components/Selects/VehicleSelect";

type TrackHistoryControlsProps = {
	setTrackHistory: Dispatch<SetStateAction<TrackHistoryResponse | null>>;
};

export function TrackHistoryControls({ setTrackHistory }: TrackHistoryControlsProps) {
	const [isLoading, setIsLoading] = useState(false);

	const { api } = useApi();

	const formik = useFormik({
		initialValues: trackHistoryControlsInitialValues,
		validationSchema: trackHistoryValidationSchema,
		onSubmit() {
			fetchTrackingHistory();
		},
	});

	async function fetchTrackingHistory() {
		try {
			setIsLoading(true);

			const { data } = await api.get("/vehicles-tracking/fetch-vehicle-history", {
				params: {
					vehicle_id: values.vehicle.value,
					start_date: values.start_date,
					end_date: values.end_date,
				},
			});

			setTrackHistory(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	const { values } = formik;

	function handleSelectVehicle(option: ReactSelectOption) {
		formik.setFieldValue("vehicle", option);
	}

	useEffect(() => {
		formik.validateForm();
	}, [values]);

	return (
		<Form onSubmit={formik.handleSubmit}>
			<Row className="gap-3">
				<Col xs={12} md={5}>
					<Form.Label htmlFor="track-history-controls-vehicle" className="fw-bold">
						VTR:{" "}
					</Form.Label>
					<VehicleSelect
						onlyTrackable
						showAllVehicles
						id="track-history-controls-vehicle"
						name="vehicle_id"
						value={values.vehicle.value ? values.vehicle : null}
						onChange={(option) => handleSelectVehicle(option as ReactSelectOption)}
						menuPortalTarget={document.body}
					/>
					{formik.errors.vehicle?.value && (
						<span className="text-danger">{formik.errors.vehicle.value}</span>
					)}
				</Col>
				<Col xs={12} md={3}>
					<Form.Label htmlFor="track-history-controls-start-date" className="fw-bold">
						Data inicial:{" "}
					</Form.Label>
					<FormattedFormControl
						type="datetime-local"
						id="track-history-controls-start-date"
						step={1}
						{...formik.getFieldProps("start_date")}
					/>
					{formik.errors.start_date && (
						<span className="text-danger">{formik.errors.start_date}</span>
					)}
				</Col>
				<Col xs={12} md={3}>
					<Form.Label htmlFor="track-history-controls-end-date" className="fw-bold">
						Data final:{" "}
					</Form.Label>
					<FormattedFormControl
						type="datetime-local"
						id="track-history-controls-end-date"
						step={1}
						{...formik.getFieldProps("end_date")}
					/>
					{formik.errors.end_date && (
						<span className="text-danger">{formik.errors.end_date}</span>
					)}
				</Col>
			</Row>

			<div className="d-flex my-5 justify-content-center">
				<button
					type="submit"
					disabled={isLoading || !formik.isValid}
					className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
				>
					{isLoading ? (
						"Aguarde..."
					) : (
						<>
							<FaSearch /> Buscar
						</>
					)}
				</button>
			</div>
		</Form>
	);
}
