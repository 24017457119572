import { Outlet } from "react-router-dom";

import { toAbsoluteUrl } from "@/../_metronic/helpers";

import "./styles.scss";

export function OccurrenceMonitoringLayout() {
	return (
		<div className="layout-container">
			<img
				src={toAbsoluteUrl("/media/images/monitoring/blob.svg")}
				alt="Bolha decorativa"
				className="top-image"
			/>

			<img
				src={toAbsoluteUrl("/media/images/monitoring/blob-2.svg")}
				alt="Bolha decorativa"
				className="bottom-image"
			/>

			<div className="h-100 d-flex align-items-center flex-column gap-10 py-15 px-5">
				<img
					src={toAbsoluteUrl("/media/logos/samu-logo.png")}
					alt="Logo do Samu"
					className="samu-logo"
				/>

				<Outlet />
			</div>
		</div>
	);
}
